import * as React from 'react';
import CustomTable from "../../../shared/Table";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Download} from "@mui/icons-material";
import {download} from "../../../utils/download";

interface INumeroValutatoreProps {
    submissions: {
        uoa_id: number,
        uoa_name: string,
        uoa_custom_field_ente_proponente_51: string,
    }[];
}

const NumeroProgetto = ({submissions}: INumeroValutatoreProps) => {
    const columns: string[] = [
        'Progetto',
        'Ente proponente',
        'valutazioni'
    ];

    const data: any = {};
    let rows: any;

    submissions.forEach(function (submission) {
        if (data.hasOwnProperty(submission.uoa_id)) {
            data[submission.uoa_id].count = data[submission.uoa_id].count + 1;
        } else {
            data[submission.uoa_id] = {
                name: submission.uoa_name,
                field: submission.uoa_custom_field_ente_proponente_51,
                count: 1
            };
        }
    })

    rows = Object.values(data)
        .map((item: any) => {
            return [
                item.name,
                item.field,
                item.count,
            ]
        })

    rows.sort(function (a: any, b: any) {
        return b[2] - a[2];
    });

    const handleDownload = () => {
        download([columns, ...rows], 'Numero di valutazioni per progetto')
    }

    return (
        <>
            <div className="content-title">
                <Typography variant="h5">
                    Numero di valutazioni per progetto
                </Typography>
                <IconButton onClick={handleDownload}>
                    <Download fontSize="small"/>
                </IconButton>
            </div>
            <CustomTable
                columns={columns}
                rows={rows}
                numberRows={[1]}/>
        </>
    );
};

export default NumeroProgetto;
