import * as Highcharts from 'highcharts';
import {useContext, useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';
import {getValueByType} from '../../helpers/mapping';
import {ContainerContext} from '../../../../contexts/Container';

interface ITypeOfSectorProps {
    submissions: any[];
}

const TypeOfSector = ({submissions}: ITypeOfSectorProps) => {
    const {type} = useContext(ContainerContext);

    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const key = getValueByType(type || '', 'type_of_sector');

        let values = submissions.map((submission) => submission[key]);
        values = values.filter((value) => value !== null && value !== '');

        let data: any = [];

        values.forEach((value: any) => {
            const index = data.findIndex((item: any) => item.name === value);

            if (index === -1) {
                data.push({name: value, y: 1});
            } else {
                data[index].y += 1;
            }
        });

        const dashboardOptions: any = getDefaultPieFormatting('Type of Sector', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default TypeOfSector;
