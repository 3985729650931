import {useEffect, useState} from 'react';
import {IFoodBanksProps} from "../../utils/models";
import PieChart from "../../PieChart";

const Source = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let distribution = 0, horeca = 0, manufacturing = 0, other = 0;

        submissions.forEach((submission) => {
            distribution = distribution + (Number(submission.distribution_partners) || 0)
            horeca = horeca + (Number(submission.horeca_and_catering_sector_partners) || 0)
            manufacturing = manufacturing + (Number(submission.manufacturing_partners) || 0)
            other = other + (Number(submission.other_partners) || 0)
        })

        setSeries([{
            name: 'Sources',
            size: "100%",
            innerSize: '50%',
            data: [
                {
                    name: 'Distribution Partners',
                    y: distribution,
                    color: '#7172ad'
                },
                {
                    name: 'HORECA and catering partners',
                    y: horeca,
                    color: '#ef8c8c'
                },
                {
                    name: 'Manufacturing Partners',
                    y: manufacturing,
                    color: '#f2a86f'
                },
                {
                    name: 'Other',
                    y: other,
                    color: '#f9d45c'
                },
            ],
        }]);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series && (
                    <PieChart
                        title="Partner companies"
                        fractionDigits={2}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default Source;