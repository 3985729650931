import * as React from 'react';
import {useEffect, useState} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from 'highcharts/modules/map';
import mapData from '@highcharts/map-collection/custom/world.topo.json';

interface IBusinessProfileProps {
    final: any[],
    questions: string[],
    countries: {
        text: string, value: string
    }[],
    title: string,
}

HighchartsMap(Highcharts);

const Map = ({final, questions, title, countries}: IBusinessProfileProps) => {
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data: any[] = [];
        const getData = (submission: any) => {
            const data: any[] = [];

            questions.forEach((question) => {
                const answer = countries.find((country) => country.value === submission[question]);

                if (answer) {
                    const index = data.findIndex((el: any) => el.key === answer.value);

                    if (index !== -1) {
                        data[index].value = data[index].value + 1;
                    } else {
                        data.push({
                            'key': answer.value,
                            value: 1,
                            visible: true
                        });
                    }
                }
            })

            return data;
        }

        final.forEach((submission: any) => {
            data = [
                ...data,
                ...getData(submission)
            ];
        })

        const dashboardOptions: any = {
            chart: {
                map: mapData,
            },
            title: {
                text: title,
                align: 'left'
            },
            colorAxis: {
                min: 0,
                stops: [
                    [0, '#EFEFFF'],
                    [0.5, '#2caffe'],
                    [1, 'rgb(0,48,127)']
                ]
            },
            accessibility: {
                series: {
                    descriptionFormat: '{series.name}, map with {series.points.length} areas.',
                    pointDescriptionEnabledThreshold: 50
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'bottom'
            },
            plotOptions: {
                map: {
                    joinBy: ['name', 'key'],
                }
            },
            series: [
                {
                    name: title,
                    data: data
                }
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={'mapChart'}
            />
        </>
    );
};

export default Map;
