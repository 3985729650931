import * as React from "react";
import DataImage from "../../DataImage";
import {IFoodBanksProps} from "../../utils/models";
import makeSum from "../../../../utils/makeSum";

const TotalFood = ({ submissions }: IFoodBanksProps) => {
    return (
        <DataImage
            image='https://www.eurofoodbank.org/wp-content/uploads/2023/03/Total_food_out.png'
            value={`Kg ${makeSum(submissions, 'non_food_out')}`}
            text='Total Non Food Out'
        />
    );
}

export default TotalFood;