import {Card, CardContent} from '@mui/material';
import {useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";

interface ITassoProps {
    data: [][],
    name: string
    elements: {
        name: string,
        choices: [],
    }[]
}


const Pie = ({data, name, elements}: ITassoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    let categories: any[] = [];
    const question = elements.find((element: { name: string }) => element.name === name)

    if (question) {
        categories = question.choices
    }

    const series = categories.map(category => ({
        name: category,
        y: data
                .filter((item: any) => item[name] === category)
                .length
        })
    )

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                height: 300,
                type: 'pie',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                    showInLegend: true
                }
            },
            series:  [{
                data: series,
            }]
        };

        setOptions(dashboardOptions)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Pie;
