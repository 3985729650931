const calculateAge = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  const birthdate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  const today = new Date();

  return today.getFullYear() - birthdate.getFullYear() -
    Number(today.getMonth() < birthdate.getMonth() ||
      (today.getMonth() === birthdate.getMonth() && today.getDate() < birthdate.getDate()));
}

export default calculateAge;
