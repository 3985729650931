import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IWomenEmployedProps {
    baseline: any[];
    final: any[];
}

const WomenEmployed = ({baseline, final}: IWomenEmployedProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [women, setWomen] = useState(0);

    const calculateSubmissions = () => {
        let totalWomen = 0;
        let totalMen = 0;

        baseline.forEach((baselineSubmission: any) => {
            const finalSubmission = final.find(
                (finalSubmission: any) => finalSubmission.uoa_id === baselineSubmission.uoa_id
            );

            if (finalSubmission) {
                let baselineCount =
                    baselineSubmission.jobs_t0_women_t0_number + baselineSubmission.jobs_t0_men_t0_number;
                let finalCount = finalSubmission.jobs_t1_women_t1_number + finalSubmission.jobs_t1_men_t1_number;

                if (baselineCount < finalCount) {
                    totalWomen += Math.max(
                        finalSubmission.jobs_t1_women_t1_number - baselineSubmission.jobs_t0_women_t0_number,
                        0
                    );
                    totalMen += Math.max(
                        finalSubmission.jobs_t1_men_t1_number - baselineSubmission.jobs_t0_men_t0_number,
                        0
                    );
                }
            }
        });

        setWomen(totalWomen);

        const data: any[] = [
            {
                name: 'Women',
                y: totalWomen,
            },
            {
                name: 'Men',
                y: totalMen,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting('Number of women employed', data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default WomenEmployed;
