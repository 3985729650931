import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
}

const CustomerNetwork = ({final}: IBusinessProfileProps) => {
    let value = final.filter((submission: any) => submission.netw_growth_t1 === 'Yes').length / final.length;

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="space-y-4">
                    <Typography>
                        % of entrepreneurs that increased the customer network
                    </Typography>
                    <Box
                        sx={{
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        <div>
                            { value * 100 }%
                        </div>
                    </Box>

                </div>
            </CardContent>
        </Card>
    );
};

export default CustomerNetwork;
