import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import React, {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import Tasso from '../FSV/Tasso';
import AgeGroups from './AgeGroups';
import Autonomy from './Autonomy';
import Composition from './Composition';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const WIAParent = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const actual_id = searchParams.get('actual_id');
    const previsioned_id = searchParams.get('previsioned_id');
    const uoa_id = searchParams.get('uoa_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_PARENT || '');

    const [loading, setLoading] = useState(false);
    const [beneficiarySubmissions, setBeneficiarySubmissions] = useState([]);
    const [tassoData, setTassoData] = useState<{
        average: number;
        uoa: number;
    }>();

    const getSurvey = async () => {
        setUserToken(searchParams.get('token') || '')

        try {
            if (actual_id && previsioned_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    await apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/parent/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            question: 'question13'
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: previsioned_id,
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setTassoData(responses[0].data.data);
                setBeneficiarySubmissions(responses[1].data.submissions);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className="attentamente-parent">
            {
                !loading && (
                    <>
                        {
                            tassoData && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>TASSO DI RAGGIUNGIMENTO DEI BENEFICIARI</div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra il tasso di raggiungimento dei beneficiari attesi per il
                                                vostro progetto a confronto con la media dello stesso valore in tutti i
                                                progetti. Il valore è ottenuto dal rapporto tra i beneficiari che il progetto
                                                prevede di raggiungere secondo la scheda previsionale di ciascuna attività e i
                                                beneficiari effettivamente raggiunti secondo la più recente scheda aggiornamento
                                                attività rivolte ai beneficiari.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Tasso
                                        data={tassoData}
                                        title='Tasso di raggiungimento dei beneficiari: Rapporto fra beneficiari effettivi e beneficiari attesi'
                                    />
                                </div>
                            )
                        }
                        {
                            (beneficiarySubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>COMPOSIZIONE BENEFICIARI ATTESI - PERSONE ANZIANE</div>
                                        <Tooltip
                                            title={<>
                                                Il grafico descrive i beneficiari anziani attesi per il vostro progetto a
                                                confronto con la media di tutti i progetti. Sono presentati un grafico con la
                                                composizione dei beneficiari per fasce d’età, e un grafico con la composizione
                                                dei beneficiari per livello di autonomia.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <div className="attentamente-parent">
                                        <AgeGroups
                                            submissions={beneficiarySubmissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                        <Autonomy
                                            submissions={beneficiarySubmissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            (beneficiarySubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>COMPOSIZIONE DELLE ATTIVITÀ</div>
                                        <Tooltip
                                            title={<>
                                                Il grafico descrive la distribuzione percentuale dei beneficiari per tipo di
                                                attività nel vostro progetto, a confronto con la stessa distribuzione nel
                                                progetto medio. Il grafico vuole offrire un’immagine approssimativa del mix di
                                                servizi offerti dal vostro progetto a confronto con gli altri progetti.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Composition
                                        submissions={beneficiarySubmissions}
                                        uoa={parseInt(uoa_id)}
                                    />
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default WIAParent;
