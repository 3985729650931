import React, {useContext} from 'react';
import {Typography} from '@mui/material';
import getValidSubmissions from '../helpers/getValidSubmissions';
import EntrepreneursIncrease from './EntrepreneursIncrease';
import IncreasedSkills from './IncreasedSkills';
import ResultsAchieved from './ResultsAchieved';
import TotalRespondents from './TotalRespondents';
import {ContainerContext} from '../../../contexts/Container';
import {TYPE_MBA_STUDENT} from '../helpers/mapping';

interface IShortTermProps {
    baseline: any[];
    final: any[];
}

const ShortTerm = ({baseline, final}: IShortTermProps) => {
    const {type} = useContext(ContainerContext);

    const {filteredBaseline, filteredFinal} = getValidSubmissions(baseline, final);

    return (
        <div>
            <TotalRespondents total={filteredFinal.length}/>
            <br/>
            {
                Number(type) === TYPE_MBA_STUDENT && <>
                    <Typography variant='h5'>Increased entrepreneurial mindset</Typography>
                    <br/>
                    <EntrepreneursIncrease
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br/>
                </>
            }
            <Typography variant='h5'>Increased entrepreneurial skills</Typography>
            <br/>
            <IncreasedSkills
                baseline={filteredBaseline}
                final={filteredFinal}
            />
            <br/>
            {
                Number(type) === TYPE_MBA_STUDENT && <ResultsAchieved submissions={filteredFinal}/>
            }
        </div>
    );
};

export default ShortTerm;
