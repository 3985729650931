import * as React from "react";
import {IFoodBanksProps} from "../utils/models";
import TotalFoodIn from "./TotalFoodIn";
import SourceFoodIn from "./SourceFoodIn";
import DonatedFood from "./DonatedFood";

const FoodIn = ({ submissions }: IFoodBanksProps) => {
    return (
       <>
           <div className="content-title col-span-2">
               Food IN
           </div>
           <div className="space-y-4">
               <TotalFoodIn submissions={submissions}/>
               <SourceFoodIn submissions={submissions}/>
               <DonatedFood submissions={submissions}/>
           </div>
       </>
    );
}

export default FoodIn;