import {useEffect, useRef, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {COLORS} from '../../../../utils/constants';
import EnrolledItem from '../../base/EnrolledItem';
import SkillsTooltip from './SkillsTooltip';

interface IImprovementsChartProps {
    baseline: any[];
    final: any[];
}

const baselineKeys = {
    business: ['skills_t0_bizmodel_skills_t0'],
    impactMeasurement: ['skills_t0_impact_skills_t0'],
    pitching: ['skills_t0_pitch_skills_t0'],
    finance: ['skills_t0_finance_skills_t0'],
};
const finalKeys = {
    business: ['skills_t1_bizmodel_skills_t1'],
    impactMeasurement: ['skills_t1_impact_skills_t1'],
    pitching: ['skills_t1_pitch_skills_t1'],
    finance: ['skills_t1_finance_skills_t1'],
};

const keys: ('business' | 'impactMeasurement' | 'pitching' | 'finance')[] = [
    'business',
    'impactMeasurement',
    'pitching',
    'finance',
];

const ImprovementsChart = ({baseline, final}: IImprovementsChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);
    const [improvedPercentage, setImprovedPercentage] = useState<number>(0);

    const calcSum = (keys: string[], submission: any) => {
        return keys.map((key) => Number(submission[key])).reduce((partialSum, a) => partialSum + a, 0);
    };

    function calculateImprovements(baselineSubmissions: any[], finalSubmissions: any[]) {
        const initObject = {
            business: 0,
            impactMeasurement: 0,
            pitching: 0,
            finance: 0,
        };

        const finalDataImprovements = {...initObject};
        const baselineData = {...initObject};
        const finalData = {...initObject};

        let improvedFinalCount = 0;

        finalSubmissions.forEach((submission: any) => {
            const baselineSubmission = baselineSubmissions.find((sub: any) => sub.uoa_id === submission.uoa_id);

            let currentValue = {...initObject};
            let baselineValue = {...initObject};

            keys.forEach((element) => {
                currentValue[element] = calcSum(finalKeys[element], submission);
                baselineValue[element] = calcSum(baselineKeys[element], baselineSubmission);
            });

            const currentMean = Object.values(currentValue).reduce((partialSum, a) => partialSum + a, 0) / keys.length;
            const baselineMean =
                Object.values(baselineValue).reduce((partialSum, a) => partialSum + a, 0) / keys.length;

            if (currentMean > baselineMean) {
                improvedFinalCount++;

                keys.forEach((element) => {
                    finalData[element] += currentValue[element];
                    baselineData[element] += baselineValue[element];

                    if (currentValue[element] > baselineValue[element]) {
                        finalDataImprovements[element]++;
                    }
                });
            }
        });

        keys.forEach((element) => {
            finalData[element] /= improvedFinalCount;
            baselineData[element] /= improvedFinalCount;
            finalDataImprovements[element] /= improvedFinalCount;
        });

        const finalDataImprovedPercentage = (improvedFinalCount / finalSubmissions.length) * 100;

        return {baselineData, finalData, finalDataImprovements, finalDataImprovedPercentage};
    }

    const calculateSubmissions = (baselineSubmissions: any[], finalSubmissions: any[]) => {
        const {baselineData, finalData, finalDataImprovements, finalDataImprovedPercentage} = calculateImprovements(
            baselineSubmissions,
            finalSubmissions
        );

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            xAxis: {
                categories: [
                    'Business modeling skills',
                    'Sustainability and impact measurement skills',
                    'Pitching skills',
                    'Financial-economic planning and accountability skills',
                ],
            },
            yAxis: {
                tickInterval: 1,
                min: 1,
                max: 7,
            },
            legend: {
                verticalAlign: 'top',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                    groupPadding: 0.1,
                },
            },
            title: {
                text: '',
                align: 'left',
            },
            series: [
                {
                    type: 'column',
                    name: 'BASELINE',
                    data: Object.values(baselineData).map((data) => Number(data.toFixed(1))),
                    color: COLORS.blue,
                },
                {
                    type: 'column',
                    name: 'FINAL',
                    data: Object.values(finalData).map((data) => Number(data.toFixed(1))),
                    color: COLORS.yellow,
                },
            ],
        };

        const improvementRatio: any = {};

        keys.forEach((element) => {
            improvementRatio[element] = finalData[element] / baselineData[element] - 1;
        });

        return {dashboardOptions, improvementRatio, finalDataImprovements, finalDataImprovedPercentage};
    };

    useEffect(() => {
        const {dashboardOptions, improvementRatio, finalDataImprovements, finalDataImprovedPercentage} =
            calculateSubmissions(baseline, final);

        setColumns([
            {field: 'name', headerName: 'Skill', flex: 2},
            {field: 'improved', headerName: '% Improved', flex: 1},
            {field: 'improvement', headerName: '% Improvement', flex: 1},
        ]);

        setRows([
            {
                id: 1,
                name: 'Business modeling skills',
                improvement: (improvementRatio.business * 100).toFixed(1) + ' %',
                improved: (finalDataImprovements.business * 100).toFixed(1) + ' %',
            },
            {
                id: 2,
                name: 'Sustainability and impact measurement skills',
                improvement: (improvementRatio.impactMeasurement * 100).toFixed(1) + ' %',
                improved: (finalDataImprovements.impactMeasurement * 100).toFixed(1) + ' %',
            },
            {
                id: 3,
                name: 'Pitching skills',
                improvement: (improvementRatio.pitching * 100).toFixed(1) + ' %',
                improved: (finalDataImprovements.pitching * 100).toFixed(1) + ' %',
            },
            {
                id: 4,
                name: 'Financial-economic planning and accountability skills',
                improvement: (improvementRatio.finance * 100).toFixed(1) + ' %',
                improved: (finalDataImprovements.finance * 100).toFixed(1) + ' %',
            },
        ]);

        setImprovedPercentage(finalDataImprovedPercentage);

        setOptions(dashboardOptions);
    }, []);

    return (
        <>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            text='% of participants who increased their entrepreneurial skills'
                            value={improvedPercentage.toFixed(1)}
                        />
                    </div>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <Typography variant='h6'>Level of increase in entrepreneurial skills</Typography>
                    <div className='flex justify-end'>
                        <SkillsTooltip />
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <Card
                            elevation={0}
                            className='basis-full lg:basis-1/2 pa-4'
                            sx={{border: '1px solid #E0E0E0', borderRadius: '4px'}}
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        </Card>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            hideFooterPagination={true}
                            hideFooter={true}
                            className='basis-full lg:basis-1/2'
                        />
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default ImprovementsChart;
