import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
}

const Branches = ({final}: IBusinessProfileProps) => {
    let count = 0;
    let value = '0';
    let average = '0'
    let branchesNum = 0

    final.forEach((submission: any) => {
        if (submission.branches_t1 === 'Yes') {
            count++
        }

        branchesNum += submission.branches_num_t1
    })

    if (final.length > 0) {
        value = (count / final.length * 100).toFixed(1)
    }

    if (count > 0) {
        average = (branchesNum / count).toFixed(1)
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{value}
                    </Box>
                    <Typography>
                        % of entrepreneurs that opened new branches
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        {average}
                    </Box>
                    <Typography>
                        Avg number of new branches
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default Branches;
