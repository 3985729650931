import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IPavesiBeneficiaryProps } from '../../utils/models';
import { COLORS } from '../../../../utils/constants';

const OriginTable = ({data, total}: IPavesiBeneficiaryProps) => {
  const sum: number = (Object.values(data) as number[]).reduce((partialSum: number, a: number) => partialSum + a, 0);
  const empty = total ? total - sum : 0;

  const capitalizeName = (str: string) => {
    const arr = str.split('-');

    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return arr.join(' ');
  }

  return (
    <Card className="chart-container">
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: 18, backgroundColor: COLORS.pavesiMain, color: 'white' }}>Paese</TableCell>
                <TableCell sx={{ fontSize: 18, backgroundColor: COLORS.pavesiMain, color: 'white' }}>Beneficiari</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (empty > 0 && total) && (
                  <TableRow>
                    <TableCell />
                    <TableCell>{total - sum}</TableCell>
                  </TableRow>
                )
              }
              {Object.keys(data).map((key) => (
                <TableRow key={key}>
                  <TableCell>{capitalizeName(key)}</TableCell>
                  <TableCell>{data[key]}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell sx={{ fontWeight: 700, fontSize: 18, backgroundColor: COLORS.pavesiMain, color: 'white' }}>
                  Grand Total
                </TableCell>
                <TableCell sx={{ fontWeight: 700, fontSize: 18, backgroundColor: COLORS.pavesiMain, color: 'white' }}>
                  {total}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default OriginTable;
