import React, {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

interface IMainSuccessFactorsProps {
    submissions: any[];
}

const keys: any = {
    'item1': 'Increased business modeling skills',
    'item2': 'Increased business planning skills',
    'item3': 'Increased financial planning skills',
    'item4': 'Increased pitching skills',
    'item5': 'E4Impact investors network',
    'other': 'Other',
};

const transform = [
    {text: 'Family & friends', value: 'question28'},
    {text: 'Business Angels', value: 'question10'},
    {text: 'Crowdfunding', value: 'question31'},
    {text: 'Incubators and Accelerators', value: 'question36'},
    {text: 'NGO\u2019s loans', value: 'question41'},
    {text: 'NGO’s grants', value: 'question46'},
    {text: 'Public subsidies (e.g. tax credits)', value: 'question51'},
    {text: 'Venture capital', value: 'question61'},
    {text: 'Microfinance', value: 'question66'},
    {text: 'Financial institutions (banks)', value: 'question71'},
    {text: 'Other', value: 'question76'},
];

const MainSuccessFactors = ({submissions}: IMainSuccessFactorsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data: any = {};

        Object.values(transform).forEach((item) => {
            data[item.value] = Object.keys(keys).map((key) => ({
                name: key,
                y: 0,
            }));
        });

        submissions.forEach((submission: any) => {
            for (const [key, values] of Object.entries(data)) {
                Object.keys(submission).forEach((question) => {
                    if (question.startsWith(key)) {
                        const value = submission[question];

                        if (value) {
                            const index = (values as any).findIndex((item: any) => item.name === value);

                            if (index !== -1) {
                                data[key][index].y += 1;
                            }
                        }
                    }
                })
            }
        });

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            xAxis: {
                categories: Object.values(transform).map(({text}) => text),
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                alignColumns: true
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                    groupPadding: 0.1,
                },
            },
            title: {
                text: 'Main success factors',
                align: 'left',
            },
            series: Object.keys(keys).map((key) => ({
                type: 'column',
                name: keys[key],
                data: Object.values(data)
                    .map((values) => (values as any[])
                        .filter((el) => el.name === key)
                        .map((el) => el.y)
                    ),
            }))
        };

        return dashboardOptions;
    };

    useEffect(() => {
        setOptions(calculateSubmissions());
    }, []);

    return (
        <>
            <Card className='chart-container'>
                <CardContent>
                    {options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default MainSuccessFactors;
