import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

import './styles.scss';

interface IEnrolledProps {
    students: string,
    women: string,
}

const Enrolled = ({ students, women }: IEnrolledProps) => {
    return (
        <Card className="chart-container">
            <CardContent>
            <div className="enrolled-item space-x-4">
                <Box
                    sx={{
                        width: 58,
                        p: 1,
                        border: '1px solid grey',
                        textAlign: 'right',
                        fontSize: '1.25rem',
                        backgroundColor: 'rgb(243, 244, 246)',
                    }}
                >
                    { Number(students) * 100 }
                </Box>
                <Typography>
                    % of increasing students enrolled
                </Typography>
            </div>
            <div className="enrolled-item space-x-4">
                <Box
                    sx={{
                        width: 58,
                        p: 1,
                        border: '1px solid grey',
                        textAlign: 'right',
                        fontSize: '1.25rem',
                        backgroundColor: 'rgb(243, 244, 246)',
                    }}
                >
                    { Number(women) * 100 }
                </Box>
                <Typography>
                    % of increasing of women enrolled
                </Typography>
            </div>
            </CardContent>
        </Card>
    );
};

export default Enrolled;
