import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IBusinessChangeProps {
    final: any[];
}

const transform = [
    {
        name: 'I implemented the idea and now I run the business',
        value: 'NEW_BIZ_T1',
    },
    {
        name: 'I changed the business idea and now I run the business',
        value: 'NEW_IDEA_BIZ_T1',
    },
    {
        name: 'I didn’t develop the idea, but I have improved my career/ I found a job',
        value: 'CAREER_T1',
    },
    {
        name: 'Nothing has changed',
        value: 'IDEA_NO_CHANGE_T1',
    },
];

const BusinessChange = ({final}: IBusinessChangeProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: final.filter((submission: any) => submission.career_status_idea_t1 === el.value).length,
        }));

        const title = 'Detail of the business status change';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessChange;
