import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IProductsInMarketProps {
    baseline: any[];
    final: any[];
}

const ProductsInMarket = ({baseline, final}: IProductsInMarketProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const data: any = [];
    const calculateValues = () => {
        data.push({
            name: 'Entrepreneurs who managed to have a product in the market',
            y: final.filter((value) => value.idea_product_sold_t1 === 'Yes').length,
        });

        data.push({
            name: 'Entrepreneurs who did not have products in the market at the beginning',
            y: baseline.filter((value) => value.product_sold_t0 === 'No').length,
        });

        const dashboardOptions: any = getDefaultPieFormatting('% of entrepreneurs who started to sell products in the market', data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateValues();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ProductsInMarket;
