import * as Highcharts from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { Card, CardContent } from '@mui/material';
import { IPavesiBeneficiaryProps } from '../../utils/models';

const Genere = ({data}: IPavesiBeneficiaryProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const calculateSubmissions = () => {
    const dashboardOptions: any = {
      chart: {
        height: 300,
        type: 'pie',
        style: {
          fontFamily: 'Rubik, sans-serif'
        },
        backgroundColor: '#fafafa'
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
          showInLegend: true
        }
      },
      series: [{
        name: 'Genere',
        data: [
          {
            name: 'Maschio',
            y: data.maschio,
            color: '#4285f4'
          },
          {
            name: 'Femmina',
            y: data.femmina,
            color: '#ea4236'
          },
        ],
        size: '50%',
        innerSize: '50%'
      }]
    };

    setOptions(dashboardOptions);
  };

  useEffect(() => {
    calculateSubmissions();
  }, [data]);

  return (
    <Card className="chart-container">
      <CardContent>
        {
          options && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          )
        }
      </CardContent>
    </Card>
  );
};

export default Genere;
