import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Impatto, {IImpattoData} from '../Impatto';

interface IDataProps {
    plannedData: any[],
    updatingData: any[],
    uoa: string | number,
}

const AceOfBeneficiaries = ({plannedData, updatingData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: IImpattoData; uoa: IImpattoData; }>({
        average: {
            same: 0,
            worse: 0,
            better: 0
        },
        uoa: {
            same: 0,
            worse: 0,
            better: 0
        }
    });

    const calcData = () => {
        let plannedValueAll = 0;
        let plannedValueCurrent = 0;
        const updatingValueAll = {
            row5: 0,
            row6: 0,
        };
        const updatingValueCurrent = {
            row5: 0,
            row6: 0,
        };

        plannedData.forEach((plannedSubmission: any) => {
            const value = plannedSubmission.question8_che_prevedete_di_coinvolgere_in_una_o_piu_delle_attivita_previste_ai_fini_dellattivazione_numero || 0;

            plannedValueAll = plannedValueAll + value;

            if (plannedSubmission.uoa_parent_id === Number(uoa)) {
                plannedValueCurrent = plannedValueCurrent + value;
            }
        })

        updatingData.forEach((updatingSubmission: any) => {
            const row5 = updatingSubmission.question10_che_avete_inserito_nel_mercato_del_lavoro_con_contratto_di_tirocinio_di_durata_a_6_mesi_o_contratto_di_lavoro_di_durata_a_4_mesi_full_time_o_part_time_non_inferiore_a_20_ore_settimanali_numero || 0;
            const row6 =updatingSubmission.question10_che_hanno_ripreso_gli_studi_per_il_conseguimento_del_diploma_di_scuola_secondaria_di_primo_o_di_secondo_grado_o_della_qualifica_professionale_numero || 0;

            updatingValueAll.row5 = updatingValueAll.row5 + row5;
            updatingValueAll.row6 = updatingValueAll.row6 + row6;

            if (updatingSubmission.uoa_parent_id === Number(uoa)) {
                updatingValueCurrent.row5 = updatingValueCurrent.row5 + row5;
                updatingValueCurrent.row6 = updatingValueCurrent.row6 + row6;
            }
        })

        if (plannedValueCurrent > 0) {
            setData({
                average: {
                    same: updatingValueAll.row6 / plannedValueAll,
                    worse: updatingValueAll.row5 / plannedValueAll,
                    better: (updatingValueAll.row5 + updatingValueAll.row6) / plannedValueAll,
                },
                uoa: {
                    same: updatingValueCurrent.row6 / plannedValueCurrent,
                    worse: updatingValueCurrent.row5 / plannedValueCurrent,
                    better: (updatingValueCurrent.row5 + updatingValueCurrent.row6) / plannedValueCurrent,
                }
            });
        }
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>TASSO DI ATTIVAZIONE DEI BENEFICIARI (SUL TOTALE DEGLI INTERCETTATI)</div>
                <Tooltip
                    title={<>
                        Il grafico mostra il tasso di attivazione dei beneficiari, complessivo e per ciascuna delle
                        tipologie di attivazione (inserimento nel mercato del lavoro e ripresa degli studi), per il
                        vostro progetto a confronto con la media degli stessi valori in tutti i progetti. I valori sono
                        ottenuti rispettivamente dal rapporto tra:<br/>
                        - i giovani NEET complessivamente attivati (somma tra i NEET inseriti nel mercato del lavoro e i
                        NEET che hanno ripreso gli studi) e i giovani NEET coinvolti in una o più delle attività
                        previste ai fini dell'attivazione, secondo la più recente scheda aggiornamento attività di
                        intercettazione<br/>
                        - i giovani NEET inseriti nel mercato del lavoro (con contratto di tirocinio di durata ≥ a 6
                        mesi o contratto di lavoro di durata ≥ a 4 mesi, full time o part-time non inferiore a 20 ore
                        settimanali) e i giovani NEET coinvolti in una o più delle attività previste ai fini
                        dell'attivazione, secondo la più recente scheda aggiornamento attività di intercettazione<br/>
                        - i giovani NEET che hanno ripreso gli studi (per il conseguimento del diploma di scuola
                        secondaria di primo o di secondo grado o della qualifica professionale) e i giovani NEET
                        coinvolti in una o più delle attività previste ai fini dell'attivazione, secondo la più recente
                        scheda aggiornamento attività di intercettazione
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Impatto
                    data={data}
                    categories={[
                        {
                            name: 'Attivazione NEET complessivamente attivati',
                            key: 'better'
                        },
                        {
                            name: 'Attivazione NEET che hanno ripreso gli studi',
                            key: 'same'
                        },
                        {
                            name: 'Attivazione NEET inseriti nel mercato del lavoro',
                            key: 'worse'
                        }
                    ]}
                />
            }
        </div>
    );
};

export default AceOfBeneficiaries;
