import * as React from "react";
import JobsCreated from "./JobsCreated";
import {Typography} from "@mui/material";

interface ILongTermProps {
    final: any[],
    baseline: any[],
}

const Impact = ({final, baseline}: ILongTermProps) => {
    return (
        <div>
            <div className="px-4">
                <Typography variant="h5" sx={{fontWeight: 600}}>
                    Entrepreneurs create decent jobs
                </Typography>
            </div>
            <JobsCreated baseline={baseline} final={final}/>
        </div>
    )
}

export default Impact;