import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import { percentageFormatter } from '../../helpers/formatters';

interface IContributionToResultScaleProps {
    final: any[];
}

const transform = [
    {value: 'SCALE _DWT_T1_5', name: '0 - 5 %'},
    {value: 'SCALE _DWT_T1_25', name: '6 - 25%'},
    {value: 'SCALE _DWT_T1_50', name: '26 - 50%'},
    {value: 'SCALE _DWT_T1_75', name: '51 - 75%'},
    {value: 'SCALE _DWT_T1_75', name: '76 - 100%'},
];

const ContributionToResultScale = ({final}: IContributionToResultScaleProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => {
            return {
                name: el.name,
                y: final.filter((submission: any) => submission.scale_dwt_t1 === el.value).length,
            };
        });

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'E4Impact contribution to results',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ContributionToResultScale;
