import _ from 'lodash';
import {Card, CardContent} from '@mui/material';
import EnrolledItem from '../../base/EnrolledItem';
import BusinessStatusChange from './BusinessStatusChange';

interface IScaledBusinessProps {
    final: any[];
    baseline: any[];
}

const ScaledBusiness = ({final, baseline}: IScaledBusinessProps) => {
    let value = 0;

    let baselineFiltered = baseline.filter((submission: any) =>
        ['BIZ>2Y_T0', 'BIZ<2Y_T0'].includes(submission.career_status_t0)
    );

    const validUoaIds = _.intersection(_.map(final, 'uoa_id'), _.map(baselineFiltered, 'uoa_id'));

    const filteredFinal = final.filter((submission: any) => validUoaIds.includes(submission.uoa_id));
    baselineFiltered = baselineFiltered.filter((submission: any) => validUoaIds.includes(submission.uoa_id));

    const divider = baselineFiltered.length;

    const finalScaled = filteredFinal.filter((submission: any) =>
        ['BIZ_SCALE_T1', 'BIZ_NEW_T1'].includes(submission.career_status_biz_t1)
    );

    if (divider > 0) {
        value = finalScaled.length / divider;
    }

    return (
        <>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            value={(value * 100).toFixed(1) + '%'}
                            text='% of entrepreneurs who scaled up their business'
                        />
                    </div>
                </CardContent>
            </Card>

            <br />
            <BusinessStatusChange final={filteredFinal} />
        </>
    );
};

export default ScaledBusiness;
