import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import React, {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import Column from './Column';
import Radar from './Radar';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const AttentamenteSecondParentAverage = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const [loading, setLoading] = useState(false);
    const [careOfData, setCareOfData] = useState<{ average: number; uoa: number; }>();
    const [inServicesData, setInServicesData] = useState<{ average: number; uoa: number; }>();
    const [initiatedServicesData, setInitiatedServicesData] = useState<{ average: number; uoa: number; }>();
    const [averageIndexData, setAverageIndexData] = useState<{ average: number; uoa: number; }>();
    const [radarData, setRadarData] = useState<{ average: object; uoa: object; }>();

    const getSurvey = async () => {
        const hashKey = searchParams.get('key');
        const base_id = searchParams.get('base_id');
        const new_id = searchParams.get('new_id');
        const checkpoint_id = searchParams.get('checkpoint_id');
        const type_id = searchParams.get('type_id');
        const uoa_id = searchParams.get('uoa_id');
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_PARENT || '');

        setUserToken(searchParams.get('token') || '')

        try {
            if (type_id && checkpoint_id && base_id && new_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    await apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/parent/average`,
                        params: {
                            base_id,
                            new_id,
                            type_id,
                            checkpoint_id,
                        }
                    }),
                ];

                const responses = await Promise.all(requests);

                setCareOfData(responses[0].data.data.care_of);
                setInServicesData(responses[0].data.data.in_services);
                setInitiatedServicesData(responses[0].data.data.initiated_services);
                setAverageIndexData(responses[0].data.data.average_index);
                setRadarData(responses[0].data.data.radar);

                setLoading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className='attentamente-average'>
            {
                !loading && (
                    <>
                        {
                            careOfData && (
                                <div>
                                    <div className='section-title'>
                                        <div>
                                            Numero medio di segnali di malessere dei beneficiari presi in carico
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra il numero medio di segnali di malessere dei beneficiari
                                                presi
                                                in carico dal vostro progetto a confronto con la media dello stesso valore
                                                in
                                                tutti i progetti. Il valore è ottenuto sommando i segnali di malessere
                                                indicati
                                                all’interno di ciascuna scheda iniziale beneficiario.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Column data={careOfData} usePercentage={false}/>
                                </div>
                            )
                        }
                        {
                            inServicesData && (
                                <div>
                                    <div className='section-title'>
                                        <div>
                                            Percentuale di beneficiari già in carico a servizi psichiatrici/psicologici
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra la percentuale di beneficiari presi in carico dal vostro
                                                progetto che risulta già in carico a servizi psichiatrici/psicologici, a
                                                confronto con la media dello stesso valore in tutti i progetti. Il valore è
                                                ottenuto dal rapporto tra il numero di beneficiari che risulta già in carico
                                                a
                                                tali servizi secondo la scheda iniziale beneficiario e il numero totale di
                                                beneficiari censiti (per i quali è stata compilata la scheda iniziale
                                                beneficiario).
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Column data={inServicesData} usePercentage={true}/>
                                </div>
                            )
                        }
                        {
                            initiatedServicesData && (
                                <div>
                                    <div className='section-title'>
                                        <div>
                                            Percentuale di beneficiari per cui è stata avviata una presa in carico da
                                            servizi
                                            psichiatrici/psicologici durante il progetto
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra la percentuale di beneficiari presi in carico dal vostro
                                                progetto che sono stati presi in carico da servizi psichiatrici/psicologici
                                                durante il progetto (e che quindi non lo erano precedentemente), a confronto
                                                con
                                                la media dello stesso valore in tutti i progetti. Il valore è ottenuto dal
                                                rapporto tra: 1) il numero di beneficiari che risultano in trattamento
                                                presso
                                                servizi di cura psicologici o psichiatrici diversi da quelli offerti dal
                                                progetto secondo la scheda di aggiornamento semestrale e che non risultavano
                                                già
                                                in carico a tali servizi secondo la scheda iniziale beneficiario 2) il
                                                numero
                                                totale di beneficiari censiti (per i quali è stata compilata la scheda
                                                iniziale
                                                beneficiario).
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Column data={initiatedServicesData} usePercentage={true}/>
                                </div>
                            )
                        }
                        {
                            averageIndexData && (
                                <div>
                                    <div className='section-title'>
                                        <div>
                                            Indice di fragilità medio
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra “l’indice di fragilità” medio dei beneficiari presi in
                                                carico
                                                dal vostro progetto a confronto con la media dello stesso valore in tutti i
                                                progetti. Il valore è calcolato, per ogni beneficiario, aggiungendo un punto
                                                per
                                                ogni occorrenza fra le seguenti:
                                                <ol style={{paddingLeft: '20px'}}>
                                                    <li>Mancata convivenza con la propria famiglia di origine</li>
                                                    <li>Presenza di altri componenti del nucleo familiare che hanno o hanno
                                                        avuto
                                                        problemi psicologici/psichiatrici
                                                    </li>
                                                    <li>Frequenza non regolare delle lezioni nell’ultimo anno scolastico</li>
                                                    <li>Esperienza di bocciatura</li>
                                                    <li>Assenza/presenza di pochi amici</li>
                                                    <li>Diagnosi di disturbi psicologici/psichiatrici specifici</li>
                                                </ol>
                                                …dividendo per 6 e moltiplicando per 100.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Column data={averageIndexData} usePercentage={true}/>
                                </div>
                            )
                        }
                        {
                            radarData && (
                                <div>
                                    <div className='section-title'>
                                        <div>
                                            Profilo dei beneficiari
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra il profilo dei beneficiari del vostro progetto a confronto con
                                                quello medio relativo all’insieme di tutti i progetti. Il profilo dei
                                                beneficiari è ottenuto considerando la quota dei beneficiari con le seguenti
                                                caratteristiche:
                                                <ol style={{paddingLeft: '20px'}}>
                                                    <li>Mancata convivenza con la propria famiglia di origine</li>
                                                    <li>Presenza di altri componenti del nucleo familiare che hanno o hanno
                                                        avuto
                                                        problemi psicologici/psichiatrici
                                                    </li>
                                                    <li>Frequenza non regolare delle lezioni nell’ultimo anno scolastico</li>
                                                    <li>Esperienza di bocciatura</li>
                                                    <li>Assenza/presenza di pochi amici</li>
                                                    <li>Diagnosi di disturbi psicologici/psichiatrici specifici</li>
                                                </ol>
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize='small'/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Radar data={radarData}/>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className='loader-container'>
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default AttentamenteSecondParentAverage;
