import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import makeAverage from "../../../../utils/makeAverage";

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const Turnover = ({final, baseline}: IBusinessProfileProps) => {
    let baselineEmployees = 0;
    let baselineTurnover = 0;
    let finalEmployees = 0;
    let finalTurnover = 0;
    const baselineAverageTurnover: number[] = []
    const finalAverageTurnover: number[] = []

    baseline.forEach((submission: any) => {
        baselineEmployees = baselineEmployees + (submission.jobs_t0_men_t0_number + submission.jobs_t0_women_t0_number)
        baselineTurnover = baselineTurnover + submission.turnover_t_1_turnover_amount
        baselineAverageTurnover.push(submission.turnover_t_1_turnover_amount)
    })

    final.forEach((submission: any) => {
        finalEmployees = finalEmployees + (submission.jobs_t1_men_t1_number + submission.jobs_t1_women_t1_number)
        finalTurnover = finalTurnover + submission.turnover_t1_value
        finalAverageTurnover.push(submission.turnover_t1_value)
    })

    const valueEmployees = (finalEmployees / baselineEmployees * 100).toFixed(1)
    const valueTurnover = (finalTurnover / baselineTurnover * 100).toFixed(1)

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{valueEmployees}
                    </Box>
                    <Typography>
                        % of employees
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{valueTurnover}
                    </Box>
                    <Typography>
                        % of turnover
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        $ {makeAverage(baselineAverageTurnover)}
                    </Box>
                    <Typography>
                        Average turnover BASELINE IMPACT
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        $ {makeAverage(finalAverageTurnover)}
                    </Box>
                    <Typography>
                        Average turnover FINAL IMPACT
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default Turnover;
