import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IBusinessSectorProps {
    final: any[];
}

const CHOICES = [
    {
        value: 'Accounting',
        text: 'Legal, Tax & Accounting',
    },
    {
        value: 'Agrifood',
        text: 'Agribusiness',
    },
    {
        value: 'Automotive',
        text: 'Automotive',
    },
    {
        value: 'Art and Culture',
        text: 'Art and Culture',
    },
    {
        value: 'Business Services',
        text: 'Business Services',
    },
    {
        value: 'Consulting',
        text: 'Consulting',
    },
    {
        value: 'Cosmetics',
        text: 'Cosmetics',
    },
    {
        value: 'Education',
        text: 'Education',
    },
    {
        value: 'Finance',
        text: 'Finance',
    },
    {
        value: 'Energy',
        text: 'Energy',
    },
    {
        value: 'Fashion and Textile',
        text: 'Fashion and Textile',
    },
    {
        value: 'Food Processing',
        text: 'Food Processing',
    },
    {
        value: 'Health',
        text: 'Health',
    },
    {
        value: 'Housing and Construction',
        text: 'Housing and Construction',
    },
    {
        value: 'ICT',
        text: 'ICT',
    },
    {
        value: 'Leisure',
        text: 'Leisure',
    },
    {
        value: 'Manufacturing and consumer goods',
        text: 'Manufacturing and consumer goods',
    },
    {
        value: 'Mechanical engeneering',
        text: 'Mechanical engeneering',
    },
    {
        value: 'Media, PR, digital comunication and marketing',
        text: 'Media, PR, digital comunication and marketing',
    },
    {
        value: 'Oil and gas',
        text: 'Oil and gas',
    },
    {
        value: 'Pharmaceuticals',
        text: 'Pharmaceuticals',
    },
    {
        value: 'Personal services',
        text: 'Personal services',
    },
    {
        value: 'Public Administration',
        text: 'Public Administration',
    },
    {
        value: 'Real Estate',
        text: 'Real Estate',
    },
    {
        value: 'Renewable Energy',
        text: 'Renewable Energy',
    },
    {
        value: 'Tourism and Hospitality',
        text: 'Tourism and Hospitality',
    },
    {
        value: 'Waste Management',
        text: 'Waste Management',
    },
    {
        value: 'Water Management',
        text: 'Water Management',
    },
    {
        value: 'Wholesale and retail trade',
        text: 'Wholesale and retail trade',
    },
    {
        value: 'NGO',
        text: 'NGO',
    },
    {
        value: 'Packaging',
        text: 'Packaging',
    },
    {
        value: 'Transport & Logistics',
        text: 'Transport & Logistics',
    },
];

const BusinessSector = ({final}: IBusinessSectorProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data = CHOICES.map((el) => ({
            name: el.text,
            y: 0,
        }));

        final.forEach((submission: any) => {
            CHOICES.forEach((el, index) => {
                if (submission.sector_t1 && submission.sector_t1.indexOf(el.value) > -1) {
                    data[index].y = data[index].y + 1;
                }
            });
        });

        const title = 'Business Sector of the new businesses';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessSector;
