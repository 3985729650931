import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import {Card, CardContent} from "@mui/material";

interface IBusinessProfileProps {
    final: { [key: string]: string }[],
}

const QUESTIONS = [
    'fund_size_t1_0',
    'fund_size_t1_1',
    'fund_size_t1_2',
    'fund_size_t1_3',
    'fund_size_t1_4',
    'fund_size_t1_5',
]

const VolumeOfFundings = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const data: { [key: string]: number } = {
        'FUND_SIZE_T1_5K': 0,
        'FUND_SIZE_T1_15K': 0,
        'FUND_SIZE_T1_30K': 0,
        'FUND_SIZE_T1_50K': 0,
        'FUND_SIZE_T1_100K': 0,
        'FUND_SIZE_T1_+100K': 0,
    };

    final.forEach((submission) => {
        QUESTIONS.forEach((question) => {
            const answer = submission[question]

            if (answer) {
                data[answer] = data[answer] + 1
            }
        })
    })

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Volume of fundings - Resources from family and friends',
                align: 'left'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: [
                    '0 - 5.000 $',
                    '6.000 – 15.000 $',
                    '16.000 - 30.000 $',
                    '31.000 - 50.000 $',
                    '51.000 - 100.000 $',
                    '>100.000 $'
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: "Number",
                data: Object.values(data)
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
}

export default VolumeOfFundings