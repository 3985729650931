import isNumeric from './isNumeric';

export default function makeRatio(amount: number, arr: number[], fixedAmount: number = 3) {
    const ration = +(amount / arr.reduce((a: number, b: number) => a + b, 0)).toFixed(fixedAmount);

    if (isNumeric(ration)) {
        return parseFloat((ration * 100).toFixed(1))
    }

    return 0;
}
