import React from 'react';
import {ISubmissionsUoaData} from '../utils/data';
import InitiativesSummary from './InitiativesSummary';
import Beneficiary from './Beneficiary';

const ProjectDetails = ({iniziativaSubmissions, beneficiarySubmissions, uoaParentId}: ISubmissionsUoaData) => {
    return (
        <>
            <div className='w-full'>
                <div className='section-title'>
                    <div>Cruscotto Progetto</div>
                </div>
                <InitiativesSummary
                    iniziativaSubmissions={iniziativaSubmissions}
                    beneficiarySubmissions={beneficiarySubmissions}
                />
                <Beneficiary
                    iniziativaSubmissions={iniziativaSubmissions}
                    beneficiarySubmissions={beneficiarySubmissions.filter((submission: any) => submission.uoa_parent_id === uoaParentId)}
                />
            </div>
        </>
    );
};

export default ProjectDetails;
