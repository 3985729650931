export default function makeSum(arr: any[], key: string) {
    const number = arr.reduce(function (a, b) {
        return a + (Number(b[key]) || 0);
    }, 0);

    if (number >= 1000000000) {
        return (number / 1000000000).toFixed(1) + 'B';
    } else if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'M';
    } else {
        return new Intl.NumberFormat('en-US').format(number.toFixed(2))
    }
}
