import EnrolledItem from '../../base/EnrolledItem';

interface IJobsCreatedProps {
    baseline: any[];
    final: any[];
}

const JobsCreated = ({baseline, final}: IJobsCreatedProps) => {
    let total = 0;
    let count = 0;

    baseline.forEach((baselineSubmission: any) => {
        const finalSubmission = final.find(
            (finalSubmission: any) => finalSubmission.uoa_id === baselineSubmission.uoa_id
        );

        if (finalSubmission) {
            const baselineCount = baselineSubmission.jobs_t0_men_t0_number + baselineSubmission.jobs_t0_women_t0_number;
            const finalCount = finalSubmission.jobs_t1_men_t1_number + finalSubmission.jobs_t1_women_t1_number;

            if (baselineCount < finalCount) {
                total += finalCount - baselineCount;
                count++;
            }
        }
    });

    const average = count > 0 ? total / count : 0;

    return (
        <div>
            <div className='enrolled-item space-x-4'>
                <EnrolledItem
                    value={average.toFixed(2)}
                    text='Average number of jobs created per entrepreneur'
                />
            </div>
            <div className='enrolled-item space-x-4'>
                <EnrolledItem
                    value={total.toString()}
                    text='Total job created'
                />
            </div>
        </div>
    );
};

export default JobsCreated;
