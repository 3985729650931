import { Typography } from '@mui/material';
import Gender from './Gender';
import Permanent from './Permanent';
import Young from './Young';

interface ITeamCompositionProps {
    final: any[];
}

const TeamComposition = ({final}: ITeamCompositionProps) => {
    return (
        <div>
            <Typography variant='h6'>Composition of the team</Typography>
            <div className='row'>
                <Gender final={final} />
                <Young final={final} />
            </div>
            <br />
            <Permanent final={final} />
        </div>
    );
};

export default TeamComposition;
