import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IWomenManagerProps {
    baseline: any[];
    final: any[];
}

const WomenManager = ({baseline, final}: IWomenManagerProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let totalWomen = 0;
        let totalMen = 0;

        baseline.forEach((baselineSubmission: any) => {
            const finalSubmission = final.find(
                (finalSubmission: any) => finalSubmission.uoa_id === baselineSubmission.uoa_id
            );

            if (finalSubmission) {
                const baselineCount = baselineSubmission.type_contract_t0_managerial_positions;
                const finalCount = finalSubmission.type_contract_t1_mgr_contract_t1;

                if (baselineCount < finalCount) {
                    const women = Math.max(finalSubmission.mgr_women_t1 - baselineSubmission.mgr_women_t0, 0);
                    totalWomen += women;
                    totalMen += finalCount - baselineCount - women;
                }
            }
        });

        const data: any[] = [
            {
                name: 'Women manager',
                y: totalWomen,
            },
            {
                name: 'Men manager',
                y: totalMen,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(
            'Number of high-skill jobs created per entrepreneur divided per gender',
            data
        );
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default WomenManager;
