import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import { percentageFormatter } from '../../helpers/formatters';

interface IBusinessProfileProps {
    submissions: any[];
}

const transform = [
    {
        name: '0-5%',
        value: 'SKILLS_DWT_5',
    },
    {
        name: '6-25%',
        value: 'SKILLS_DWT_25',
    },
    {
        name: '26-50%',
        value: 'SKILLS_DWT_50',
    },
    {
        name: '51-75%',
        value: 'SKILLS_DWT_75',
    },
    {
        name: '76-100%',
        value: 'SKILLS_DWT_100',
    },
];

const ResultsAchieved = ({submissions}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map(({name, value}) => ({
            name,
            y: submissions.filter((submission: any) => submission.skills_dwt === value).length,
        }));

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            title: {
                align: 'left',
                text: 'E4Impact contribution to results',
            },
            xAxis: {
                categories: transform.map((el) => el.name),
            },
            series: [
                {
                    showInLegend: false,
                    type: 'column',
                    data,
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ResultsAchieved;
