import * as React from 'react';
import CustomTable from "../../../shared/Table";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Download} from "@mui/icons-material";
import {download} from "../../../utils/download";

interface INumeroValutatoreProps {
    submissions: {
        uoa_id: number,
        uoa_name: string,
        uoa_custom_field_ente_proponente_51: string,
        user_name: string,
        totale_punteggi_pesati: number,
    }[];
}

const EvaluationsOverview = ({submissions}: INumeroValutatoreProps) => {
    const users = Array
        .from(new Set(submissions.map((submission) => submission.user_name)))
        .sort(function (a: string, b: string) {
            if (a > b) {
                return 1;
            }

            if (a < b) {
                return -1;
            }

            return 0;
        })
    const columns: any[] = [
        ...[
            'Progetto',
            'Ente proponente',
        ],
        ...users,
        ...[
            'Numero di valutazioni',
            'Totale'
        ]
    ];
    const data: any = {};
    let rows: any;

    submissions.forEach(function (submission) {
        if (data.hasOwnProperty(submission.uoa_id)) {
            data[submission.uoa_id][submission.user_name] = submission.totale_punteggi_pesati;
            data[submission.uoa_id].count = data[submission.uoa_id].count + 1;
            data[submission.uoa_id].sum = data[submission.uoa_id].sum + submission.totale_punteggi_pesati;
        } else {
            data[submission.uoa_id] = {
                name: submission.uoa_name,
                field: submission.uoa_custom_field_ente_proponente_51,
                [submission.user_name]: submission.totale_punteggi_pesati,
                count: 1,
                sum: submission.totale_punteggi_pesati
            };
        }
    })

    rows = Object.values(data)
        .map((item: any) => {
            return [
                item.name,
                item.field,
                ...users.map((user: string) => item[user] ?? ''),
                item.count,
                Number.isFinite(item.sum) ? (item.sum / item.count).toFixed(2) : 0,
            ]
        })

    rows.sort(function (a: any, b: any) {
        if (a[0] > b[0]) {
            return 1;
        }

        if (a[0] < b[0]) {
            return -1;
        }

        return 0;
    });

    const handleDownload = () => {
        download([columns, ...rows], 'Numero di valutazioni per progetto')
    }

    return (
        <>
            <div className="content-title">
                <Typography variant="h5">
                    Overview di tutti i progetti
                </Typography>
                <IconButton onClick={handleDownload}>
                    <Download fontSize="small"/>
                </IconButton>
            </div>
            <CustomTable
                columns={columns}
                rows={rows}
                numberRows={Array.from({length: (users.length + 2)}, (_, i) => i + 1)}/>
        </>
    );
};

export default EvaluationsOverview;
