import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from "../../../utils/constants";

interface ICareWorkCoordinatorData {
    submissions: any[];
    uoa: string | number
}

const chartData = () =>  ([
    {
        name: 'Il/La beneficiario/a stesso/a',
        color: COLORS.blue,
        value: 0,
        answers: ['Il/La beneficiario/a stesso/a'],
    },
    {
        name: 'Un familiare',
        color: COLORS.red,
        value: 0,
        answers: ['Il/La coniuge', 'Altri familiari'],
    },
    {
        name: 'Case manager',
        color: COLORS.yellow,
        value: 0,
        answers: ['Case manager privato o del privato sociale', 'Assistente sociale/amministratore di sostegno pubblico/altro servizio pubblico'],
    },
    {
        name: 'Altri soggetti',
        color: COLORS.green,
        value: 0,
        answers: ['Altri caregiver (ad esempio, badanti)', 'other'],
    }
])

const CareWorkCoordinator = ({submissions, uoa}: ICareWorkCoordinatorData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [allOptions, setAllOptions] = useState<Highcharts.Options>();
    const [currentOptions, setCurrentOptions] = useState<Highcharts.Options>();

    const setOptions = (title: string, series: any[]): Highcharts.Options => {
        return {
            chart: {
                type: 'pie',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa',
            },
            title: {
                text: title,
                align: 'left'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                    showInLegend: true
                }
            },
            series
        };
    }

    const calculateSubmissions = () => {
        const allSubmissions: any[] = chartData();
        const currentSubmissions: any[] = chartData();
        const currentCount: number = submissions.filter((submission: any) => submission.uoa_parent_id === uoa).length;
        const allCount = submissions.length;

        submissions.forEach((submission: any) => {
            const index = chartData().findIndex((chart: any) => chart.answers.includes(submission.question18))

            if (index !== -1) {
                allSubmissions[index].value += 1

                if (submission.uoa_parent_id === uoa) {
                    currentSubmissions[index].value += 1
                }
            }
        })

        if (currentCount > 0) {
            setCurrentOptions(setOptions(
                'Il mio progetto',
                [
                    {
                        name: 'Il mio progetto',
                        data: currentSubmissions.map((submission) => ({
                            name: submission.name,
                            y: parseFloat((submission.value / currentCount).toFixed(2)),
                            color: submission.color
                        })),
                    }
                ]
            ));
        }

        if (allCount > 0) {
            setAllOptions(setOptions(
                'Media dei progetti',
                [
                    {
                        name: 'Media dei progetti',
                        data: allSubmissions.map((submission) => ({
                            name: submission.name,
                            y: parseFloat((submission.value / allCount).toFixed(2)),
                            color: submission.color
                        })),
                    }
                ]
            ));
        }
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <div className="attentamente-parent">
            <Card>
                <CardContent>
                    {
                        currentOptions && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={currentOptions}
                                ref={chartComponentRef}
                            />
                        )
                    }
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    {
                        allOptions && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={allOptions}
                                ref={chartComponentRef}
                            />
                        )
                    }
                </CardContent>
            </Card>
        </div>
    );
};

export default CareWorkCoordinator;
