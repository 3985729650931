import * as React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';

interface ISuccessfullyStartProps {
    baseline: any[],
    final: any[],
}

const SuccessfullyStart = ({baseline, final}: ISuccessfullyStartProps) => {
    let value = '0'

    const baseCount = baseline.filter((submission: any) => ['BIZ_IDEA_EM_T0', 'BIZ_IDEA_UNEM_T0'].includes(submission.career_status_t0)).length
    const finalCount = final.filter((submission: any) => ['NEW_BIZ_T1', 'NEW_IDEA_BIZ_T1'].includes(submission.career_status_idea_t1)).length

    if (baseCount > 0) {
        value = (finalCount / baseCount * 100).toFixed()
    }

    return (
        <Card className='chart-container'>
            <CardContent className='space-y-4'>
                <div className='enrolled-item space-x-4'>
                    <Box
                        sx={{
                            width: 58,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        { value }%
                    </Box>
                    <Typography>
                        % of entrepreneurs who started a new business
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default SuccessfullyStart;
