import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface ISameObjectiveData {
    submissions: any[];
    uoa: string | number
}

const ANSWER_MAP = {
    socialEducational: ['Educatori', 'Assistenti', 'Psicologi'],
    health: ['Infermieri', 'Medici', 'Medici specialisti (neurologi, fisiatri, etc.)', 'Fisioterapisti'],
    care: ['OSS/ASA', 'Badanti'],
    other: ['other']
}

const ProjectSameObjective = ({submissions, uoa}: ISameObjectiveData) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);

    const calculateSubmissions = () => {
        let count: number = 0;
        const rows: any[] = [
            {
                name: 'Profili socio-educativi (educatori, assistenti sociali, psicologi, etc.)',
                involvement: 'Sì',
                value: 0,
            },
            {
                name: 'Profili sanitari (infermieri, medici, fisioterapisti, etc.)',
                involvement: 'No',
                value: 0,
            },
            {
                name: 'Profili assistenziali (OSS/ASA, badanti, etc.)',
                involvement: 'No',
                value: 0,
            },
            {
                name: 'Altri profili',
                involvement: 'Sì',
                value: 0,
            },
        ];

        const answers: any[number] = {
            socialEducational: 0,
            health: 0,
            care: 0,
            other: 0
        }

        const currentSubmission = submissions.find((submission: any) => submission.uoa_id === uoa)

        if (currentSubmission) {
            const sameObjective = submissions.filter((submission: any) => submission.question4 === currentSubmission.question4)
            count = sameObjective.length

            sameObjective.forEach((submission: any) => {
                const usedKeys: string[] = [];

                Object.keys(submission).forEach((key: string) => {
                    if (key.startsWith('question17_')) {
                        const value = submission[key]

                        for (const [index, values] of Object.entries(ANSWER_MAP)) {
                            if (values.includes(value) && answers.hasOwnProperty(index) && !usedKeys.includes(index)) {
                                answers[index] += 1
                                usedKeys.push(index)
                            }
                        }
                    }
                })
            })
        }

        if (count > 0) {
            rows[0].value = Math.floor(answers.socialEducational / count * 100);
            rows[1].value = Math.floor(answers.health / count * 100);
            rows[2].value = Math.floor(answers.care / count * 100);
            rows[3].value = Math.floor(answers.other / count * 100);
        }

        setRows(rows)
        setLoading(true)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    loading && (
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label='simple table'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            COINVOLGIMENTO ATTIVITA'
                                        </TableCell>
                                        <TableCell align='right'>
                                            % COINVOLGIMENTO (a confronto con altre attività che hanno la stessa finalità)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {row.involvement}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {row.value}%
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
            </CardContent>
        </Card>
    )
}

export default ProjectSameObjective;
