import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import {COLORS} from '../../../../utils/constants';
import {ISubmissionsData} from '../../utils/data';
import {numberFormat} from '../../../../utils/numberFormat';
import {previsionaleCheckpoint, consuntivoCheckpoint} from '../../index';

const ResponseRate = ({iniziativaSubmissions, beneficiarySubmissions}: ISubmissionsData) => {
    const [data, setData] = useState(0);
    const [loading, setLoading] = useState(false);

    const calcResults = () => {
        let answers = 0;
        let count = 0
        let data = 0

        iniziativaSubmissions
            .filter((submission) => submission.checkpoint_id === previsionaleCheckpoint)
            .forEach((submission) => {
                count = count + beneficiarySubmissions.filter((el) => el.uoa_id === submission.uoa_id).length
                const newSubmission = iniziativaSubmissions.find((el) => el.checkpoint_id === consuntivoCheckpoint && el.uoa_id === submission.uoa_id)

                if (newSubmission) {
                    answers = answers + newSubmission.proj_beneficiaries_total_numero
                }
            })

        if (answers !== 0) {
            data = (count / answers) * 100
        }

        setData(data)
        setLoading(true)
    }

    useEffect(() => {
        calcResults()
    }, []);

    return (
        <>
            {
                loading && (
                    <Card>
                        <CardContent className='text-center text-primary'>
                            <Typography variant='h1' sx={{fontSize: 30, fontWeight: 800}}>
                                {numberFormat(data, 0, ',', '.')}%
                            </Typography>
                            <div className='flex-center'>
                                <Typography>
                                    Tasso di risposta
                                </Typography>
                                <Tooltip
                                    title={<>
                                        Rapporto fra risposte ricevute e partecipanti effettivi
                                    </>}
                                >
                                    <IconButton>
                                        <Info htmlColor={COLORS.libriMain} fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
};

export default ResponseRate;
