import * as React from 'react';
import CustomTable from "../../../shared/ApiTable";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Download} from "@mui/icons-material";
import {download} from "../../../utils/download";
import {addHours} from "../../../utils/dateString";
import {useEffect, useState} from "react";

interface INumeroValutatoreProps {
    submissions: {
        id: number,
        user_name: string,
        uoa_name: string,
        submission_opened_date_time: string,
        submission_updated_date_time: string,
    }[],
    meta: {
        current_page: number,
        total: number,
        per_page: number,
    },
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    getAllSurveys: () => Promise<any[]>
}

const Table = ({
                   submissions,
                   meta,
                   handleChangePage,
                   handleChangeRowsPerPage,
                   getAllSurveys
               }: INumeroValutatoreProps) => {
    const columns: string[] = [
        'ID',
        'Nome',
        'Nome organizzazione',
        'Compilazione iniziata data/ora',
        'Compilazione aggiornata data/ora',
    ];

    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const handleDownload = async () => {
        setLoading(true)
        const data = await getAllSurveys()
        const rows = process(data)

        download([columns, ...rows], 'Submission anagrafiche')
        setLoading(false)
    }

    const process = (submissions: any[]) => {
        const rows: {}[] = []

        submissions.forEach((submission) => {
            rows.push([
                submission.id,
                submission.user_name,
                submission.uoa_name,
                addHours(submission.submission_opened_date_time, 2),
                addHours(submission.submission_updated_date_time, 2),
            ])
        })

        return rows
    }

    useEffect(() => {
        setRows(process(submissions));
    }, []);

    return (
        <>
            <div className="content-title">
                <Typography variant="h5">
                    Submission anagrafiche
                </Typography>
                {
                    !loading && (
                        <IconButton onClick={handleDownload}>
                            <Download fontSize="small"/>
                        </IconButton>
                    )
                }
                {
                    loading && (
                        <>
                            ...loading
                        </>
                    )
                }
            </div>
            {
                rows.length > 0 && (
                    <CustomTable
                        columns={columns}
                        rows={rows}
                        meta={meta}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        numberRows={[6, 12, 13]}/>
                )
            }
        </>
    );
};

export default Table;
