export const TYPE_MBA_STUDENT = 9;
export const TYPE_ACCELERATOR = 102;

export const FIELD_MAPPING: any = {
    uoa_breakdown: {
        [TYPE_ACCELERATOR]: 'uoa_breakdown_accelerator_and_11',
        [TYPE_MBA_STUDENT]: 'uoa_breakdown_mbas_3',
        default: 'uoa_breakdown_mbas_3'
    },
    active_entrepeneurs: {
        [TYPE_ACCELERATOR]: '% of participants who concluded the acceleration program and dropouts',
        [TYPE_MBA_STUDENT]: '% of participants that successfully concluded the MBA and dropouts',
        default: '% of participants that successfully concluded the MBA and dropouts'
    },
    type_of_sector: {
        [TYPE_ACCELERATOR]: 'question3',
        [TYPE_MBA_STUDENT]: 'sector_t0',
        default: 'sector_t0'
    },
    agrifood_sector: {
        [TYPE_ACCELERATOR]: 'question23',
        [TYPE_MBA_STUDENT]: 'question1',
        default: 'question1'
    },
    food_processing_sector: {
        [TYPE_ACCELERATOR]: 'question22',
        [TYPE_MBA_STUDENT]: 'question2',
        default: 'question2'
    },
};

export const getValueByType = (type: string | number, key: string) => {
    if (FIELD_MAPPING.hasOwnProperty(key)) {
        const params = FIELD_MAPPING[key];
        const typeID = Number(type);

        return params.hasOwnProperty(typeID) ? params[typeID] : params.default;
    }

    return 'id';
}