export const groupBy = (obj: any, key: string): any => {
    return obj.reduce(
        function (r: any, a: any) {
            r[a[key]] = r[a[key]] || [];
            r[a[key]].push(a);

            return r;
        },
        Object.create(null)
    )
}

type Entries<T> = {
    [K in keyof T]: any[];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;