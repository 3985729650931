import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';

interface IObtainedFundsProps {
    submissions: any[];
}

const transform = [
    {text: 'Family & friends', value: 'question26'},
    {text: 'Business Angels', value: 'question2'},
    {text: 'Crowdfunding', value: 'question27'},
    {text: 'Incubators and Accelerators', value: 'question35'},
    {text: 'NGO\u2019s loans', value: 'question40'},
    {text: 'NGO’s grants', value: 'question45'},
    {text: 'Public subsidies (e.g. tax credits)', value: 'question50'},
    {text: 'Venture capital', value: 'question60'},
    {text: 'Microfinance', value: 'question65'},
    {text: 'Financial institutions (banks)', value: 'question70'},
    {text: 'Other', value: 'question75'},
]

const ObtainedFunds = ({submissions}: IObtainedFundsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const success: any = [];
        const unSuccess: any = [];
        const pending: any = [];

        Object.values(transform).forEach((item) => {
            success[item.value] = submissions.filter((submission: any) => submission[item.value] === 'item1').length;
            unSuccess[item.value] = submissions.filter((submission: any) => submission[item.value] === 'item3').length;
            pending[item.value] = submissions.filter((submission: any) => submission[item.value] === 'item2').length;
        });

        setOptions({
            chart: {
                type: 'bar',
            },
            title: {
                text: '% of entrepreneurs who obtained the funds',
                align: 'left',
            },
            xAxis: {
                categories: Object.values(transform).map(({text}) => text),
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.0f}%'
                    }
                }
            },
            series: [
                {
                    name: 'Yes',
                    type: 'column',
                    data: Object.values(success).map((value) => Number(value)),
                    color: COLORS.green,
                },
                {
                    name: 'No',
                    type: 'column',
                    data: Object.values(unSuccess).map((value) => Number(value)),
                    color: COLORS.gray,
                },
                {
                    name: 'Pending',
                    type: 'column',
                    data: Object.values(pending).map((value) => Number(value)),
                    color: COLORS.orange,
                }
            ],
        });
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default ObtainedFunds;
