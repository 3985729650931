import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IBusinessProfileProps {
    final: any[],
}

const transform = [
    {
        value: 'scale_im_t1_scale_im_a_t1',
    },
    {
        value: 'scale_im_t1_scale_im_b_t1',
    },
    {
        value: 'scale_im_t1_scale_im_c_t1',
    },
    {
        value: 'scale_im_t1_scale_im_d_t1',
    },
    {
        value: 'scale_im_t1_scale_im_e_t1',
    },
    {
        value: 'scale_im_t1_scale_im_f_t1',
    }
]

const ImpactOutcomes = ({ final }: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data = transform.map(() => 0);

        final.forEach((submission: any) => {
            transform.forEach((el, index) => {
                data[index] = data[index] + Number(submission[el.value])
            })
        })

        const dashboardOptions: any = {
            chart: {
                polar: true,
            },
            title: {
                text: 'Level of perception about the SCALING IMPACT OUTCOMES scale',
                align: 'left'
            },
            xAxis: {
                categories: [
                    'Our organization’s approach allows us to serve potentially large groups of people',
                    'We are able to improve our offerings by expanding market reach (e.g., offering services to more people, adding locations, etc.)',
                    'We have scaled up our capabilities to address our mission',
                    'We have greatly expanded the number of individuals we serve',
                    'We have substantially increased the geographic area we serve',
                    'Our work and approach is transferable to other locations'
                ],
                labels: {
                    enabled: false
                }
            },
            yAxis: {

            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: false
                    }
                },
            },
            series: [
                {
                    data
                },
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default ImpactOutcomes;
