import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

import './styles.scss';

const RegisteredPercentage = () => {
    const value = 0

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 68,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        {value}%
                    </Box>
                    <Typography>
                        Percentage of entrepreneurs registered on the E4Impact B2B platform
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default RegisteredPercentage;
