import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IGenderProps {
    final: any[];
}

const Gender = ({final}: IGenderProps) => {
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = [
            {
                name: 'Women',
                y: final.map((submission: any) => submission.jobs_t1_women_t1_number).reduce((a, b) => a + b, 0),
            },
            {
                name: 'Men',
                y: final.map((submission: any) => submission.jobs_t1_men_t1_number).reduce((a, b) => a + b, 0),
            },
        ];

        const title = '% of women in the team';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default Gender;
