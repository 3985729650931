import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import React, {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import Tasso from '../FSV/Tasso';
import Impatto, {IImpattoData} from './Impatto';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const AttentamenteParent = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const [loading, setLoading] = useState(false);
    const [tassoData, setTassoData] = useState<{ average: number; uoa: number; }>();
    const [impattoData, setImpattoData] = useState<{ average: IImpattoData; uoa: IImpattoData; }>();

    const getSurvey = async () => {
        const hashKey = searchParams.get('key');
        const actual_id = searchParams.get('actual_id');
        const previsioned_id = searchParams.get('previsioned_id');
        const base_id = searchParams.get('base_id');
        const new_id = searchParams.get('new_id');
        const uoa_id = searchParams.get('uoa_id');
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_PARENT || '');

        setUserToken( searchParams.get('token') || '')

        try {
            if (actual_id && previsioned_id && base_id && new_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/parent/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            question: '10_'
                        }
                    }),
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/parent/impact`,
                        params: {
                            base_id,
                            new_id
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setTassoData(responses[0].data.data);
                setImpattoData(responses[1].data.data);

                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getSurvey();
    }, []);

    return (
        <div className="attentamente-parent">
            {
                !loading && (
                    <>
                        {
                            tassoData && (
                                <div>
                                    <div className="section-title">
                                        <div>Tasso di raggiungimento dei beneficiari</div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra il tasso di raggiungimento dei beneficiati attesi per il
                                                vostro progetto a confronto con la media dello stesso valore in tutti i
                                                progetti. Il valore è ottenuto dal rapporto tra i beneficiari che il progetto
                                                prevede di raggiungere secondo la scheda previsionale di ciascuna attività e i
                                                beneficiari effettivamente raggiunti secondo la scheda finale di ciascuna delle
                                                attività concluse.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Tasso data={tassoData}/>
                                </div>
                            )
                        }
                        {
                            impattoData && (
                                <div>
                                    <div className="section-title">
                                        <div>Andamento dei beneficiari presi in carico</div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra l’andamento dei beneficiari presi in carico dal vostro
                                                progetto a confronto
                                                con la media di tutti i progetti. In particolare, si osserva la distribuzione
                                                dei beneficiari presi incarico
                                                (per i quali è stata completata almeno una scheda di aggiornamento) fra: <br/>
                                                i) beneficiari che hanno mostrato segnali di
                                                peggioramento del livello di benessere
                                                (cioè che nella scheda di aggiornamento più recente presentano un numero di
                                                segnali di malessere superiore a quello
                                                registrato nella rispettiva scheda iniziale);<br/>
                                                ii) beneficiari che hanno mostrano segnali di stabilità del livello di
                                                benessere (cioè che
                                                nella scheda di aggiornamento più recente presentano un numero di segnali di
                                                malessere uguale a quello registrato nella rispettiva scheda iniziale)<br/>
                                                iii) beneficiari che hanno mostrano segnali di miglioramento del livello di
                                                benessere (cioè che
                                                nella scheda di aggiornamento più recente presentano un numero di segnali di
                                                malessere inferiore a quello
                                                registrato nella rispettiva scheda iniziale)
                                            </>
                                            }
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <Impatto data={impattoData}/>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default AttentamenteParent;
