import * as React from "react";
import {SyntheticEvent, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {sha256} from "js-sha256";
import {Box, Tabs} from "@mui/material";
import StyledTab from "../../shared/Tabs/StyledTab";
import TabPanel from "../../shared/Tabs/TabPanel";
import Output from "./Output";
import ShortTerm from "./ShortTerm";
import MidTerm from "./MidTerm";
import CustomSelect from "../../shared/CustomSelect";
import Loader from "../../shared/Loader";
import apiCall, {setUserToken} from "../../utils/api";
import LongTerm from "./LongTerm";
import Impact from "./Impact";

import './styles.scss';

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const E4Impact = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_E4_IMPACT || '');
    const containerId = searchParams.get('container_id');
    const typeId = searchParams.get('type_id');
    const baselineId = searchParams.get('baseline_id');
    const finalId = searchParams.get('final_id');
    const hashKey = searchParams.get('key');

    setUserToken( searchParams.get('token') || '')

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState<any[]>([]);
    const [baseline, setBaseline] = useState([]);
    const [final, setFinal] = useState([]);
    const [fields, setFields] = useState<{ id: number, key: string, options: any[] }[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>('All');
    const [tab, setTab] = useState(0);
    const [uoas, setUoas] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [selectedCountry, setSelectedCountry] = useState<string>('All');
    const [isReady, setIsReady] = useState<boolean>(false);
    const [students, setStudents] = useState('0');
    const [women, setWomen] = useState('0');
    const [graduated, setGraduated] = useState<{ yes: number, no: number }>({yes: 0, no: 0});

    const getSurveysData = async () => {
        setLoading(true);

        await getUoaData()
        let selectedField = null;
        const graduatedYearField = fields.find((field: any) => field.key === "anno-diploma")

        if (graduatedYearField && selectedYear !== 'All') {
            selectedField = {[`field_${graduatedYearField.id}`]: selectedYear}
        }

        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    survey_id: baselineId,
                    ...(selectedUoa !== 'All') && {uoa_id: selectedUoa},
                    ...(selectedCountry !== 'All') && {level_id: selectedCountry},
                    additional_data: false,
                    custom_fields: {
                        ...selectedField
                    },
                }
            }),
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    survey_id: finalId,
                    ...(selectedUoa !== 'All') && {uoa_id: selectedUoa},
                    ...(selectedCountry !== 'All') && {level_id: selectedCountry},
                    additional_data: false,
                    custom_fields: {
                        ...selectedField
                    },
                }
            })
        ]

        const responses = await Promise.all(requests);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
            setBaseline(responses[0].data.submissions);
        }

        if (responses.length > 1 && responses[1].data.hasOwnProperty('submissions')) {
            setFinal(responses[1].data.submissions);
        }
    }

    const getUoaData = async () => {
        if (containerId && typeId) {
            const genderField = fields.find((field) => field.key === "genere")
            const graduatedField = fields.find((field) => field.key === "diplomato")
            const selected_levels = selectedCountry === 'All' ? null : [selectedCountry];
            let selectedField = null;
            const graduatedYearField = fields.find((field: any) => field.key === "anno-diploma")

            if (graduatedYearField && selectedYear !== 'All') {
                selectedField = {[`field_${graduatedYearField.id}`]: selectedYear}
            }

            const requests = [
                await apiCall({
                    url: `containers/${container}/uoa`,
                    params: {
                        container_id: containerId,
                        searched: {
                            custom_fields: {
                                ...selectedField
                            },
                            type_id: typeId,
                            selected_levels
                        }
                    }
                })
            ]

            if (genderField) {
                requests.push(await apiCall({
                    url: `containers/${container}/uoa`,
                    params: {
                        container_id: containerId,
                        searched: {
                            custom_fields: {
                                [`field_${genderField.id}`]: genderField.options.find((option: any) => option.key === "female").id,
                                ...selectedField
                            },
                            type_id: typeId,
                            selected_levels
                        }
                    }
                }))
            }

            if (graduatedField) {
                requests.push(await apiCall({
                    url: `containers/${container}/fields/${graduatedField.id}/search`,
                    params: {
                        searched: {
                            custom_fields: {
                                ...selectedField
                            },
                            type_id: typeId,
                            selected_levels
                        }
                    }
                }))
            }

            const responses = await Promise.all(requests);

            setStudents((uoas.length / 250).toFixed(2));

            if (responses.length > 0 && responses[0].data.hasOwnProperty('meta')) {
                setStudents((responses[0].data.meta.total / 250).toFixed(2));
            }

            if (responses.length > 1 && responses[1].data.hasOwnProperty('meta')) {
                setWomen((responses[1].data.meta.total / 86).toFixed(2));
            }

            if (responses.length > 2 && responses[2].data.hasOwnProperty('data')) {
                setGraduated({
                    yes: responses[2].data.data.si ?? 0,
                    no: responses[2].data.data.no ?? 0,
                });
            }
        }
    }

    const getFilterData = async () => {
        if (containerId && hashKey === hashKeyLocal && baselineId && finalId) {
            const requests = [
                await apiCall({
                    url: `containers/${container}/fields`,
                    params: {
                        container_id: containerId,
                        pagination: 'false',
                    }
                }),
                await apiCall({
                    url: `containers/${container}/uoa`,
                    params: {
                        container_id: containerId,
                        pagination: 'false',
                        searched: {
                            type_id: typeId,
                        }
                    }
                }),
                await apiCall({
                    url: `containers/${container}/breakdowns`,
                    params: {
                        container_id: containerId,
                    }
                }),
            ]

            const responses = await Promise.all(requests);

            if (responses.length > 0 && responses[0].data.hasOwnProperty('data')) {
                setFields(responses[0].data.data);
                const graduatedField = responses[0].data.data.find((field: any) => field.key === "anno-diploma")

                if (graduatedField) {
                    setYears(graduatedField.options);
                }
            }

            if (responses.length > 1 && responses[1].data.hasOwnProperty('items')) {
                setUoas(responses[1].data.items.map((item: { id: number, title: string }) => ({
                    name: item.title,
                    id: item.id,
                })));
            }

            if (responses.length > 2 && responses[2].data.hasOwnProperty('items')) {
                const mbas = responses[2].data.items.find((breakdown: any) => breakdown.slug === 'activities')

                if (mbas) {
                    setCountries(mbas.levels.filter((level: any) => level.parent_id === null))
                }
            }
        }
    }

    useEffect(() => {
        getFilterData().then(_ => setIsReady(true));
    }, []);

    useEffect(() => {
        if (isReady) {
            getSurveysData().then(_ => setLoading(hashKey !== hashKeyLocal));
        }
    }, [isReady, selectedUoa, selectedYear, selectedCountry]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <div className="container space-y-8">
            {
                !loading && (
                    <>
                        <div className="filter space-x-4">
                            <CustomSelect
                                items={countries}
                                value={selectedCountry}
                                onChange={(value: any) => setSelectedCountry(value)}
                                label="Country"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={years}
                                value={selectedYear}
                                onChange={(value: any) => setSelectedYear(value)}
                                label="Academic Year"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={uoas}
                                value={selectedUoa}
                                onChange={(value: any) => setSelectedUoa(value)}
                                label="Beneficiary"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                        </div>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs
                                value={tab}
                                onChange={handleChange}
                                aria-label="tabs"
                            >
                                <StyledTab label="OUTPUT" {...tabProps(0)} />
                                <StyledTab label="SHORT-TERM OUTCOMES" {...tabProps(1)} />
                                <StyledTab label="MID-TERM OUTCOMES" {...tabProps(1)} />
                                <StyledTab label="LONG-TERM OUTCOMES" {...tabProps(1)} />
                                <StyledTab label="IMPACT" {...tabProps(1)} />
                            </Tabs>
                        </Box>

                        <TabPanel value={tab} index={0}>
                            <Output
                                baseline={baseline}
                                students={students}
                                women={women}
                                graduated={graduated}
                            />
                        </TabPanel>

                        <TabPanel value={tab} index={1}>
                            <ShortTerm
                                baseline={baseline}
                                final={final}
                            />
                        </TabPanel>

                        <TabPanel value={tab} index={2}>
                            <MidTerm
                                final={final}
                                baseline={baseline}
                            />
                        </TabPanel>

                        <TabPanel value={tab} index={3}>
                            <LongTerm
                                final={final}
                                baseline={baseline}
                            />
                        </TabPanel>

                        <TabPanel value={tab} index={4}>
                            <Impact
                                final={final}
                                baseline={baseline}
                            />
                        </TabPanel>
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
}

export default E4Impact;