import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Impatto, {IImpattoData} from '../Impatto';
import {getEntries, groupBy} from '../../../utils/groupBy';

interface IDataProps {
    baseData: any[],
    newData: any[],
    uoa: string | number,
}

const ANSWERS = [
    '2',
    '3',
];

const FrequencyExtraSchoolActivities = ({baseData, newData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: IImpattoData; uoa: IImpattoData; }>({
        average: {
            same: 0,
            worse: 0,
            better: 0
        },
        uoa: {
            same: 0,
            worse: 0,
            better: 0
        }
    });

    const calcData = () => {
        let average = {
            same: 0,
            worse: 0,
            better: 0
        };
        let current = {
            same: 0,
            worse: 0,
            better: 0
        };

        for (const [parent, newSubmissions] of getEntries(groupBy(newData, 'uoa_parent_id'))) {
            const calc = {
                same: 0,
                worse: 0,
                better: 0
            };
            let newSubmissionsCount = 0;
            let baseSubmissionsCount = 0;
            const baseSubmissions = baseData.filter((submission) => submission.uoa_parent_id === Number(parent));

            newSubmissions.forEach((newSubmission: any) => {
                for (const [question, answer] of Object.entries(newSubmission)) {
                    if (question.startsWith('5') && answer === '1') {
                        newSubmissionsCount++;
                    }
                }
            })

            baseSubmissions.forEach((baseSubmission) => {
                for (const [question, answer] of Object.entries(baseSubmission)) {
                    if (question.startsWith('15') && ANSWERS.includes(answer as string)) {
                        baseSubmissionsCount++;
                    }
                }
            })

            if (newSubmissionsCount > baseSubmissionsCount) {
                calc.worse++;
            } else if (newSubmissionsCount < baseSubmissionsCount) {
                calc.better++;
            } else {
                calc.same++;
            }

            average = {
                same: +calc.same,
                worse: +calc.worse,
                better: +calc.better
            };

            if (Number(parent) === Number(uoa)) {
                current = calc;
            }
        }

        setData({
            average,
            uoa: current
        });
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>Variazione della frequenza di attività extra-scolastiche</div>
                <Tooltip
                    title={<>
                        Il grafico mostra le eventuali variazioni del tipo di rete amicale dei
                        beneficiari presi in carico dal vostro progetto a confronto con la media
                        di tutti i progetti. In particolare, si osserva la distribuzione dei
                        beneficiari presi incarico (per i quali è stata completata almeno
                        una scheda di aggiornamento) fra: <br/>
                        1) beneficiari che hanno aumentato la dimensione della loro
                        rete amicale (cioè che nella scheda di aggiornamento più recente sono
                        passati dalla risposta “Ha un solo amico/a o non ha amici”, registrata
                        nella rispettiva scheda iniziale, a una delle altre due risposte
                        possibili; oppure dalla risposta “Ha pochi amici, ma non un gruppo”
                        alla risposta “Ha uno o più gruppi di amici”); <br/>
                        2) beneficiari che hanno mantenuto costante la dimensione della loro
                        rete amicale (cioè che nella scheda di aggiornamento più recente
                        presentano la stessa risposta registrato nella rispettiva scheda
                        iniziale); <br/>
                        3) beneficiari che hanno diminuito la dimensione della loro rete
                        amicale (cioè che nella scheda di aggiornamento più recente sono
                        passati dalla risposta “Ha uno o più gruppi di amici”, registrata nella
                        rispettiva scheda iniziale, a una delle altre due risposte possibili;
                        oppure dalla risposta “Ha pochi amici, ma non un gruppo” alla risposta
                        “Ha un solo amico/a o non ha amici”)
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Impatto
                    data={data}
                    categories={[
                        {
                            name: 'Beneficiari con diminuzione delle dimensioni della rete amicale',
                            key: 'better'
                        },
                        {
                            name: 'Beneficiari con andamento stabile delle dimensioni della rete amicale',
                            key: 'same'
                        },
                        {
                            name: 'Beneficiari con aumento delle dimensioni della rete amicale',
                            key: 'worse'
                        }
                    ]}
                />
            }
        </div>
    );
};

export default FrequencyExtraSchoolActivities;
