import {Autocomplete, FormControl, TextField} from '@mui/material';

interface ICustomSelectProps {
    items: {
        id: number | string;
        name: string;
    }[];
    value: any;
    label: string;
    className?: string;
    defaultVariant?: string;
    loading?: boolean;
    onChange: (value: any) => void;
    onInputChange?: (event: React.ChangeEvent<{}>, value: string) => void;
    hidden?: boolean;
    noOptionsText?: string;
    disableCloseOnSelect?: boolean;
    defaultLabel?: string;
}

const CustomSelect = ({
    items,
    value,
    label,
    className,
    defaultVariant,
    loading,
    onInputChange,
    onChange,
    hidden = false,
    noOptionsText = 'No options',
    disableCloseOnSelect = false,
    defaultLabel = 'All',
}: ICustomSelectProps) => {
    const options = items.map((item: any) => ({
        id: item.id,
        label: item.name,
    }));

    const el = options.find((item) => item.id === value);

    return (
        <FormControl className={className}>
            <Autocomplete
                value={el?.label ?? defaultLabel}
                disableCloseOnSelect={disableCloseOnSelect}
                onInputChange={onInputChange}
                onChange={(event: any, newValue: {id: any; label: string}) => {
                    if (newValue === null && typeof defaultVariant === 'string') {
                        onChange(defaultVariant);
                    } else if (newValue) {
                        onChange(newValue.id);
                    }
                }}
                options={options}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                    />
                )}
                hidden={hidden}
                noOptionsText={noOptionsText}
                getOptionKey={(option) => option.id}
            />
        </FormControl>
    );
};

export default CustomSelect;
