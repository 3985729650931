import React from 'react';

import './styles.scss';

const NoResults = () => {
  return (
    <div className="no-results">
      Non ci sono valutazioni da visualizzare
    </div>
  );
};

export default NoResults;
