import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import makeAverage from "../../../../utils/makeAverage";

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const EBITDA = ({final, baseline}: IBusinessProfileProps) => {
    let baselineCount = 0;
    let finalCount = 0;
    const baselineAverageTurnover: number[] = []
    const finalAverageTurnover: number[] = []

    baseline.forEach((submission: any) => {
        baselineCount = baselineCount + submission.ebitda_t_1_ebitda_amount
        baselineAverageTurnover.push(submission.ebitda_t_1_ebitda_amount)
    })

    final.forEach((submission: any) => {
        finalCount = finalCount + submission.ebitda_t1_value
        finalAverageTurnover.push(submission.ebitda_t1_value)
    })

    const valueTurnover = (finalCount / baselineCount * 100).toFixed(1)

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{valueTurnover}
                    </Box>
                    <Typography>
                        % of EBITDA
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        $ {makeAverage(baselineAverageTurnover)}
                    </Box>
                    <Typography>
                        Average turnover BASELINE IMPACT
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        $ {makeAverage(finalAverageTurnover)}
                    </Box>
                    <Typography>
                        Average turnover FINAL IMPACT
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default EBITDA;
