import * as React from "react";
import BusinessModelling from "./BusinessModelling";
import EntrepreneurialOrientation from "./EntrepreneurialOrientation";
import ImpactMeasurement from "./ImpactMeasurement";
import ImprovementLevel from "./ImprovementLevel";
import FinancialPlanning from "./FinancialPlanning";
import RegisteredPercentage from "./RegisteredPercentage";
import {Typography} from "@mui/material";

interface IShortTermProps {
    baseline: any[],
    final: any[],
}

const ShortTerm = ({baseline, final}: IShortTermProps) => {
    return (
        <div className="row">
            <div className="col-span-2">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    SHORT-TERM OUTCOMES
                </Typography>
                <div className="flex text-sm">
                    <div className="mx-auto">
                        <div className="mt-1">
                            <div>
                                Participants acquire skills in:
                            </div>
                            <ul className="my-0">
                                <li>
                                    Business Modeling
                                </li>
                                <li>
                                    Financial planning
                                </li>
                                <li>
                                    Impact Measurement
                                </li>
                                <li>
                                    Pitching and Communicating
                                </li>
                            </ul>
                        </div>

                        <div className="mt-1">
                            <div>
                                Participants:
                            </div>
                            <ul className="my-0">
                                <li>
                                    Expand network
                                </li>
                                <li>
                                    Strengthen entrepreneurial mindset
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <div>
                <BusinessModelling baseline={baseline} final={final}/>
            </div>
            <div>
                <EntrepreneurialOrientation baseline={baseline} final={final}/>
            </div>
            <div>
                <ImpactMeasurement baseline={baseline} final={final}/>
            </div>
            <div>
                <ImprovementLevel baseline={baseline} final={final}/>
            </div>
            <div>
                <FinancialPlanning baseline={baseline} final={final}/>
            </div>
            <div>
                <RegisteredPercentage/>
            </div>
        </div>
    )
}

export default ShortTerm