import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from "@mui/material";

interface IPieChartProps {
    series: any[],
    title: string,
    fractionDigits: number,
}

const PieChart = ({series, title, fractionDigits}: IPieChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                height: 300,
                type: 'pie',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: title,
                align: 'left'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                    showInLegend: true
                }
            },
            legend: {
                align: 'left',
                floating: false,
                layout: 'vertical',
                verticalAlign: 'middle',
                // @ts-ignore
                labelFormatter: function () {
                    // @ts-ignore
                    let value = (this.y / this.total * 100).toFixed(fractionDigits)

                    if (value === 'NaN') {
                        value = 0
                    }

                    // @ts-ignore
                    return `${this.name} - ${value}%`
                }
            },
            series,
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [series]);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
}

export default PieChart;