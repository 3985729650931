import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';

require('highcharts/modules/wordcloud')(Highcharts);

interface ITypeOfChannelsProps {
    final: any[],
}

interface IWordcloudData {
    name: string,
    weight: number,
}

const TypeOfChannels = ({final}: ITypeOfChannelsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data: IWordcloudData[] = [];

        final.forEach((submission: any) => {
            const answer = submission.sale_channels_name_t1;

            if (answer) {
                let index = data.findIndex((el: IWordcloudData) => el.name === answer);

                if (index !== -1) {
                    data[index].weight += 1;
                } else {
                    data.push({
                        name: answer,
                        weight: 1,
                    });
                }
            }
        })

        const dashboardOptions: any = {
            title: {
                text: 'Type of new sales channels',
                align: 'left'
            },
            tooltip: {
                headerFormat: '<span style="font-size: 16px"><b>{point.key}</b>' +
                    '</span><br>'
            },
            series: [{
                type: 'wordcloud',
                data,
                name: 'Type'
            }],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default TypeOfChannels;
