import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import {numberFormat} from '../../../../utils/numberFormat';
import {ISubmissionsData} from '../../utils/data';
import {COLORS} from '../../../../utils/constants';
import {previsionaleCheckpoint, consuntivoCheckpoint} from '../../index';
import makeAverage from '../../../../utils/makeAverage';

const ActualParticipants = ({iniziativaSubmissions}: ISubmissionsData) => {
    const [data, setData] = useState(0);
    const [loading, setLoading] = useState(false);

    const calcResults = () => {
        const answers: any = {};

        iniziativaSubmissions
            .filter((submission) => submission.checkpoint_id === previsionaleCheckpoint)
            .forEach((submission) => {
                const newSubmission = iniziativaSubmissions.find((el) => el.checkpoint_id === consuntivoCheckpoint && el.uoa_id === submission.uoa_id)

                if (newSubmission) {
                    if (!answers.hasOwnProperty(submission.uoa_id)) {
                        answers[submission.uoa_id] = 0;
                    }

                    answers[submission.uoa_id] = answers[submission.uoa_id] + newSubmission.proj_beneficiaries_total_numero
                }
            })

        setData(makeAverage(Object.values(answers), 2))
        setLoading(true)
    }

    useEffect(() => {
        calcResults()
    }, []);

    return (
        <>
            {
                loading && (
                    <Card>
                        <CardContent className='text-center text-primary'>
                            <Typography variant='h1' sx={{fontSize: 30, fontWeight: 800}}>
                                {numberFormat(data, 2, ',', '.')}
                            </Typography>
                            <div className='flex-center'>
                                <Typography>
                                    Partecipanti effettivi
                                </Typography>
                                <Tooltip
                                    title={<>
                                        Partecipanti raggiunti in media dalle iniziative dello stesso tipo
                                    </>}
                                >
                                    <IconButton>
                                        <Info htmlColor={COLORS.libriMain} fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
};

export default ActualParticipants;
