import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const ScaledBusiness = ({ final, baseline }: IBusinessProfileProps) => {
    let value = 0;
    const divider = baseline.filter((submission: any) => ['BIZ>2Y_T0', 'BIZ<2Y_T0'].includes(submission.career_status_t0)).length

    if (divider > 0) {
        value = final.filter((submission: any) => ['BIZ_SCALE_T1', 'BIZ_NEW_T1'].includes(submission.career_status_biz_t1)).length / divider
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="space-y-4">
                    <Typography sx={{ fontWeight: 600 }}>
                        % entrepreneurs that had scaled up their business
                    </Typography>
                    <Box
                        sx={{
                            width: 88,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{(value * 100).toFixed(1)}%
                    </Box>
                </div>
            </CardContent>
        </Card>
    );
};

export default ScaledBusiness;
