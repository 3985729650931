import axios from 'axios';
import {API_URL, appClient} from './constants';

export const getToken = () => localStorage.getItem('token');
export const removeToken = () => localStorage.removeItem('token');
export const setToken = (token: string) => localStorage.setItem('token', token);

const loginUser = async () => {
    try {
        const refreshResponse = await apiCall({
            url: 'token',
            method: 'POST',
            data: {
                client: appClient,
            }
        });

        const response = await apiCall({
            url: 'token',
            method: 'PUT',
            data: {
              refresh_token: refreshResponse.data?.refresh_token,
            }
        });

        setToken(response.data?.access_token);

        return true;
    } catch (e) {
        return false;
    }
};

export default function apiCall(params: any) {
    const defaultParams = {
        baseURL: API_URL,
        method: 'get',
        data: {},
        url: '',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const configParams = {
        ...defaultParams,
        ...params
    };
    const instance = axios.create(configParams);

    instance.interceptors.request.use(config => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    instance.interceptors.response.use(
        res => res,
        async error => {
            const originalRequest = error.config;

            if (error.response?.status === 401 && error.response.data?.message === 'Token has expired' && !originalRequest._retry) {
                originalRequest._retry = true;
                await loginUser();

                return apiCall(originalRequest);
            }

            return Promise.reject(error);
        }
    );

    return instance(configParams);
}

export const setUserToken = (token = '') => {
    removeToken();
    setToken(token);
}