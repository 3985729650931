import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
}

const ClosedAgreement = ({ final }: IBusinessProfileProps) => {
    const value = final.filter((submission: any) => submission.trade_agr_t1 === 'Yes').length / final.length;

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 58,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        {value * 100}
                    </Box>
                    <Typography>
                        % of entrepreneurs that closed the agreement
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default ClosedAgreement;
