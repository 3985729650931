import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IFailureFactorsProps {
    final: any[];
}

const transform = [
    {
        name: 'I stop the business for personal reasons although it was going well',
        value: 'BIZ_CLOSE_FAC_PERS',
    },
    {
        name: 'I stop the business because it wasn’t going well',
        value: 'BIZ_CLOSE_FAC_BAD',
    },
    {
        name: 'I sold the business',
        value: 'BIZ_CLOSE_FAC_SOLD',
    },
];

const FailureFactors = ({final}: IFailureFactorsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: final.filter((submission: any) => submission.biz_close_fac === el.value).length,
        }));

        const title = 'Failure factors for business closing';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default FailureFactors;
