import * as React from "react";
import {ISubmissionProps} from "../utils/models";
import Total from "./Total";
import Source from "./Source";
import {useEffect, useState} from "react";
import {getSurveySubmissions} from "../utils/submissions";
import Loader from "../../../shared/Loader";

const Partners = ({container, selectedUoa, selectedYear, survey}: ISubmissionProps) => {
    const [loading, setLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);

    const getSurveysData = async () => {
        setLoading(true)

        if (survey) {
            const data = await getSurveySubmissions({container, survey_id: survey, selectedUoa, selectedYear})
            setSubmissions(data)
        }
    }

    useEffect(() => {
        getSurveysData().then(() => setLoading(false));
    }, [selectedUoa, selectedYear]);

    return (
        <>
            {
                !loading && (
                    <>
                        <div className="content-title col-span-2">
                            Total number of partners
                        </div>
                        <div className="space-y-4">
                            <Total submissions={submissions}/>
                            <Source submissions={submissions}/>
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container col-span-2">
                        <Loader size={60}/>
                    </div>
                )
            }
        </>
    );
}

export default Partners;