import LibriProject from "../containers/LibriProject";

export const API_URL = process.env.REACT_APP_API_URL;
export const appClient = process.env.REACT_APP_CLIENT;

export const COLORS = {
  blue: '#4285f4',
  red: '#ea4236',
  yellow: '#fcbc00',
  gray: '#A5A5A5',
  green: '#34a754',
  orange: '#ff6d01',
  turquoise: '#46bec6',
  lightBlue: '#7ba9f7',
  pavesiMain: '#699418',
  attentamenteMain: '#002f87',
  networkMain: '#ad3f10',
  wiaMain: '#a8a29e',
  libriMain: '#002f87',
  unipolisMain: '#002a4f',
  cdpMain: '#405464',
  e4ImpactMain: '#009d6f',
  feba: '#006538',
};
export const EXPORTING = {
  buttons: {
    contextButton: {
      menuItems: [
        'printChart',
        'separator',
        'downloadPNG',
        'downloadJPEG',
        'downloadSVG'
      ]
    }
  }
}
