import {useEffect, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import EnrolledItem from '../../base/EnrolledItem';

interface IEntrepreneursSocialImpactProps {
    submissions: any[];
}

const PRODUCT_OR_SERVICE = 'Item 3';
const PEOPLE = 'Item 4';

const EntrepreneursSocialImpact = ({submissions}: IEntrepreneursSocialImpactProps) => {
    const [value, setValue] = useState<number>(0);

    const calculateSubmissions = () => {
        let data: number = 0;

        submissions.forEach((submission) => {
            for (let i = 0; i < 4; i++) {
                const key = `question24_${i}`;
                const value = submission[key];

                if ([PRODUCT_OR_SERVICE, PEOPLE].includes(value)) {
                    data++;
                }
            }
        });

        setValue(data);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                <div className='enrolled-item space-x-4'>
                    <EnrolledItem
                        value={((value / submissions.length) * 100).toFixed(2)}
                        text='% Entrepreneurs with social impact purpose (option 3 or 4)'
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default EntrepreneursSocialImpact;
