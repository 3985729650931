import * as React from "react";
import {IFoodBanksProps} from "../utils/models";
import TotalFoodOut from "./TotalFoodOut";
import FoodOutDestination from "./FoodOutDestination";

const FoodOut = ({ submissions }: IFoodBanksProps) => {
    return (
       <>
           <div className="content-title col-span-2">
               Food OUT
           </div>
           <div className="space-y-4">
               <TotalFoodOut submissions={submissions}/>
               <FoodOutDestination submissions={submissions}/>
           </div>
       </>
    );
}

export default FoodOut;