import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tab from '@mui/material/Tab';

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(() => ({
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '1.5rem',
}));

export default StyledTab;