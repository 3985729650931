import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from "../../../utils/constants";

interface ITassoProps {
    data: {
        average: any;
        uoa: any;
    };
}

const KEYS = [
    'non_cohabiting',
    'with_problematic',
    'irregular',
    'shortage',
    'disorders',
    'regular',
]

const Radar = ({data}: ITassoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [
                    'Beneficiari che convivono con la famiglia di origine',
                    'Convivenza con famigliari problematici',
                    'Esperienze di bocciatura',
                    'Scarsità di amici',
                    'Presenza disturbi psicologici/psichiatrici',
                    'Frequenza scolastica non regolare',
                ]
            },
            series: [
                {
                    type: 'line',
                    name: 'Il mio progetto',
                    data: KEYS.map((key) => data.uoa.hasOwnProperty(key) ? data.uoa[key] : 0),
                    color: COLORS.blue,
                },
                {
                    type: 'line',
                    name: 'Media dei progetti',
                    data: KEYS.map((key) => data.average.hasOwnProperty(key) ? data.average[key] : 0),
                    color: COLORS.red,

                },
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>

    );
};

export default Radar;
