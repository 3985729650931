import {Card, CardContent} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS} from '../../../../utils/constants';

interface ILevelOfImprovementProps {
    baseline: any[];
    final: any[];
}

const CATEGORIES = [
    {
        name: 'Never',
        baseAnswer: 'NEVER_T0',
        finalAnswer: 'NEVER_T1',
    },
    {
        name: 'Sometimes',
        baseAnswer: 'SOMETIMES_T0',
        finalAnswer: 'SOMETIMES_T1',
    },
    {
        name: 'Yearly',
        baseAnswer: 'YEARLY_T0',
        finalAnswer: 'YEARLY_T1',
    },
    {
        name: 'Twice a year',
        baseAnswer: 'TWICE A YEAR_T0',
        finalAnswer: 'TWICE A YEAR_T1',
    },
    {
        name: 'Quarterly',
        baseAnswer: 'QUARTERLY_T0',
        finalAnswer: 'QUARTERLY_T1',
    },
];

const LevelOfImprovement = ({final, baseline}: ILevelOfImprovementProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const baselineData = CATEGORIES.map(
            ({baseAnswer}) => baseline.filter((submission: any) => submission.bookkeeping_freq_t0 === baseAnswer).length
        );
        const finalData = CATEGORIES.map(
            ({finalAnswer}) => final.filter((submission: any) => submission.bookkeeping_freq_t1 === finalAnswer).length
        );

        const dashboardOptions: any = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Level of improvement of book keeping (frequency)',
                align: 'left',
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: CATEGORIES.map((category) => category.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
                line: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            legend: {
                verticalAlign: 'top',
                x: 0,
                y: 0,
            },
            series: [
                {
                    name: 'BASELINE',
                    data: baselineData,
                    color: COLORS.blue,
                },
                {
                    name: 'FINAL',
                    data: finalData,
                    color: COLORS.yellow,
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent className='space-y-4'>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default LevelOfImprovement;
