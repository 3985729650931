import * as React from 'react';
import {Card, CardContent} from '@mui/material';

interface IDataImageProps {
    image: string;
    value: string | number;
    text: string;
}

const DataImage = ({image, value, text}: IDataImageProps) => {
    return (
        <div className='flex flex-wrap'>
            <div
                className='col pa-2'
                style={{minWidth: '50%'}}
            >
                <Card
                    className='chart-container'
                    style={{textAlign: 'center'}}
                >
                    <CardContent>
                        <img
                            src={image}
                            alt=''
                            style={{maxWidth: '100%'}}
                        />
                    </CardContent>
                </Card>
            </div>
            <div className='col pa-2'>
                <Card
                    className='chart-container h-full'
                    style={{whiteSpace: 'nowrap'}}
                >
                    <CardContent className='card-content'>
                        <div className='card_value'>{value}</div>
                        <div className='card_text'>{text}</div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default DataImage;
