import React, {useEffect, useRef, useState} from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IMainReasonOfFailureProps {
    submissions: any[];
}

const transform: any = {
    item1: 'Issues related to the business model',
    item2: 'The requested amount was too high',
    item3: 'The skills of the team were not strong enough',
    item4: 'I don\'t know',
}

const MainReasonOfFailure = ({submissions}: IMainReasonOfFailureProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let values = submissions.map((submission) => submission.question12);
        values = values.filter((value) => value !== null && value !== '');

        let data: any = [];

        values.forEach((value: any) => {
            const answer = transform[value];

            if (!answer) return;

            const index = data.findIndex((item: any) => item.name === answer);

            if (index === -1) {
                data.push({name: answer, y: 1});
            } else {
                data[index].y += 1;
            }
        });

        const dashboardOptions: any = getDefaultPieFormatting('Main reason of failure in obtaining the funds', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default MainReasonOfFailure;
