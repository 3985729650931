import * as React from 'react';
import {Typography} from '@mui/material';
import RequestedFundsBefore from './RequestedFundsBefore';
import FundsKind from './FundsKind';
import ObtainedFundsBefore from './ObtainedFundsBefore';
import MainReasonOfFailure from './MainReasonOfFailure';
import RequestedFundsDuring from './RequestedFundsDuring';
import FundsKindFinal from './FundsKindFinal';
import ObtainedFunds from './ObtainedFunds';
import IncreasedGeographicalArea from './IncreasedGeographicalArea';
import OpenedNewSales from './OpenedNewSales';
import ListOfChannels from './ListOfChannels';
import MainSuccessFactors from './MainSuccessFactors';

interface IAcceleratorMidTermProps {
    final: any[];
    baseline: any[];
}

const AcceleratorMidTerm = ({final, baseline}: IAcceleratorMidTermProps) => {
    return (
        <div>
            <Typography variant='h5'>BUSINESS PERFORMANCE</Typography>
            <br/>
            <Typography variant='h6'>Funds raised before</Typography>
            <div className='row'>
                <div>
                    <RequestedFundsBefore baseline={baseline}/>
                </div>
                <div>
                    <FundsKind
                        submissions={baseline}
                    />
                </div>
            </div>
            <br/>

            <div className='row'>
                <div>
                    <ObtainedFundsBefore baseline={baseline}/>
                </div>
                <div>
                    <MainReasonOfFailure submissions={baseline}/>
                </div>
            </div>
            <br/>

            <Typography variant='h6'>Funds raised during the program</Typography>
            <div className='row'>
                <div>
                    <RequestedFundsDuring submissions={final}/>
                </div>
                <div>
                    <FundsKindFinal
                        submissions={final}
                    />
                </div>
            </div>
            <br/>
            <div className='row'>
                <div>
                    <ObtainedFunds submissions={final}/>
                </div>
                <div>
                    <MainSuccessFactors submissions={final} />
                </div>
            </div>

            <br/>

            <Typography variant='h6'>Geographical area of activity </Typography>
            <div className='row'>
                <div>
                    <IncreasedGeographicalArea baseline={baseline} final={final} />
                </div>
                <div>
                    <OpenedNewSales submissions={final} />
                </div>
            </div>
            <ListOfChannels submissions={final} />
        </div>
    );
};

export default AcceleratorMidTerm;
