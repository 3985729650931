import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS, EXPORTING} from '../../../utils/constants';
import makeRatio from "../../../utils/makeRatio";

interface ITassoData {
    submissions: any[];
    actualSubmissions: any[];
    uoa: string | number
}

interface ISubmissionData {
    submissions: number;
    actual_submissions: number;
}

const AGE_GROUP_KEYS = [
    'question13_persone_anziane_di_eta_65_75_numero',
    'question13_persone_anziane_di_eta_75_85_numero',
    'question13_persone_anziane_over_85_numero',
]

const Tasso = ({submissions, actualSubmissions, uoa}: ITassoData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const allSubmissions: ISubmissionData = {
            submissions: 0,
            actual_submissions: 0,
        }
        const currentSubmissions: ISubmissionData = {
            submissions: 0,
            actual_submissions: 0,
        }

        const currentSubmission = submissions.find((submission: any) => submission.uoa_id === uoa)
        const currentActualSubmission = actualSubmissions.find((submission: any) => submission.uoa_id === uoa)

        if (currentSubmission) {
            submissions
                .filter((submission: any) => submission.question4 === currentSubmission.question4)
                .forEach((submission: any) => {
                    const ageGroupAnswer = AGE_GROUP_KEYS.map(key => submission[key] || 0)
                        .reduce((a, b) => (a + b), 0)

                    allSubmissions.submissions += ageGroupAnswer;

                    if (submission.uoa_id === uoa) {
                        currentSubmissions.submissions += ageGroupAnswer;
                    }
                })
        }

        if (currentActualSubmission) {
            actualSubmissions
                .filter((submission: any) => submission.question4 === currentActualSubmission.question4)
                .forEach((submission: any) => {
                    const ageGroupAnswer = AGE_GROUP_KEYS.map(key => submission[key] || 0)
                        .reduce((a, b) => (a + b), 0)

                    allSubmissions.actual_submissions += ageGroupAnswer;

                  if (submission.uoa_id === uoa) {
                        currentSubmissions.actual_submissions += ageGroupAnswer;
                    }
                })
        }

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'La mia attività e Media dei progetti (stessa attività)',
                align: 'left'
            },
            xAxis: {
                categories: ['La mia attività', 'Media dei progetti (stessa attività)']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                }
            },
            series: [
                {
                    showInLegend: false,
                    color: COLORS.blue,
                    name: '',
                    data: [
                        makeRatio(currentSubmissions.actual_submissions, [currentSubmissions.submissions]),
                        makeRatio(allSubmissions.actual_submissions, [allSubmissions.submissions]),
                    ]
                },
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Tasso;
