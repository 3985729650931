import {useEffect, useState} from 'react';
import {IFoodBanksProps} from "../../utils/models";
import PieChart from "../../PieChart";

const DonatedFood = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let donatedDistribution = 0, donatedManufacturing = 0, donatedHoreca = 0;

        submissions.forEach((submission) => {
            donatedDistribution = donatedDistribution + (Number(submission.donated_food_from_distribution_sector) || 0)
            donatedManufacturing = donatedManufacturing + (Number(submission.donated_food_from_manufacturing_sector) || 0)
            donatedHoreca = donatedHoreca + (Number(submission.donated_food_from_horeca_and_catering_sector) || 0)
        })

        setSeries([{
            name: 'Sources',
            size: "100%",
            innerSize: '50%',
            data: [
                {
                    name: 'Donated food from distribution sector',
                    y: donatedDistribution,
                    color: '#98d9d9'
                },
                {
                    name: 'Donated food from manufacturing sector',
                    y: donatedManufacturing,
                    color: '#88bf4d'
                },
                {
                    name: 'Donated food from HORECA and catering sector',
                    y: donatedHoreca,
                    color: '#f2a86f'
                },
            ],
        }]);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series && (
                    <PieChart
                        title="Donated Food (Sources)"
                        fractionDigits={3}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default DonatedFood;