import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {IPavesiColumnsProps} from '../../utils/models';
import mapAnswers from '../../utils/mapAnswers';

interface IIstruzioneProps extends IPavesiColumnsProps {
    question: string;
}

const Istruzione = ({submissions, checkpoints, question}: IIstruzioneProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const answersMapper: any = {
            Università: {},
            'Scuola Secondaria Superiore': {},
            'Scuola Secondaria Inferiore': {},
            'Scuola Primaria': {},
            Sconosciuto: {},
            Nessuno: {},
            empty: {},
        };

        mapAnswers(submissions, answersMapper, question);

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: checkpoints.map((checkpoint) => checkpoint.name),
            },
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: true,
                },
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    name: 'Università',
                    color: '#7ba9f7',
                    data: checkpoints.map((checkpoint) => answersMapper['Università'][checkpoint.id] || 0),
                },
                {
                    name: 'Scuola Secondaria Superiore',
                    color: '#46bec6',
                    data: checkpoints.map(
                        (checkpoint) => answersMapper['Scuola Secondaria Superiore'][checkpoint.id] || 0
                    ),
                },
                {
                    name: 'Scuola Secondaria Inferiore',
                    color: '#ff6d01',
                    data: checkpoints.map(
                        (checkpoint) => answersMapper['Scuola Secondaria Inferiore'][checkpoint.id] || 0
                    ),
                },
                {
                    name: 'Scuola Primaria',
                    color: '#34a754',
                    data: checkpoints.map((checkpoint) => answersMapper['Scuola Primaria'][checkpoint.id] || 0),
                },
                {
                    name: 'Sconosciuto',
                    color: '#fcbc00',
                    data: checkpoints.map((checkpoint) => answersMapper.Sconosciuto[checkpoint.id] || 0),
                },
                {
                    name: 'Nessuno',
                    color: '#ea4236',
                    data: checkpoints.map((checkpoint) => answersMapper.Nessuno[checkpoint.id] || 0),
                },
                {
                    name: 'Nessuna Risposta',
                    color: '#4285f4',
                    data: checkpoints.map((checkpoint) => answersMapper.empty[checkpoint.id] || 0),
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default Istruzione;
