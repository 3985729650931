import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IFundsKindProps {
    submissions: any[];
}

const FundsKind = ({submissions}: IFundsKindProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data: {name: string, y: number}[] = [];

        submissions.forEach((submission: any) => {
            for (let i = 0; i < 9; i++) {
                const key = `question9_${i}`;
                const value = submission[key];

                if (value === null) continue;

                const index = data.findIndex((item: any) => item.name === value);

                if (index === -1) {
                    data.push({name: value, y: 1});
                } else {
                    data[index].y += 1;
                }
            }
        });

        data = data.sort((a: any, b: any) => (b.name > a.name ? -1 : 1));

        setOptions({
            chart: {
                type: 'bar',
            },
            title: {
                text: 'Kind of Funds obtained before the acceleration program',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: [
                    'Family & friends',
                    'Business Angels',
                    'Crowdfunding',
                    'Incubators and Accelerators',
                    'NGO’s grants',
                    'Public subsidies (e.g. tax credits)',
                    'Venture capital',
                    'Microfinance',
                    'Financial institutions (banks)',
                    'Other',
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                    },
                },
            ],
        });
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default FundsKind;
