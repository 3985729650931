import * as React from 'react';
import {useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import {visuallyHidden} from '@mui/utils';
import {Box, TableSortLabel} from '@mui/material';

import './styles.scss';

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(243 244 246)',
        color: theme.palette.common.black,
        borderBottom: '1px solid',
        borderColor: 'rgb(156 163 175)',
        whiteSpace: 'nowrap',
        fontWeight: 700,
        fontSize: 16,
    },
    [`&.${tableCellClasses.head}:not(:nth-child(2))`]: {
        borderLeft: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        borderColor: 'rgb(156 163 175)'
    },
    [`&.${tableCellClasses.body}:not(:nth-child(2))`]: {
        borderLeft: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&': {
        backgroundColor: '#fff',
        borderBottom: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
}));

const StyledTable = styled(Table)(() => ({
    '&': {
        border: '1px solid',
        borderLeft: '0',
        borderColor: 'rgb(156 163 175)'
    },
}));

const StickyTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(243 244 246)',
        color: theme.palette.common.black,
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 5,
        borderBottom: '1px solid',
        borderRight: '1px solid',
        borderLeft: '1px solid',
        borderColor: 'rgb(156 163 175)',
        fontWeight: 700,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: '#fff',
        left: 0,
        position: 'sticky',
        zIndex: theme.zIndex.appBar + 2,
        fontSize: 16,
        borderRight: '1px solid',
        borderLeft: '1px solid',
        borderColor: 'rgb(156 163 175)'
    },
}));

interface ITableProps {
    columns: string[];
    rows: any[];
    numberRows: number[];
}

type Order = 'asc' | 'desc';

export default function CustomTable({rows, columns, numberRows}: ITableProps) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<number>(0);

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }

        if (b[orderBy] > a[orderBy]) {
            return 1;
        }

        return 0;
    }

    function getComparator(order: Order, orderBy: number): (a: any, b: any) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array: any[], comparator: (a: string, b: number) => number) {
        const stabilizedThis = array.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);

            if (order !== 0) {
                return order;
            }

            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const createSortHandler = (property: number) => (_: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';

        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Paper className='table-container'>
            <TableContainer sx={{maxHeight: 600}}>
                <StyledTable stickyHeader aria-label='customized table sticky'>
                    <TableHead>
                        <TableRow>
                            <StickyTableCell className={tableCellClasses.head}>
                                <TableSortLabel
                                    active={0 === orderBy}
                                    direction={orderBy === 0 ? order : 'asc'}
                                    onClick={createSortHandler(0)}
                                >
                                    {columns[0]}
                                    {
                                        orderBy === 0 ? (
                                            <Box component='span' sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null
                                    }
                                </TableSortLabel>
                            </StickyTableCell>
                            {
                                columns.slice(1).map((column, colIndex) => (
                                    <StyledTableCell
                                        key={colIndex}
                                        sortDirection={orderBy === (colIndex + 1) ? order : false}
                                    >
                                        <TableSortLabel
                                            active={(colIndex + 1) === orderBy}
                                            direction={orderBy === (colIndex + 1) ? order : 'asc'}
                                            onClick={createSortHandler((colIndex + 1))}
                                        >
                                            {column}
                                            {
                                                orderBy === (colIndex + 1) ? (
                                                    <Box component='span' sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null
                                            }
                                        </TableSortLabel>
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            visibleRows.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StickyTableCell className={tableCellClasses.body}>
                                        <div
                                            title={row[0]}
                                            className={'text-container ' + (row.length > 4 ? 'w-200' : '')}
                                        >
                                            {row[0]}
                                        </div>
                                    </StickyTableCell>
                                    {
                                        row.slice(1).map((rowColumn: any, rowIndex: number) => (
                                            <StyledTableCell key={rowIndex}>
                                                <div
                                                    title={rowColumn}
                                                    className={
                                                        'text-container '
                                                        + (numberRows.includes(rowIndex) ? 'text-right ' : '')
                                                        + (row.length > 4 ? 'w-200' : '')
                                                    }
                                                >
                                                    {rowColumn}
                                                </div>
                                            </StyledTableCell>
                                        ))
                                    }
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </StyledTable>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}