import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Impatto, {IImpattoData} from '../Impatto';
import {getEntries, groupBy} from '../../../utils/groupBy';

interface IDataProps {
    baseData: any[],
    newData: any[],
    uoa: string | number,
}

const StateBeneficiariesTakenCare = ({baseData, newData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: IImpattoData; uoa: IImpattoData; }>({
        average: {
            same: 0,
            worse: 0,
            better: 0
        },
        uoa: {
            same: 0,
            worse: 0,
            better: 0
        }
    });

    const calcData = () => {
        let average = {
            same: 0,
            worse: 0,
            better: 0
        };
        let current = {
            same: 0,
            worse: 0,
            better: 0
        };

        for (const [parent, newSubmissions] of getEntries(groupBy(newData, 'uoa_parent_id'))) {
            const calc = {
                same: 0,
                worse: 0,
                better: 0
            };
            let newSubmissionsCount = 0;
            let baseSubmissionsCount = 0;
            const baseSubmissions = baseData.filter((submission) => submission.uoa_parent_id === Number(parent));

            newSubmissions.forEach((newSubmission: any) => {
                for (const [question, answer] of Object.entries(newSubmission)) {
                    if (question.startsWith('10_') && answer) {
                        newSubmissionsCount++;
                    }
                }
            })

            baseSubmissions.forEach((baseSubmission) => {
                for (const [question, answer] of Object.entries(baseSubmission)) {
                    if (question.startsWith('19') && answer) {
                        baseSubmissionsCount++;
                    }
                }
            })

            const variation = (newSubmissionsCount - baseSubmissionsCount) / newData.length;

            if (variation > 0) {
                calc.worse++;
            } else if (variation < 0) {
                calc.better++;
            } else {
                calc.same++;
            }

            average = {
                same: +calc.same,
                worse: +calc.worse,
                better: +calc.better
            };

            if (Number(parent) === Number(uoa)) {
                current = calc;
            }
        }

        setData({
            average,
            uoa: current
        });
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>Andamento dello stato di benessere dei beneficiari presi in carico</div>
                <Tooltip
                    title={<>
                        Il grafico mostra l’andamento dei beneficiari presi in carico dal vostro
                        progetto a confronto con la media di tutti i progetti. In particolare,
                        si osserva la distribuzione dei beneficiari presi incarico (per i quali
                        è stata completata almeno una scheda di aggiornamento) fra:<br/>
                        1) beneficiari che hanno mostrato segnali di peggioramento del livello
                        di benessere (cioè che nella scheda di aggiornamento più recente
                        presentano un numero di segnali di malessere superiore a quello
                        registrato nella rispettiva scheda iniziale); <br/>
                        2) beneficiari che hanno mostrano segnali di stabilità del livello di
                        benessere (cioè che nella scheda di aggiornamento più recente presentano
                        un numero di segnali di malessere uguale a quello registrato nella
                        rispettiva scheda iniziale);<br/>
                        3) beneficiari che hanno mostrano segnali di miglioramento del livello
                        di benessere (cioè che nella scheda di aggiornamento più recente
                        presentano un numero di segnali di malessere inferiore a quello
                        registrato nella rispettiva scheda iniziale).
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Impatto
                    data={data}
                    categories={[
                        {
                            name: 'Beneficiari con miglioramento',
                            key: 'better'
                        },
                        {
                            name: 'Beneficiari stabili',
                            key: 'same'
                        },
                        {
                            name: 'Beneficiari con peggioramento',
                            key: 'worse'
                        }
                    ]}
                />
            }
        </div>
    );
};

export default StateBeneficiariesTakenCare;
