import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS, EXPORTING} from '../../../utils/constants';

export interface IImpattoData {
    same: number;
    worse: number;
    better: number;
}

interface IImpattoProps {
    data: {
        average: IImpattoData;
        uoa: IImpattoData;
    };
    categories: {
        name: string,
        key: string,
    }[]
}

const Impatto = ({data, categories}: IImpattoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const seriesName = (key: string) => {
        const category = categories.find((category) => category.key === key);

        if (category) {
            return category.name;
        }

        return '';
    };

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: ['Il mio progetto', 'Media dei progetti']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}'
                },
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: false,
                },
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'percent',
                }
            },
            series: [
                {
                    name: seriesName('better'),
                    color: COLORS.blue,
                    data: [
                        Number((data.uoa.better * 100).toFixed(2)),
                        Number((data.average.better * 100).toFixed(2))
                    ]
                },
                {
                    name: seriesName('same'),
                    color: COLORS.yellow,
                    data: [
                        Number((data.uoa.same * 100).toFixed(2)),
                        Number((data.average.same * 100).toFixed(2))
                    ]
                },
                {
                    name: seriesName('worse'),
                    color: COLORS.red,
                    data: [
                        Number((data.uoa.worse * 100).toFixed(2)),
                        Number((data.average.worse * 100).toFixed(2))
                    ]
                }
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Impatto;
