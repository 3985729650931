import * as React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const CATEGORIES = [
    {
        name: 'Never',
        baseAnswer: 'NEVER_T0',
        finalAnswer: 'NEVER_T1',
    },
    {
        name: 'Sometimes',
        baseAnswer: 'SOMETIMES_T0',
        finalAnswer: 'SOMETIMES_T1',
    },
    {
        name: 'Yearly',
        baseAnswer: 'YEARLY_T0',
        finalAnswer: 'YEARLY_T1',
    },
    {
        name: 'Twice a year',
        baseAnswer: 'TWICE A YEAR_T0',
        finalAnswer: 'TWICE A YEAR_T1',
    },
    {
        name: 'Quarterly',
        baseAnswer: 'QUARTERLY_T0',
        finalAnswer: 'QUARTERLY_T1',
    },
]

const BookKeeping = ({final, baseline}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const baselineData = CATEGORIES.map(() => 0)
    const finalData = CATEGORIES.map(() => 0)

    baseline.forEach((submission: any) => {
        const index = CATEGORIES.findIndex((category) => category.baseAnswer === submission.bookkeeping_freq_t0)

        if (index !== -1) {
            baselineData[index] = baselineData[index] + 1
        }
    })

    final.forEach((submission: any) => {
        const index = CATEGORIES.findIndex((category) => category.finalAnswer === submission.bookkeeping_freq_t1)

        if (index !== -1) {
            finalData[index] = finalData[index] + 1
        }
    })

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            title: {
                text: 'Level of improvement of book keeping (frequency)\n',
                align: 'left'
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: CATEGORIES.map((category) => category.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                line: {
                    marker: {
                        enabled: false
                    }
                }
            },
            legend: {
                verticalAlign: 'top',
                x: 0,
                y: 0
            },
            series: [
                {
                    name: 'BASELINE',
                    data: baselineData
                },
                {
                    name: 'IMPACT',
                    data: finalData
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent className="space-y-4">
                <div className="bold">
                    <Typography variant="h5" sx={{fontWeight: 600}}>
                        Entrepreneurs are able to manage a new business/existing business
                    </Typography>
                </div>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default BookKeeping;
