import {IUoaValue} from './models';
import makeAverage from '../../../utils/makeAverage';

const makeAverageFromAverage = (line: IUoaValue[][], selectedUoa: any, uoas: { id: number, name: string }[]) => {
    const filteredUoasArr = selectedUoa === 'All' ? uoas.map(uoa => uoa.id) : [selectedUoa];

    return line.map(lineItem => makeAverage(
        lineItem
            .filter(item => filteredUoasArr.includes(item.uoa))
            .map(item => item.value))
    );
};

export default makeAverageFromAverage;
