import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface ILoaderProps {
  size?: number;
  staticColor?: string;
}

const Loader = ({ size = 25, staticColor = '', ...props }: ILoaderProps) => (
  <Box sx={{ position: 'relative', margin: 'auto' }}>
    <CircularProgress
      variant="determinate"
      size={size}
      thickness={4}
      {...props}
      value={100}
    />
    <CircularProgress
      variant="indeterminate"
      disableShrink
      sx={{
        color: 'white',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      }}
      size={size}
      thickness={4}
      {...props}
    />
  </Box>
);

export default Loader;
