import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IStartedSellProps {
    baseline: any[];
    final: any[];
}

const valid: any[] = ['BIZ_IDEA_EM_T0', 'BIZ_IDEA_UNEM_T0'];

const StartedSell = ({baseline, final}: IStartedSellProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const startedCount = final.filter(
            (submission: any) => submission.completed && submission.idea_product_sold_t1 === 'Yes'
        ).length;

        const title = '% of entrepreneurs who started to sell products in the market';

        if (startedCount === 0) {
            const dashboardOptions: any = getDefaultPieFormatting(title, []);
            setOptions(dashboardOptions);
            return;
        }

        const baselineValidCount = baseline.filter(
            (submission) => submission.completed && valid.includes(submission.career_status_t0)
        ).length;

        const finalValidCount = final.filter(
            (submission) => submission.completed && valid.includes(submission.question8)
        ).length;

        const validCount = baselineValidCount + finalValidCount;

        const data = [
            {
                name: 'Yes',
                y: startedCount,
            },
            {
                name: 'No',
                y: validCount - startedCount,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(title, data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default StartedSell;
