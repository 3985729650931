import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {ISubmissionsData} from '../../utils/data';
import CustomTable from '../../../../shared/Table';
import {consuntivoCheckpoint} from '../../index';

const InitiativesSummary = ({iniziativaSubmissions}: ISubmissionsData) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);

    const columns: any[] = [
        'Iniziativa',
        'Progetto',
        'Tipologia intervento',
        'Provincia',
        'Comune',
        'Biblioteca',
        'Scuola dell\'infanzia',
        'Scuola primaria',
        'Scuola secondaria di primo grado',
        'Scuola secondaria di secondo grado',
        'Università',
        'Libreria',
        'Teatro',
        'Museo',
        'Parco',
        'Piazza',
        'Mezzi di trasporto',
        'Mercato/Supermercato',
        'Web o altri spazi virtuali',
        'Infanti (0-3 anni)',
        'Bambini (4-12)',
        'Adolescenti (13-18)',
        'Studenti universitari',
        'Genitori',
        'Altri adulti',
        'Anziani (>=65)',
        'Insegnanti',
        'Professionisti',
        'Altra categoria',
        'Categoria beneficiari coinvolti'
    ];

    const calculateSubmissions = () => {
        const rows = Object.values(iniziativaSubmissions.filter((submission) => submission.checkpoint_id === consuntivoCheckpoint))
            .map((item: any) => {
                return [
                    ...[
                        item.uoa_name,
                        item.uoa_parent_title,
                        item.proj_type,
                        item.proj_province,
                        item.proj_city,
                    ],
                    ...Array.from(Array(14)).map((_, index) => item[`proj_place_types_${index}`] || '-'),
                    ...[
                        item.proj_beneficiaries_infanti_0_3_anni_numero,
                        item.proj_beneficiaries_bambini_4_12_numero,
                        item.proj_beneficiaries_adolescenti_13_18_numero,
                        item.proj_beneficiaries_studenti_universitari_numero,
                        item.proj_beneficiaries_genitori_numero,
                        item.proj_beneficiaries_altri_adulti_numero,
                        item.proj_beneficiaries_anziani_65_numero,
                        item.proj_beneficiaries_insegnanti_numero,
                        item.proj_beneficiaries_professionisti_numero,
                        item.proj_beneficiaries_altra_categoria_numero,
                        item.proj_beneficiaries_other_type,
                    ]
                ]
            })

        setRows(rows)
        setLoading(true)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                <div className='py-16'>
                    <Typography variant='h3' sx={{fontSize: 18, fontWeight: 500}}>
                        Dettagli del progetto
                    </Typography>
                    <div className='mt-12'>
                        <Typography variant='h4' sx={{fontSize: 18, fontWeight: 500}}>
                            Consuntivi delle iniziative
                        </Typography>
                    </div>
                </div>
                {
                    loading && (
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            numberRows={[]}
                        />
                    )
                }
            </CardContent>
        </Card>
    )
}

export default InitiativesSummary;
