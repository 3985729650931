import * as React from "react";
import DataImage from "../../DataImage";
import {IFoodBanksProps} from "../../utils/models";
import makeSum from "../../../../utils/makeSum";

const Vehicles = ({ submissions }: IFoodBanksProps) => {
    return (
        <DataImage
            image="https://www.eurofoodbank.org/wp-content/uploads/2023/03/Total_number_of_vehicles.png"
            value={makeSum(submissions, 'total_number_of_vehicles')}
            text="Total number of vehicles"
        />
    );
}

export default Vehicles;