import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import makeAverage from "../../../../utils/makeAverage";

interface IBusinessProfileProps {
    baseline: any[],
    final: any[],
}

const BusinessModelling = ({baseline, final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
              const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Level of improvement in business modeling skills',
                align: 'left'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: [
                    'Business modelling',
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            legend: {
                verticalAlign: 'top',
                x: 0,
                y: 0
            },
            series: [
                {
                    name: "T0",
                    data: [makeAverage(baseline.map((submission: any) => Number(submission.skills_t0_bizmodel_skills_t0)))]
                }, {
                    name: "T1",
                    data: [makeAverage(final.map((submission: any) => Number(submission.skills_t1_bizmodel_skills_t1)))]
                }
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default BusinessModelling;
