import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Column from '../Column';

interface IDataProps {
    plannedData: any[],
    updatingData: any[],
    uoa: string | number,
}

const PathStartRate = ({plannedData, updatingData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: number; uoa: number; }>({
        average: 0,
        uoa: 0,
    });

    const calcData = () => {
        const average = {
            planned: 0,
            updating: 0,
        };
        const current = {
            planned: 0,
            updating: 0,
        }

        plannedData.forEach((plannedSubmission: any) => {
            const value = plannedSubmission.question8_in_possesso_dei_requisiti_richiesti_dal_bando_che_prevedete_aderiscano_al_progetto_firmando_il_documento_di_adesione_numero || 0;

            average.planned = average.planned + value;

            if (plannedSubmission.uoa_parent_id === Number(uoa)) {
                current.planned = average.planned + value;
            }
        })

        updatingData.forEach((updatingSubmission: any) => {
            const value = updatingSubmission.question10_che_avete_coinvolto_in_una_o_piu_delle_attivita_previste_ai_fini_dellattivazione_numero || 0;

            average.updating = average.updating + value;

            if (updatingSubmission.uoa_parent_id === Number(uoa)) {
                current.updating = average.updating + value;
            }
        })

        setData({
            average: average.updating / average.planned,
            uoa: current.updating / current.planned,
        })
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>TASSO DI AVVIO DEI PERCORSI</div>
                <Tooltip
                    title={<>
                        Il grafico mostra il tasso di avvio dei percorsi previsti ai fini dell’attivazione dei giovani
                        NEET per il vostro progetto a confronto con la media dello stesso valore in tutti i progetti. Il
                        valore è ottenuto dal rapporto tra i giovani coinvolti in una o più delle attività previste ai
                        fini dell'attivazione, secondo la più recente scheda di aggiornamento attività di
                        intercettazione e i giovani in possesso dei requisiti richiesti dal bando che hanno aderito al
                        progetto, firmando il documento di adesione, secondo la stessa scheda.
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Column
                    data={data}
                />
            }
        </div>
    );
};

export default PathStartRate;
