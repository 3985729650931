import * as React from 'react';
import NoResults from '../../shared/NoResults';
import Loader from '../../shared/Loader';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import apiCall, {setUserToken} from '../../utils/api';
import Table from './Table';

const FebaOrganization = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const foodBank = searchParams.get('food_bank');
    const foodIn = searchParams.get('food_in');
    const nonFoodIn = searchParams.get('non_food_in');
    const financials = searchParams.get('financials');
    const human = searchParams.get('human');
    const infrastructure = searchParams.get('infrastructure');
    const energy = searchParams.get('energy');
    const partners = searchParams.get('partners');
    const dataCollection = searchParams.get('checkpoint_id');
    let financialsCheckpoint = searchParams.get('financials_checkpoint');
    const hashKey = searchParams.get('key');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_FEBA || '');

    setUserToken(searchParams.get('token') || '')

    const [loading, setLoading] = useState(true);
    const [submissions, setSubmissions] = useState([]);
    const [foodInSubmissions, setFoodInSubmissions] = useState([]);
    const [nonFoodInSubmissions, setNonFoodInInSubmissions] = useState([]);
    const [financialsSubmissions, setFinancialsSubmissions] = useState([]);
    const [humanSubmissions, setHumanSubmissions] = useState([]);
    const [infrastructureSubmissions, setInfrastructureSubmissions] = useState([]);
    const [energySubmissions, setEnergySubmissions] = useState([]);
    const [partnersSubmissions, setPartnersSubmissions] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [meta, setMeta] = useState<{
        current_page: number,
        total: number,
        per_page: number,
    }>({
        current_page: 1,
        total: 0,
        per_page: 10,
    });

    const getFinancialsSubmissions = async (checkpointId: number | string, uoaIds: any[]) => {
        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    survey_id: financials,
                    checkpoint_id: checkpointId,
                    additional_data: 'false',
                    uoa_ids: uoaIds,
                }
            }),
        ];

        const responses = await Promise.all(requests);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
            const responseSubmissions = responses[0]?.data?.submissions;
            setFinancialsSubmissions(responseSubmissions);
        }
    }

    const getSubmission = async (rowsPerPage: number, page: number): Promise<any[]> => {
        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    survey_id: foodBank,
                    checkpoint_id: dataCollection,
                    pagination: 'true',
                    additional_data: 'false',
                    per_page: rowsPerPage,
                    page: page,
                }
            }),
        ];

        return await Promise.all(requests);
    }

    const getSurvey = async () => {
        setLoading(true);

        try {
            if (foodBank && dataCollection && hashKey === hashKeyLocal) {
                const responses = await getSubmission(rowsPerPage, page);

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    const data = responses[0].data.submissions
                    setSubmissions(data);
                    setMeta(responses[0].data.meta);

                    await getRelatedSurvey(data.map((submission: any) => submission.uoa_id))
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getRelatedSurvey = async (ids: any[]) => {
        try {
            const uoaIds = [].concat(...ids)

            if (foodIn && nonFoodIn && financials && human && infrastructure && energy && partners && ids.length > 0) {
                const submissionsMap = {
                    [foodIn]: setFoodInSubmissions,
                    [nonFoodIn]: setNonFoodInInSubmissions,
                    [human]: setHumanSubmissions,
                    [infrastructure]: setInfrastructureSubmissions,
                    [energy]: setEnergySubmissions,
                    [partners]: setPartnersSubmissions,
                }

                const requests = Object.keys(submissionsMap).map((survey_id) => (
                    apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id,
                            checkpoint_id: dataCollection,
                            additional_data: 'false',
                            uoa_ids: uoaIds,
                        }
                    })
                ));

                const responses = await Promise.all(requests);

                responses.forEach((response) => {
                    const responseSubmissions = response?.data?.submissions;

                    if (submissionsMap.hasOwnProperty(response.config.params?.survey_id)) {
                        submissionsMap[response.config.params?.survey_id](responseSubmissions)
                    }
                });

                if (!financialsCheckpoint) {
                    financialsCheckpoint = dataCollection;
                }

                if (financialsCheckpoint) {
                    await getFinancialsSubmissions(financialsCheckpoint, uoaIds);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const getAllSurveys = async () => {
        let submissions: any[] = [];

        if (foodBank && dataCollection && hashKey === hashKeyLocal) {
            let page = 1
            let meta = {
                last_page: 1,
            }

            do {
                const responses = await getSubmission(50, page);
                page++;

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    submissions = [...submissions, ...responses[0].data.submissions];
                    meta = responses[0].data.meta;
                }

                if (page > meta.last_page) {
                    break;
                }
            } while (true)

            if (submissions.length > 0) {
                await getRelatedSurvey(submissions.map((submission: { uoa_id: any }) => submission.uoa_id));
            }
        }

        return submissions;
    };

    useEffect(() => {
        getSurvey().then(() => setLoading(false));
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    return (
        <div className='container space-y-8'>
            {
                !loading && (
                    <>
                        <div>
                            {
                                submissions.length > 0 && (
                                    <Table
                                        submissions={submissions}
                                        foodInSubmissions={foodInSubmissions}
                                        nonFoodInSubmissions={nonFoodInSubmissions}
                                        financialsSubmissions={financialsSubmissions}
                                        humanSubmissions={humanSubmissions}
                                        infrastructureSubmissions={infrastructureSubmissions}
                                        energySubmissions={energySubmissions}
                                        partnersSubmissions={partnersSubmissions}
                                        meta={meta}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        getAllSurveys={getAllSurveys}
                                    />
                                )
                            }
                            {
                                submissions.length === 0 && (
                                    <NoResults/>
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className='loader-container'>
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
}

export default FebaOrganization;