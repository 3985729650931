import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import strNormalize from "../../../utils/strNormalize";
import strSlug from "../../../utils/strSlug";

interface ITassoProps {
    data: {}[],
    name: string,
    seriesName?: string,
    slugKey?: string,
    elements: {
        name: string,
        rows: [],
    }[]
}

const RowsBar = ({data, name, elements, seriesName = "% per category", slugKey = ''}: ITassoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    let categories: any[] = [];
    const series: any[] = [];
    const question = elements.find((element: { name: string }) => element.name === name)

    if (question) {
        categories = question.rows
    }

    if (name.slice(-1) === "_") {
        name = name.slice(0, -1);
    }

    categories.forEach((category) => {
        const categoryName = (`${strNormalize(name)}_${strSlug(category, '_')}${slugKey}`).toLowerCase()

        series.push(
            data
                .map((item: any) => {
                    return Number(item[categoryName] ?? 0)
                }).reduce((partialSum, a) => partialSum + a, 0)
        )
    })

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: '',
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: categories.map((category) => typeof category === 'object' ? category.text : category),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: seriesName,
                data: series
            }]
        };

        setOptions(dashboardOptions)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default RowsBar;
