import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {IPavesiProjectProps} from '../../utils/models';
import {COLORS} from "../../../../utils/constants";

const Progetti = ({data, selectedProject}: IPavesiProjectProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let projects = data;

        if (selectedProject !== 'All') {
            projects = data.filter(project => project.id === selectedProject)
        }

        const dashboardOptions: any = {
            chart: {
                height: 300,
                type: 'pie',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Attività partecipate',
                data: projects.map((project, index) => ({
                    name: project.name,
                    y: project.total,
                    color: Object.values(COLORS)[index]
                })),
                size: '50%',
                innerSize: '50%'
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [data, selectedProject]);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Progetti;
