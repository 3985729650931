import * as React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import EnrolledItem from "./EnrolledItem";

interface IBusinessProfileProps {
    baseline: any[],
    final: any[],
}

const JobsCreated = ({baseline, final}: IBusinessProfileProps) => {
    const baseData: any = {
        employees: 0,
        permanentContracts: 0,
        managerialPositions: 0,
        under30: 0,
        women: 0,
    }
    const finalData: any = {
        employees: 0,
        permanentContracts: 0,
        managerialPositions: 0,
        under30: 0,
        women: 0,
    }
    const result: any = {
        employees: '0',
        permanentContracts: '0',
        managerialPositions: '0',
        under30: '0',
        women: '0',
    }
    const total = final.length + baseline.length

    baseline.forEach((submission: any) => {
        baseData.employees += (submission.jobs_t0_men_t0_number + submission.jobs_t0_women_t0_number)
        baseData.permanentContracts += Number(submission.type_contract_t0_permanent_contracts)
        baseData.managerialPositions += Number(submission.type_contract_t0_managerial_positions)
        baseData.under30 += Number(submission.type_jobs_t0_under_30)
        baseData.women += Number(submission.jobs_t0_women_t0_number)
    })

    final.forEach((submission: any) => {
        finalData.employees += (submission.jobs_t1_men_t1_number + submission.jobs_t1_women_t1_number)
        finalData.permanentContracts += Number(submission.type_contract_t1_per_contract_t1)
        finalData.managerialPositions += Number(submission.type_contract_t1_mgr_contract_t1)
        finalData.under30 += Number(submission.type_jobs_t1_under_30)
        finalData.women += Number(submission.jobs_t1_women_t1_number)
    })

    const callAvg = (key: string) => {
        return (Math.max((finalData[key] - baseData[key]), 0) / total).toFixed()
    }

    if (total > 0) {
        Object.keys(result).forEach((key) => {
            result[key] = callAvg(key)
        })
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="row">
                    <div>
                        <div className="enrolled-item space-x-4">
                            <EnrolledItem value={result.employees} text={'Average number of decent jobs created'}/>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <Typography>
                                Of which
                            </Typography>
                        </div>
                        <div className="enrolled-item space-x-4">
                            <EnrolledItem value={result.permanentContracts} text={'Permanent jobs (avg)'}/>
                        </div>
                        <div className="enrolled-item space-x-4">
                            <EnrolledItem value={result.managerialPositions} text={'Managerial contracts (avg)'}/>
                        </div>
                        <div className="enrolled-item space-x-4">
                            <EnrolledItem value={result.under30} text={'YOUTH UNDER 30 (avg)'}/>
                        </div>
                        <div className="enrolled-item space-x-4">
                            <EnrolledItem value={result.women} text={'WOMEN (avg)'}/>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default JobsCreated;
