const mapAnswers = (submissions: any[], answersMapper: any, question: string) => {
  submissions.forEach((submission) => {
    if (answersMapper[submission[question]]) {
      if (answersMapper[submission[question]][submission.checkpoint_id]) {
        answersMapper[submission[question]][submission.checkpoint_id]++
      } else {
        answersMapper[submission[question]][submission.checkpoint_id] = 1;
      }
    } else {
      answersMapper.empty[submission.checkpoint_id] = answersMapper.empty[submission.checkpoint_id]
        ? answersMapper.empty[submission.checkpoint_id] + 1
        : 1
    }
  });
};

export default mapAnswers;
