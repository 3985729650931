import React from 'react';
import ActualParticipants from './ActualParticipants';
import ParticipationRate from './ParticipationRate';
import NotReaders from './NotReaders';
import WeakReaders from './WeakReaders';
import ResponseRate from './ResponseRate';
import {ISubmissionsData} from '../utils/data';

const Project = ({iniziativaSubmissions, beneficiarySubmissions}: ISubmissionsData) => {
    return (
        <>
            <div className='w-full'>
                <div className='section-title'>
                    <div>Statistiche relative a questa iniziativa</div>
                </div>
                <div className='container-child grid-cols-3'>
                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <>
                                    <ActualParticipants
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                    <ParticipationRate
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                </>
                            )
                        }
                    </div>

                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <>
                                    <NotReaders
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                    <WeakReaders
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                </>
                            )
                        }
                    </div>

                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <ResponseRate
                                    iniziativaSubmissions={iniziativaSubmissions}
                                    beneficiarySubmissions={beneficiarySubmissions}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Project;
