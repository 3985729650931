import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {COLORS, EXPORTING} from '../../../utils/constants';
import makeRatio from "../../../utils/makeRatio";

interface IAutonomyData {
    submissions: any[];
    uoa: string | number
}

interface ISubmissionData {
    independent: number;
    autonomous: number;
}

const Autonomy = ({submissions, uoa}: IAutonomyData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const allSubmissions: ISubmissionData = {
            independent: 0,
            autonomous: 0,
        }
        const currentSubmissions: ISubmissionData = {
            independent: 0,
            autonomous: 0,
        }

        submissions.forEach((submission: any) => {
            allSubmissions['independent'] += submission.question24_persone_anziane_poco_autonomenon_autonome_nelle_seguenti_attivita_movimenti_cura_e_igiene_personale_pulizia_quotidiana_della_casa_spesa_e_commissioni_correnti_numero || 0;
            allSubmissions['autonomous'] += submission.question24_persone_anziane_autonomequasi_autonome_nelle_seguenti_attivita_movimenti_cura_e_igiene_personale_pulizia_quotidiana_della_casa_spesa_e_commissioni_correnti_numero || 0;

            if (submission.uoa_id === uoa) {
                currentSubmissions['independent'] += submission.question24_persone_anziane_poco_autonomenon_autonome_nelle_seguenti_attivita_movimenti_cura_e_igiene_personale_pulizia_quotidiana_della_casa_spesa_e_commissioni_correnti_numero || 0;
                currentSubmissions['autonomous'] += submission.question24_persone_anziane_autonomequasi_autonome_nelle_seguenti_attivita_movimenti_cura_e_igiene_personale_pulizia_quotidiana_della_casa_spesa_e_commissioni_correnti_numero || 0;
            }
        })

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'COMPOSIZIONE BENEFICIARI ATTESI PER LE ATTIVITA’ CHE HANNO LO STESSO OBIETTIVO - livello di autonomia',
                align: 'left'
            },
            xAxis: {
                categories: ['Il mio progetto', 'Media attività con stessa finalità in tutti i progetti']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: false,
                },
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'percent',
                }
            },
            series: [
                {
                    name: '% di beneficiari anziani poco autonomi',
                    color: COLORS.red,
                    data: [
                      makeRatio(currentSubmissions.autonomous, Object.values(currentSubmissions)),
                      makeRatio(allSubmissions.autonomous, Object.values(allSubmissions)),
                    ]
                },
                {
                    name: '% di beneficiari anziani autonomi',
                    color: COLORS.blue,
                    data: [
                      makeRatio(currentSubmissions.independent, Object.values(currentSubmissions)),
                      makeRatio(allSubmissions.independent, Object.values(allSubmissions)),
                    ]
                }
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Autonomy;
