import {Info} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {COLORS} from '../../../../utils/constants';

const SkillsTooltip = () => {
    return (
        <Tooltip
            title={
                <>
                    <li>% increased business modeling skills</li>
                    <li>% increased sustainability and impact measurement skills</li>
                    <li>% increased pitching skills</li>
                    <li>% increased financial-economic planning and accountability skills</li>
                </>
            }
        >
            <IconButton>
                <Info
                    htmlColor={COLORS.e4ImpactMain}
                    fontSize='small'
                />
            </IconButton>
        </Tooltip>
    );
};

export default SkillsTooltip;
