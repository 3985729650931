import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IExportProps {
    final: any[];
    baseline: any[];
}

const Export = ({final, baseline}: IExportProps) => {
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const filteredFinal = final.filter(
            (submission: any) =>
                submission.export_t1 !== null &&
                baseline.find((base: any) => submission.uoa_id === base.uoa_id)?.export_t0 === 'NO'
        );

        const finalExportCount = filteredFinal.filter((submission: any) => submission.export_t1 === 'Yes').length;

        const data = [
            {
                name: 'Entrepreneurs who did not export and started export',
                y: finalExportCount,
            },
            {
                name: 'Entrepreneurs who did not export and are not exporting',
                y: filteredFinal.length - finalExportCount,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting('% of entrepreneurs who started export', data);

        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartComponentRef}
                />
            </CardContent>
        </Card>
    );
};

export default Export;
