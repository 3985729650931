import * as React from "react";
import DataImage from "../../DataImage";
import {IFoodBanksProps} from "../../utils/models";
import makeSum from "../../../../utils/makeSum";

const FoodBanks = ({ submissions }: IFoodBanksProps) => {
    return (
        <DataImage
            image="https://www.eurofoodbank.org/wp-content/uploads/2023/03/Food_Bank_V1.png"
            value={makeSum(submissions, 'total_number_of_food_banks')}
            text="Total Number of Food Banks"
        />
    );
}

export default FoodBanks;