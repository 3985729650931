import {ReactNode} from 'react';
import {Typography} from "@mui/material";

import './styles.scss';

interface TabPanelProps {
    children?: ReactNode;
    title?: string;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, title, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {
                value === index && (
                    <>
                        {
                            title && (
                                <Typography className="content-title" variant="h5">{title}</Typography>
                            )
                        }
                        {children}
                    </>
                )
            }
        </div>
    );
}

export default TabPanel;