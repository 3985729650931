import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import {Card, CardContent} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
}

const YearImproved = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const data = Array.from({length: 6}, () => 0);

    final.forEach((submission: any) => {
        const index = submission.biz_mod_improved_t1 - 1
        data[index] = data[index] + 1
    })

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: '',
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: Array.from({length: 6}, (x, i) => i + 1),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: "Number",
                data: data
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
}

export default YearImproved