import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Column from '../Column';

interface IDataProps {
    plannedData: any[],
    updatingData: any[],
    uoa: string | number,
}

const AceOfMembership = ({plannedData, updatingData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: number; uoa: number; }>({
        average: 0,
        uoa: 0,
    });

    const calcData = () => {
        const average = {
            planned: 0,
            updating: 0,
        };
        const current = {
            planned: 0,
            updating: 0,
        }

        plannedData.forEach((plannedSubmission: any) => {
            const value = plannedSubmission.question8_dai_quali_prevedete_di_ricevere_una_manifestazione_di_interesse_verso_il_progetto_numero || 0;

            average.planned = average.planned + value;

            if (plannedSubmission.uoa_parent_id === Number(uoa)) {
                current.planned = average.planned + value;
            }
        })

        updatingData.forEach((updatingSubmission: any) => {
            const value = updatingSubmission.question10_in_possesso_dei_requisiti_richiesti_dal_bando_che_hanno_aderito_al_progetto_firmando_il_documento_di_adesione_numero || 0;

            average.updating = average.updating + value;

            if (updatingSubmission.uoa_parent_id === Number(uoa)) {
                current.updating = average.updating + value;
            }
        })

        setData({
            average: average.updating / average.planned,
            uoa: current.updating / current.planned,
        })
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>TASSO DI ADESIONE AL PERCORSO</div>
                <Tooltip
                    title={<>
                        Il grafico mostra il tasso di adesione al percorso da parte dei giovani NEET per il proprio
                        progetto a confronto con la media dello stesso valore in tutti i progetti. Il valore è ottenuto
                        dal rapporto tra i giovani in possesso dei requisiti richiesti dal bando che hanno aderito al
                        progetto (firmando il documento di adesione), secondo la più recente scheda di aggiornamento
                        attività di intercettazione e i giovani NEET dai quali è stata ricevuta una “manifestazione di
                        interesse” verso il progetto, secondo la stessa scheda.
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Column
                    data={data}
                />
            }
        </div>
    );
};

export default AceOfMembership;
