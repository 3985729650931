import * as XLSX from "xlsx";

export const download = (data: any[], fileName: string) => {
    const workSheet = XLSX.utils.aoa_to_sheet(data);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");

    const working = XLSX.write(workBook, {bookType: "xlsx", bookSST: true, type: "buffer"});
    const csvURL = window.URL.createObjectURL(
        new Blob(
            [working],
            {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
            }
        )
    );

    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `${fileName}.xlsx`);
    tempLink.click();
}