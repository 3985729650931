import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS, EXPORTING} from '../../../utils/constants';

interface ITassoProps {
    data: {
        average: number;
        uoa: number;
    };
    usePercentage: boolean
}

const Column = ({data, usePercentage}: ITassoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: ['Il mio progetto', 'Media dei progetti']
            },
            yAxis: {
                tickInterval: 1,
                title: {
                    text: ''
                }
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                }
            },
            series: [
                {
                    showInLegend: false,
                    color: COLORS.blue,
                    name: '',
                    data: [
                        usePercentage ? data.uoa * 100 : data.uoa,
                        usePercentage ? data.average * 100 : data.average
                    ]
                },
            ],
            exporting: EXPORTING
        };

        if (usePercentage) {
            dashboardOptions.yAxis = {
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                }
            }
            dashboardOptions.tooltip = {
                valueSuffix: '%'
            }
        }

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Column;
