import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {IPavesiColumnsProps} from '../../utils/models';
import mapAnswers from '../../utils/mapAnswers';

const GradoScolastico = ({submissions, checkpoints}: IPavesiColumnsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const answersMapper: any = {
            Elemento1: {},
            Elemento2: {},
            Elemento3: {},
            empty: {},
        };

        mapAnswers(submissions, answersMapper, 'question18');

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: checkpoints.map((checkpoint) => checkpoint.name),
            },
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: true,
                },
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true,
                    },
                },
            },
            series: [
                {
                    name: 'Scuola Secondaria di 2° grado',
                    color: '#ff6d01',
                    data: checkpoints.map((checkpoint) => answersMapper.Elemento3[checkpoint.id] || 0),
                },
                {
                    name: 'Scuola Secondaria di 1° grado',
                    color: '#34a853',
                    data: checkpoints.map((checkpoint) => answersMapper.Elemento2[checkpoint.id] || 0),
                },
                {
                    name: 'Scuola Primaria',
                    color: '#fcbc00',
                    data: checkpoints.map((checkpoint) => answersMapper.Elemento1[checkpoint.id] || 0),
                },
                {
                    name: 'Nessuna Risposta',
                    color: '#4285f4',
                    data: checkpoints.map((checkpoint) => answersMapper.empty[checkpoint.id] || 0),
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default GradoScolastico;
