import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IFundsKindFinalProps {
    submissions: any[];
}

const transform = [
    {text: 'Family & friends', value: 'item1'},
    {text: 'Business Angels', value: 'item2'},
    {text: 'Crowdfunding', value: 'item3'},
    {text: 'Incubators and Accelerators', value: 'item4'},
    {text: 'NGO\u2019s loans', value: 'item10'},
    {text: 'NGO’s grants', value: 'item5'},
    {text: 'Public subsidies (e.g. tax credits)', value: 'item6'},
    {text: 'Venture capital', value: 'item7'},
    {text: 'Microfinance', value: 'item8'},
    {text: 'Financial institutions (banks)', value: 'item9'},
    {text: 'Other', value: 'other'},
]

const FundsKindFinal = ({submissions}: IFundsKindFinalProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data: { name: string, y: number }[] = [];

        Object.values(transform).forEach((item) => {
            data.push({name: item.value, y: 0});
        });

        submissions.forEach((submission: any) => {
            for (let i = 0; i < 10; i++) {
                const key = `question9_${i}`;
                const value = submission[key];

                if (value === null) continue;

                const index = data.findIndex((item: any) => item.name === value);

                if (index !== -1) {
                    data[index].y += 1;
                }
            }
        });

        setOptions({
            chart: {
                type: 'bar',
            },
            title: {
                text: 'Kind of Funds',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: Object.values(transform).map(({text}) => text),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                    },
                },
            ],
        });
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default FundsKindFinal;
