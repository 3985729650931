import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {COLORS, EXPORTING} from '../../../utils/constants';
import makeRatio from "../../../utils/makeRatio";

interface ICompositionData {
    submissions: any[];
    uoa: string | number
}

interface ISubmissionData {
    awareness: number;
    members: number;
    sociability: number;
    information: number;
    home: number;
}

interface IBeneficiaryData {
    all: number;
    current: number;
}

const AGE_GROUP_KEYS = [
    'question13_persone_anziane_di_eta_65_75_numero',
    'question13_persone_anziane_di_eta_75_85_numero',
    'question13_persone_anziane_over_85_numero',
]

const OTHER_KEYS = [
    'question25_altri_caregivers_ad_es_badanti_numero',
    'question25_famigliari_inserire_solo_un_famigliare_per_anziano_numero',
    'question25_operatori_numero',
    'question25_volontari_numero',
]

const Composition = ({submissions, uoa}: ICompositionData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const allSubmissions: ISubmissionData = {
            awareness: 0,
            members: 0,
            sociability: 0,
            information: 0,
            home: 0,
        }
        const currentSubmissions: ISubmissionData = {
            awareness: 0,
            members: 0,
            sociability: 0,
            information: 0,
            home: 0,
        }
        const ageGroup: IBeneficiaryData = {
            current: 0,
            all: 0,
        }
        const other: IBeneficiaryData = {
            current: 0,
            all: 0,
        }

        submissions.forEach((submission: any) => {
            const ageGroupAnswer = AGE_GROUP_KEYS.map(key => submission[key] || 0)
                .reduce((a, b) => (a + b), 0)
            const otherAnswer = OTHER_KEYS.map(key => submission[key] || 0)
                .reduce((a, b) => (a + b), 0)

            let awareness = 0, members = 0, sociability = 0, information = 0, home = 0;

            if (submission.question4 === 'Offerta di servizi domiciliari') {
                home = ageGroupAnswer
            } else if (submission.question4 === 'Offerta di servizi informativi/di orientamento') {
                information = ageGroupAnswer + otherAnswer
            } else if (submission.question4 === 'Promozione della socialità/attivazione cognitiva dell’anziano (attività non svolte presso il domicilio dell’anziano, nel qual caso considerarle incluse nella categoria “Offerta di servizi domiciliari”)') {
                sociability = ageGroupAnswer
            } else if (submission.question4 === 'Formazione e supporto per famigliari/caregivers (escludere la ricomposizione dei servizi, da includere in ‘Offerta di servizi informativi/di orientamento’)') {
                members = otherAnswer
            } else if (submission.question4 === 'Sensibilizzazione e attivazione della comunità (escludere le attività di ricomposizione delle risorse, per cui è prevista apposita scheda)') {
                awareness = submission.question25_operatori_numero + submission.question25_volontari_numero
            }

            ageGroup.all += ageGroupAnswer
            other.all += otherAnswer

            allSubmissions.awareness += awareness;
            allSubmissions.members += members;
            allSubmissions.sociability += sociability;
            allSubmissions.information += information;
            allSubmissions.home += home;

            if (submission.uoa_parent_id === uoa) {
                ageGroup.current += ageGroupAnswer
                other.current += otherAnswer

                currentSubmissions.awareness += awareness;
                currentSubmissions.members += members;
                currentSubmissions.sociability += sociability;
                currentSubmissions.information += information;
                currentSubmissions.home += home;
            }
        })

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'Composizione delle attività',
                align: 'left'
            },
            xAxis: {
                categories: ['Il mio progetto', 'Media dei progetti']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: false,
                },
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'percent',
                }
            },
            series: [
                {
                    name: 'Sensibilizzazione e attivazione della comunità (escludere le attività di ricomposizione ' +
                        'delle risorse, per cui è prevista apposita scheda)',
                    color: COLORS.orange,
                    data: [
                        makeRatio(currentSubmissions.awareness, Object.values(ageGroup.current)),
                        makeRatio(allSubmissions.awareness, Object.values(ageGroup.all))
                    ]
                },
                {
                    name: 'Formazione e supporto per famigliari/caregivers (escludere la ricomposizione dei servizi, da ' +
                        'includere in ‘Offerta di servizi informativi/di orientamento’)',
                    color: COLORS.green,
                    data: [
                        makeRatio(currentSubmissions.members, Object.values(ageGroup.current)),
                        makeRatio(allSubmissions.members, Object.values(ageGroup.all))
                    ]
                },
                {
                    name: 'Promozione della socialità/attivazione cognitiva dell’anziano (attività non svolte presso il ' +
                        'domicilio dell’anziano, nel qual caso considerarle incluse nella categoria “Offerta di servizi ' +
                        'domiciliari”)',
                    color: COLORS.yellow,
                    data: [
                        makeRatio(currentSubmissions.sociability, Object.values([ageGroup.current])),
                        makeRatio(allSubmissions.sociability, Object.values([ageGroup.all]))
                    ]
                },
                {
                    name: 'Offerta di servizi informativi/di orientamento',
                    color: COLORS.red,
                    data: [
                        makeRatio(currentSubmissions.information, Object.values([ageGroup.current])),
                        makeRatio(allSubmissions.information, Object.values([ageGroup.all]))
                    ]
                },
                {
                    name: 'Offerta di servizi domiciliari',
                    color: COLORS.blue,
                    data: [
                        makeRatio(currentSubmissions.home, [ageGroup.current]),
                        makeRatio(allSubmissions.home, [ageGroup.all])
                    ]
                }
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Composition;
