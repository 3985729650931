import {Card, CardContent} from '@mui/material';
import strNormalize from "../../../utils/strNormalize";
import strSlug from "../../../utils/strSlug";
import makeAverage from "../../../utils/makeAverage";

interface ITassoProps {
    data: any[],
    name: string,
    elements: {
        name: string,
        rows: [],
    }[]
}

const Avg = ({data, name, elements}: ITassoProps) => {
    let categories: any[] = [];
    const series: any[] = [];
    const question = elements.find((element: { name: string }) => element.name === name)

    if (question) {
        categories = question.rows
    }

    categories.forEach((category) => {
        const categoryName = (`${strNormalize(name)}${strSlug(category, '_')}`).toLowerCase()

        series.push(
            data
                .map((item: any) => {
                    return Number(item[categoryName] ?? 0)
                }).reduce((partialSum, a) => partialSum + a, 0)
        )
    })

    return (
        <div className="charts_average">

            <Card sx={{width: '100%'}}>
                <CardContent>
                    <div className="competenze-educative__charts_average_value">{makeAverage(series)}%</div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Avg;
