import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import {COLORS} from '../../../../utils/constants';
import makeAverage from '../../../../utils/makeAverage';
import {ISubmissionsData} from '../../utils/data';
import {numberFormat} from '../../../../utils/numberFormat';
import {previsionaleCheckpoint} from '../../index';

const NotReaders = ({iniziativaSubmissions, beneficiarySubmissions}: ISubmissionsData) => {
    const [data, setData] = useState(0);
    const [loading, setLoading] = useState(false);

    const calcResults = () => {
        const answers: any = {};

        iniziativaSubmissions
            .filter((submission) => submission.checkpoint_id === previsionaleCheckpoint)
            .forEach((submission) => {
                let sum = 0
                const submissions = beneficiarySubmissions.filter((el) => el.uoa_id === submission.uoa_id)

                if (submissions.length > 0) {
                    if (!answers.hasOwnProperty(submission.uoa_parent_id)) {
                        answers[submission.uoa_parent_id] = [];
                    }

                    submissions.forEach((el) => {
                        sum = sum + (el.a1 === '2' ? 1 : 0)
                    })

                    answers[submission.uoa_parent_id].push((sum / submissions.length))
                }
            })

        const data = Object.values(answers).map((answer: any) => makeAverage(answer, 2) * 100)

        setData(makeAverage(data, 2))
        setLoading(true)
    }

    useEffect(() => {
        calcResults()
    }, []);

    return (
        <>
            {
                loading && (
                    <Card>
                        <CardContent className='text-center text-primary'>
                            <Typography variant='h1' sx={{fontSize: 30, fontWeight: 800}}>
                                {numberFormat(data, 0, ',', '.')}%
                            </Typography>
                            <div className='flex-center'>
                                <Typography>
                                    Non lettori
                                </Typography>
                                <Tooltip
                                    title={<>
                                        Percentuale media di non lettori per questo tipo di iniziative
                                    </>}
                                >
                                    <IconButton>
                                        <Info htmlColor={COLORS.libriMain} fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
};

export default NotReaders;
