export const numberFormat = (number: any, decimals = 0, decSep = '.', thousandsSep = ',') => {
    const sign = number < 0 ? '-' : '';
    const sNumber = Math.abs(parseInt(number = (+number || 0).toFixed(decimals))) + '';
    const len = sNumber.length;
    const tChunk = len > 3 ? len % 3 : 0;

    const chFirst = (tChunk ? sNumber.substr(0, tChunk) + thousandsSep : '');
    const chRest = sNumber.substr(tChunk)
        .replace(/(\d\d\d)(?=\d)/g, '$1' + thousandsSep);
    const ch_last = decimals
        ? decSep + (Math.abs(number) - +(sNumber)).toFixed(decimals).slice(2) :
        '';

    return sign + chFirst + chRest + ch_last;
}

