import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IImportProps {
    final: any[];
    baseline: any[];
}

const Import = ({final, baseline}: IImportProps) => {
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const filteredFinal = final.filter(
            (submission: any) =>
                submission.import_t1 !== null &&
                baseline.find((base: any) => submission.uoa_id === base.uoa_id)?.import_t0 === 'No'
        );

        const finalImportCount = filteredFinal.filter((submission: any) => submission.import_t1 === 'Yes').length;

        const data = [
            {
                name: 'Entrepreneurs who did not import and started import',
                y: finalImportCount,
            },
            {
                name: 'Entrepreneurs who did not import and are not importing',
                y: filteredFinal.length - finalImportCount,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting('% of entrepreneurs who started import', data);

        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    ref={chartComponentRef}
                />
            </CardContent>
        </Card>
    );
};

export default Import;
