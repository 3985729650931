import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IEntrepreneurialOrientationProps {
    baseline: any[],
    final: any[],
}

const baselineKeys = {
    riskTaking: [
        'entr_orient_risk_t0_entr_orient_a_t0',
        'entr_orient_risk_t0_entr_orient_b_t0',
        'entr_orient_risk_t0_entr_orient_c_t0',
    ],
    innovativeness: [
        'entr_orient_inn_t0_entr_orient_d_t0',
        'entr_orient_inn_t0_entr_orient_e_t0',
        'entr_orient_inn_t0_entr_orient_f_t0',
    ],
    proactiveness: [
        'entr_orient_pro_t0_entr_orient_g_t0',
        'entr_orient_pro_t0_entr_orient_h_t0',
        'entr_orient_pro_t0_entr_orient_i_t0',
    ],
    competitiveAggressiveness: [
        'entr_orient_comp_t0_entr_orient_l_t0',
        'entr_orient_comp_t0_entr_orient_m_t0',
        'entr_orient_comp_t0_entr_orient_n_t0',
    ],
    autonomy: [
        'entr_orient_aut_t0_entr_orient_o_t0',
        'entr_orient_aut_t0_entr_orient_p_t0',
        'entr_orient_aut_t0_entr_orient_q_t0',
        'entr_orient_aut_t0_entr_orient_r_t0',
        'entr_orient_aut_t0_entr_orient_s_t0',
        'entr_orient_aut_t0_entr_orient_t_t0',
    ],
}
const finalKeys = {
    riskTaking: [
        'entr_orient_risk_t1_entr_orient_a_t1',
        'entr_orient_risk_t1_entr_orient_b_t1',
        'entr_orient_risk_t1_entr_orient_c_t1',
    ],
    innovativeness: [
        'entr_orient_inn_t1_entr_orient_d_t1',
        'entr_orient_inn_t1_entr_orient_e_t1',
        'entr_orient_inn_t1_entr_orient_f_t1',
    ],
    proactiveness: [
        'entr_orient_proact_t1_entr_orient_g_t1',
        'entr_orient_proact_t1_entr_orient_h_t1',
        'entr_orient_proact_t1_entr_orient_i_t1',
    ],
    competitiveAggressiveness: [
        'entr_orient_compet_t1_entr_orient_l_t1',
        'entr_orient_compet_t1_entr_orient_m_t1',
        'entr_orient_compet_t1_entr_orient_n_t1',
    ],
    autonomy: [
        'entr_orient_aut_t1_entr_orient_o_t1',
        'entr_orient_aut_t1_entr_orient_p_t1',
        'entr_orient_aut_t1_entr_orient_q_t1',
        'entr_orient_aut_t1_entr_orient_r_t1',
        'entr_orient_aut_t1_entr_orient_s_t1',
        'entr_orient_aut_t1_entr_orient_t_t1',
    ],
}

const EntrepreneurialOrientation = ({baseline, final}: IEntrepreneurialOrientationProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const baselineData = {
        riskTaking: 0,
        innovativeness: 0,
        proactiveness: 0,
        competitiveAggressiveness: 0,
        autonomy: 0,
    };

    const finalData = {
        riskTaking: 0,
        innovativeness: 0,
        proactiveness: 0,
        competitiveAggressiveness: 0,
        autonomy: 0,
    };

    const calcSum = (keys: string[], submission: any) => {
        return keys
            .map((key) => Number(submission[key]))
            .reduce((partialSum, a) => partialSum + a, 0)
    }

    baseline.forEach((submission: any) => {
        baselineData.riskTaking = baselineData.riskTaking + calcSum(baselineKeys.riskTaking, submission)
        baselineData.innovativeness = baselineData.innovativeness + calcSum(baselineKeys.innovativeness, submission)
        baselineData.proactiveness = baselineData.proactiveness + calcSum(baselineKeys.proactiveness, submission)
        baselineData.competitiveAggressiveness = baselineData.competitiveAggressiveness + calcSum(baselineKeys.competitiveAggressiveness, submission)
        baselineData.autonomy = baselineData.autonomy + calcSum(baselineKeys.autonomy, submission)
    });

    final.forEach((submission: any) => {
        finalData.riskTaking = finalData.riskTaking + calcSum(finalKeys.riskTaking, submission)
        finalData.innovativeness = finalData.innovativeness + calcSum(finalKeys.innovativeness, submission)
        finalData.proactiveness = finalData.proactiveness + calcSum(finalKeys.proactiveness, submission)
        finalData.competitiveAggressiveness = finalData.competitiveAggressiveness + calcSum(finalKeys.competitiveAggressiveness, submission)
        finalData.autonomy = finalData.autonomy + calcSum(finalKeys.autonomy, submission)
    });

    const calculateSubmissions = () => {
        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'Level of increase of the entrepreneurial mindset and orientation'
            },
            xAxis: {
                categories: [
                    'RISK-TAKING ITEMS',
                    'INNOVATIVENESS ITEMS',
                    'PROACTIVENESS ITEMS',
                    'COMPETITIVE AGGRESSIVENESS ITEMS',
                    'AUTONOMY ITEMS',
                ]
            },
            series: [
                {
                    type: 'line',
                    name: 'BASELINE IMPACT',
                    data: Object.values(baselineData).map((data) => Number((data / baseline.length).toFixed())),
                },
                {
                    type: 'line',
                    name: 'FINAL IMPACT',
                    data: Object.values(finalData).map((data) => Number((data / final.length).toFixed())),
                },
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>

    );
};

export default EntrepreneurialOrientation;
