import AlreadySoldChart from './AlreadySoldChart';

interface IBusinessProfileProps {
    baseline: any[];
}

const ProductsNotInMarket = ({baseline}: IBusinessProfileProps) => {
    return <AlreadySoldChart baseline={baseline} />;
};

export default ProductsNotInMarket;
