import * as Highcharts from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import { Card, CardContent } from '@mui/material';
import { IPavesiBeneficiaryProps } from '../../utils/models';
import calculateAge from '../../utils/calculateAge';

const AgeChart = ({data}: IPavesiBeneficiaryProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const calculateSubmissions = () => {
    let till5: number = 0;
    let till10: number = 0;
    let till14: number = 0;
    let till18: number = 0;

    Object.keys(data).forEach((item: string) => {
      if (item) {
        const age = calculateAge(item);

        if (age <= 5) {
          till5 += data[item];
        } else if (age <= 10) {
          till10 += data[item];
        } else if (age <= 14) {
          till14 += data[item];
        } else if (age <= 18) {
          till18 += data[item];
        }
      }
    });

    const dashboardOptions: any = {
      chart: {
        height: 300,
        type: 'pie',
        style: {
          fontFamily: 'Rubik, sans-serif'
        },
        backgroundColor: '#fafafa'
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
          showInLegend: true
        }
      },
      series: [{
        name: 'Fascia d\'età',
        data: [
          {
            name: '0-5',
            y: till5,
            color: '#34a853'
          },
          {
            name: '6-10',
            y: till10,
            color: '#ea4236'
          },
          {
            name: '11-14',
            y: till14,
            color: '#4285f4'
          },
          {
            name: '15-18',
            y: till18,
            color: '#fbbd05'
          },
        ],
        size: '50%',
        innerSize: '50%'
      }]
    };

    setOptions(dashboardOptions);
  };

  useEffect(() => {
    calculateSubmissions();
  }, [data]);

  return (
    <Card className="chart-container">
      <CardContent>
        {
          options && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          )
        }
      </CardContent>
    </Card>
  );
};

export default AgeChart;
