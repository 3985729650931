import * as React from 'react';
import {Box, Typography} from '@mui/material';

interface IEnrolledItemProps {
    value: string,
    text: string,
}

const EnrolledItem = ({value, text}: IEnrolledItemProps) => {
    return (
        <>
            <Box
                sx={{
                    width: 120,
                    p: 1,
                    border: '1px solid grey',
                    textAlign: 'right',
                    fontSize: '1.25rem',
                    backgroundColor: 'rgb(243, 244, 246)',
                }}
            >
                {value}
            </Box>
            <Typography>
                {text}
            </Typography>
        </>
    )
}

export default EnrolledItem