import { useEffect, useRef, useState } from 'react';
import { Card, CardContent } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS, EXPORTING} from '../../../utils/constants';

interface IAttentamenteChartProps {
  data: {
    average: number;
    uoa: number;
  };
  categories: string[]
}

const AttentamenteChart = ({data, categories}: IAttentamenteChartProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const calculateSubmissions = () => {
    const dashboardOptions: any = {
      chart: {
        height: 400,
        type: 'column',
        style: {
          fontFamily: 'Rubik, sans-serif'
        },
        backgroundColor: '#fafafa'
      },
      title: {
        text: '',
      },
      xAxis: {
        categories
      },
      yAxis: {
        min: 0,
        max: 100,
        labels: {
          format: '{value}%'
        },
        title: {
          text: ''
        }
      },
      tooltip: {
        valueSuffix: '%'
      },
      plotOptions: {
        column: {
          cursor: 'pointer',
          stacking: 'normal',
        }
      },
      series: [
        {
          showInLegend: false,
          color: COLORS.blue,
          name: '',
          data: [data.uoa * 100, data.average * 100]
        },
      ],
      exporting: EXPORTING
    };

    setOptions(dashboardOptions);
  };

  useEffect(() => {
    calculateSubmissions();
  }, []);

  return (
    <Card>
      <CardContent>
        {
          options && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          )
        }
      </CardContent>
    </Card>
  );
};

export default AttentamenteChart;
