import React, {useEffect, useState} from 'react';
import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import ActivitiesReachingRate from './ActivitiesReachingRate';
import NeetEngagementAbility from './NeetEngagementAbility';
import AceOfMembership from './AceOfMembership';
import PathStartRate from './PathStartRate';
import BeneficiariesActivationRate from './BeneficiariesActivationRate';
import AceOfBeneficiaries from './AceOfBeneficiaries';
import ActivationRateOfBeneficiaries from './ActivationRateOfBeneficiaries';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const NeetworkParent = () => {
    const [searchParams] = useSearchParams();

    const container = searchParams.get('container_id');
    const uoa_id = searchParams.get('uoa_id');
    const hashKey = searchParams.get('key');
    const planned_id = searchParams.get('planned_id');
    const updating_id = searchParams.get('updating_id');

    const [loading, setLoading] = useState(false);
    const [plannedData, setPlannedData] = useState<any[]>([]);
    const [updatingData, setUpdatingData] = useState<any[]>([]);

    const getSurvey = async () => {
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_NEETWORK || '');

        setUserToken(searchParams.get('token') || '')

        try {
            if (planned_id && updating_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: planned_id,
                        }
                    }),
                    apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: updating_id,
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setPlannedData(responses[0].data.submissions);
                setUpdatingData(responses[1].data.submissions);

                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getSurvey();
    }, []);

    return (
        <div className='attentamente-parent'>
            {
                !loading && (
                    <>
                        {
                            plannedData.length > 0 && updatingData.length > 0 && uoa_id && (
                                <>
                                    <ActivitiesReachingRate
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <NeetEngagementAbility
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <AceOfMembership
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <PathStartRate
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <BeneficiariesActivationRate
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <AceOfBeneficiaries
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                    <ActivationRateOfBeneficiaries
                                        uoa={uoa_id}
                                        plannedData={plannedData}
                                        updatingData={updatingData}
                                    />
                                </>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className='loader-container'>
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default NeetworkParent;
