import React from 'react';
import {ISubmissionsUoaData} from '../utils/data';
import Beneficiary from './Beneficiary';

const ProjectDetails = ({iniziativaSubmissions, beneficiarySubmissions, uoaParentId}: ISubmissionsUoaData) => {
    return (
        <>
            <div className='w-full'>
                <div className='section-title'>
                    <div>Dettagli dell'iniziativa</div>
                </div>
                <Beneficiary
                    iniziativaSubmissions={iniziativaSubmissions}
                    beneficiarySubmissions={beneficiarySubmissions.filter((submission: any) => submission.uoa_id === uoaParentId)}
                />
            </div>
        </>
    );
};

export default ProjectDetails;
