import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IBusinessStatusChangeProps {
    final: any[];
}

const transform = [
    {
        name: 'I was able to grow my business',
        value: 'BIZ_SCALE_T1',
    },
    {
        name: 'I started another business in addition to the one I had',
        value: 'BIZ_NEW_T1',
    },
    {
        name: 'Nothing has changed',
        value: 'BIZ_NO_CHANGE_T1',
    },
    {
        name: 'I close my business',
        value: 'BIZ_CLOSE_T1',
    },
];

const BusinessStatusChange = ({final}: IBusinessStatusChangeProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: final.filter((submission: any) => submission.career_status_biz_t1 === el.value).length,
        }));

        const title = 'Detail of the business status change';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessStatusChange;
