import * as React from 'react';
import CustomTable from "../../../shared/Table";
import IconButton from "@mui/material/IconButton";
import {Download} from "@mui/icons-material";
import {Typography} from "@mui/material";
import {download} from "../../../utils/download";

interface INumeroValutatoreProps {
    submissions: { user_name: string }[];
}

const NumeroValutatore = ({submissions}: INumeroValutatoreProps) => {
    const columns: [string, string] = [
        'Nome valutatore',
        'Numero di valutazioni'
    ];

    let data: any = {};
    let rows: any = [];

    submissions.forEach(function (submission: { user_name: string }) {
        data[submission.user_name] = (data[submission.user_name] || 0) + 1;
    })

    for (const [key, value] of Object.entries(data)) {
        rows.push([
            key,
            value
        ])
    }

    rows.sort(function (a: any, b: any) {
        return b[1] - a[1];
    });

    const handleDownload = () => {
        download([columns, ...rows], 'Numero di valutazioni per valutatore')
    }

    return (
        <>
            <div className="content-title">
                <Typography variant="h5">
                    Numero di valutazioni per valutatore
                </Typography>
                <IconButton onClick={handleDownload}>
                    <Download fontSize="small"/>
                </IconButton>
            </div>

            <CustomTable
                columns={columns}
                rows={rows}
                numberRows={[0]}
            />
        </>
    );
};

export default NumeroValutatore;
