import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import EnrolledItem from '../../base/EnrolledItem';

interface IEbitdaProps {
    final: any[];
    baseline: any[];
}

const Ebitda = ({final, baseline}: IEbitdaProps) => {
    const [ebitda, setEbitda] = React.useState<string>('0');
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();
    const filteredBaseline: any[] = [];
    const filteredFinal: any[] = [];

    const calculateSubmissions = () => {
        let validCount = 0;
        let notValidCount = 0;

        final.forEach((submission: any) => {
            const total = submission.ebitda_t1_value;
            const baselineSubmission = baseline.find(
                (baselineSubmission: any) => submission.uoa_id === baselineSubmission.uoa_id
            );

            if (baselineSubmission) {
                const baselineTotal = baselineSubmission.ebitda_t_1_ebitda_amount;

                if (total > baselineTotal) {
                    validCount++;
                    filteredBaseline.push(baselineSubmission);
                    filteredFinal.push(submission);
                } else notValidCount++;
            }
        });

        const avgBaselineData = filteredBaseline
            .filter((submission: any) => submission.ebitda_t_1_ebitda_amount != null)
            .map((submission: any) => submission.ebitda_t_1_ebitda_amount);

        const avgBaseline = avgBaselineData.length > 0 ? avgBaselineData.reduce((a, b) => a + b, 0) / avgBaselineData.length : 0;

        const avgFinalData = filteredFinal
            .filter((submission: any) => submission.ebitda_t1_value !== null)
            .map((submission: any) => submission.ebitda_t1_value);

        const avgFinal = avgFinalData.length > 0 ? avgFinalData.reduce((a, b) => a + b, 0) / avgFinalData.length : 0;

        if (validCount + notValidCount > 0)
            setEbitda(((validCount / (validCount + notValidCount)) * 100).toFixed(1) + '%');

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Level of increased EBITDA',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: ['BASELINE', 'FINAL'],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: [Number(avgBaseline.toFixed(2)), Number(avgFinal.toFixed(2))],
                    type: 'column',
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            text='% Entrepreneurs that increased the ebitda'
                            value={ebitda}
                        />
                    </div>
                    <br />
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default Ebitda;
