import * as React from "react";
import {Typography} from "@mui/material";
import MidtermOutcomes from "./MidtermOutcomes";
import ProductsInMarket from "./ProductsInMarket";
import BusinessModelImprovement from "./BusinessModelImprovement";
import EntrepreneursMeasure from "./EntrepreneursMeasure";
import YearImproved from "./YearImproved";
import BookKeeping from "./BookKeeping";
import InnovationPerformance from "./InnovationPerformance";
import ExternalFunding from "./ExternalFunding";
import VolumeOfFundings from "./VolumeOfFundings";
import FundsKind from "./FundsKind";
import ClosedAgreement from "./ClosedAgreement";
import TradeAgreements from "./TradeAgreements";
import B2BAgreements from "./B2BAgreements";
import NewSales from "./NewSales";
import StartedSell from "./StartedSell";
import CustomerNetwork from "./CustomerNetwork";
import ImpactOutcomes from "./ImpactOutcomes";

interface IMidTermProps {
    final: any[],
    baseline: any[],
}

const MidTerm = ({final, baseline}: IMidTermProps) => {
    return (
        <div className="row">
            <MidtermOutcomes/>
            <div>
                <ProductsInMarket baseline={baseline} final={final}/>
            </div>
            <div>
                <BusinessModelImprovement final={final}/>
            </div>
            <div>
                <EntrepreneursMeasure final={final}/>
            </div>
            <div>
                <YearImproved final={final}/>
            </div>
            <div>
                <BookKeeping final={final} baseline={baseline}/>
            </div>
            <div>
                <InnovationPerformance final={final}/>
            </div>
            <div className="col-span-2 bold px-4">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    Entrepreneurs get financing
                </Typography>
            </div>
            <div></div>
            <div>
                <ExternalFunding final={final}/>
            </div>
            <div>
                <VolumeOfFundings final={final}/>
            </div>
            <div>
                <FundsKind final={final}/>
            </div>
            <div className="col-span-2 bold px-4">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    Entrepreneurs close trade agreements
                </Typography>
            </div>
            <div>
                <ClosedAgreement final={final}/>
            </div>
            <div>
                <TradeAgreements final={final}/>
            </div>
            <div></div>
            <div>
                <B2BAgreements final={final}/>
            </div>
            <div className="col-span-2 bold px-4">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    Entrepreneurs increase sales
                </Typography>
            </div>
            <div>
                <NewSales baseline={baseline} final={final}/>
            </div>
            <div>
                <Typography  sx={{textAlign: 'center'}}>
                    Sales Channels name
                </Typography>
                <div className="mt-1">
                    <Typography  sx={{fontWeight: 500, textAlign: 'center'}}>
                        WORDCLOUD
                    </Typography>
                </div>
            </div>
            <div>
                <StartedSell baseline={baseline} final={final}/>
            </div>
            <div>
                <CustomerNetwork final={final}/>
            </div>
            <div className="col-span-2 bold px-4">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    Entrepreneurs implement actions for the improvement of their social and environmental impact
                </Typography>
            </div>
            <ImpactOutcomes final={final} />
        </div>
    )
}

export default MidTerm