import * as React from "react";
import Enrolled from "./Enrolled";
import DropOuts from "./DropOuts";

interface IStudentsProps {
    students: string,
    women: string,
    graduated: { yes: number, no: number },
}

const Students = ({students, women, graduated}: IStudentsProps) => {
    return (
        <div className="space-y-4">
            <Enrolled students={students} women={women}/>
            <DropOuts graduated={graduated}/>
        </div>
    )
}

export default Students