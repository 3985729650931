import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IEmployeesProps {
    final: any[];
    baseline: any[];
}

const Employees = ({final, baseline}: IEmployeesProps) => {
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const finalCount = final.filter((submission: any) => {
            const total = submission.jobs_t1_men_t1_number + submission.jobs_t1_women_t1_number;
            const baselineSubmission = baseline.find(
                (baselineSubmission: any) => submission.uoa_id === baselineSubmission.uoa_id
            );

            if (baselineSubmission) {
                const baselineTotal = baselineSubmission.jobs_t0_men_t0_number + baselineSubmission.jobs_t0_women_t0_number;

                return total > baselineTotal;
            }

            return false;
        }).length;

        const data = [
            {
                name: 'Yes',
                y: finalCount,
            },
            {
                name: 'No',
                y: final.length - finalCount,
            },
        ];

        const title = '% of entrepreneurs who increased the number of employees';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default Employees;
