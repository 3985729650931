import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IFundsKindProps {
    final: any[];
}

const transform = [
    {
        name: 'Resources from family & friends',
        value: 'FUND_KIND_FAM_T1',
    },
    {
        name: 'Business Angels',
        value: 'FUND_KIND_BA_T1',
    },
    {
        name: 'Crowdfunding',
        value: 'FUND_KIND_CF_T1',
    },
    {
        name: 'Incubators and Accelerators',
        value: 'FUND_KIND_ACC_T1',
    },
    {
        name: 'NGO’s grants',
        value: 'FUND_KIND_NGO_T1',
    },
    {
        name: 'Public subsidies',
        value: 'FUND_KIND_PUB_T1',
    },
    {
        name: 'Venture capital',
        value: 'FUND_KIND_VC_T1',
    },
    {
        name: 'Microfinance',
        value: 'FUND_KIND_MF_T1',
    },
    {
        name: 'Financial institutions (banks)',
        value: 'FUND_KIND_BANK_T1',
    },
];

const FundsKind = ({final}: IFundsKindProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: 0,
        }));

        final.forEach((submission: any) => {
            transform.forEach((el, index) => {
                if (Object.values(submission).indexOf(el.value) > -1) {
                    data[index].y = data[index].y + 1;
                }
            });
        });

        const title = 'Kind of Funds';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default FundsKind;
