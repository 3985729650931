import React from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import Pavesi from './containers/Pavesi';
import PavesiHomepage from './containers/PavesiHomepage';
import AttentamenteParent from './containers/AttentamenteParent';
import AttentamenteParentAverage from './containers/AttentamenteParentAverage';
import AttentamenteChild from './containers/AttentamenteChild';
import SharedThemeProvider from './shared/SharedThemeProvider';
import Unipolis from './containers/Unipolis';
import CDP from './containers/CDP';
import CdpProposalGrid from './containers/CdpProposalGrid';
import CdpAnagrafica from './containers/CdpAnagrafica';
import E4Impact from './containers/E4Impact';
import E4ImpactMBA from './containers/E4ImpactMBA';
import Feba from './containers/Feba';
import FebaOrganization from './containers/FebaOrganization';
import WIAParent from './containers/WIAParent';
import WIAChild from './containers/WIAChild';
import WIAParentBeneficiary from './containers/WIAParentBeneficiary';
import LibriProject from './containers/LibriProject';
import LibriIniziative from './containers/LibriIniziative';
import AttentamenteSecondParent from './containers/AttentamenteSecondParent';
import AttentamenteSecondParentAverage from './containers/AttentamenteSecondParentAverage';
import AttentamenteSecondChild from './containers/AttentamenteSecondChild';
import NeetworkParent from './containers/NeetworkParent';
import {COLORS} from './utils/constants';

import './App.css';

function App() {
    const router = createBrowserRouter([
        {
            path: '/pavesi',
            element: (
                <SharedThemeProvider mainColor={COLORS.pavesiMain}>
                    <Pavesi/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/pavesi-homepage',
            element: (
                <SharedThemeProvider mainColor={COLORS.pavesiMain}>
                    <PavesiHomepage/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-parent',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteParent/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-parent-average',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteParentAverage/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-child',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteChild/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/unipolis',
            element: (
                <SharedThemeProvider mainColor={COLORS.unipolisMain}>
                    <Unipolis/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/cdp-evaluation-grid',
            element: (
                <SharedThemeProvider mainColor={COLORS.cdpMain}>
                    <CDP/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/cdp-proposal-grid',
            element: (
                <SharedThemeProvider mainColor={COLORS.cdpMain}>
                    <CdpProposalGrid/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/cdp-anagrafica',
            element: (
                <SharedThemeProvider mainColor={COLORS.cdpMain}>
                    <CdpAnagrafica/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/e4-impact',
            element: (
                <SharedThemeProvider mainColor={COLORS.e4ImpactMain}>
                    <E4Impact/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/e4-impact-mba',
            element: (
                <SharedThemeProvider mainColor={COLORS.e4ImpactMain}>
                    <E4ImpactMBA/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/feba',
            element: (
                <SharedThemeProvider mainColor={COLORS.feba}>
                    <Feba/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/feba-organizations',
            element: (
                <SharedThemeProvider mainColor={COLORS.feba}>
                    <FebaOrganization/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/wia-parent',
            element: (
                <SharedThemeProvider mainColor={COLORS.wiaMain}>
                    <WIAParent/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/wia-child',
            element: (
                <SharedThemeProvider mainColor={COLORS.wiaMain}>
                    <WIAChild/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/wia-parent-beneficiary',
            element: (
                <SharedThemeProvider mainColor={COLORS.wiaMain}>
                    <WIAParentBeneficiary/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/libri-project',
            element: (
                <SharedThemeProvider mainColor={COLORS.libriMain}>
                    <LibriProject/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/libri-iniziative',
            element: (
                <SharedThemeProvider mainColor={COLORS.libriMain}>
                    <LibriIniziative/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-second-parent',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteSecondParent/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-second-parent-average',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteSecondParentAverage/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/attentamente-second-child',
            element: (
                <SharedThemeProvider mainColor={COLORS.attentamenteMain}>
                    <AttentamenteSecondChild/>
                </SharedThemeProvider>
            ),
        },
        {
            path: '/neetwork-parent',
            element: (
                <SharedThemeProvider mainColor={COLORS.networkMain}>
                    <NeetworkParent/>
                </SharedThemeProvider>
            ),
        },
    ]);

    return (
        <RouterProvider router={router}/>
    );
}

export default App;
