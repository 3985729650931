import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import React, {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import AttentamenteChart from './AttentamenteChart';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Info from "@mui/icons-material/Info";

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const AttentamenteChild = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const [loading, setLoading] = useState(false);
    const [obiettivoData, setObiettivoData] = useState<{ average: number; uoa: number; }>();
    const [modalitaData, setModalitaData] = useState<{ average: number; uoa: number; }>();
    const [luogoData, setLuogoData] = useState<{ average: number; uoa: number; }>();
    const [allData, setAllData] = useState<{ average: number; uoa: number; }>();

    const getSurvey = async () => {
        const hashKey = searchParams.get('key');
        const actual_id = searchParams.get('actual_id');
        const previsioned_id = searchParams.get('previsioned_id');
        const uoa_id = searchParams.get('uoa_id');
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_CHILD || '');

        setUserToken( searchParams.get('token') || '')

        try {
            if (actual_id && previsioned_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/child/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            questions: [6]
                        }
                    }),
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/child/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            questions: [7]
                        }
                    }),
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/child/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            questions: [8]
                        }
                    }),
                    apiCall({
                        url: `containers/${container}/uoa/${uoa_id}/dashboards/child/achievement`,
                        params: {
                            actual_id,
                            previsioned_id,
                            questions: [6, 7, 8]
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setObiettivoData(responses[0].data.data);
                setModalitaData(responses[1].data.data);
                setLuogoData(responses[2].data.data);
                setAllData(responses[3].data.data);

                setLoading(false);
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        getSurvey();
    }, []);

    return (
        <div className="attentamente-child">
            {
                !loading && (
                    <>
                        <div className="attentamente-child__title">
                            Tasso di raggiungimento dei beneficiari attesi…
                        </div>
                        <div className="attentamente-child__row">
                            {
                                allData && (
                                    <div>
                                        <div className="section-title">
                                            <div>
                                                ..per attività "gemelle"
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il tasso di raggiungimento dei beneficiati attesi per la
                                                    vostra attività a confronto la media dello stesso valore per tutte le
                                                    attività che, nell’ambito di tutti i progetti, hanno contemporaneamente lo
                                                    stesso obiettivo, la stessa modalità e lo stesso luogo di svolgimento. Il
                                                    tasso di raggiungimento dei beneficiari attesi è ottenuto dal rapporto tra i
                                                    beneficiari che si prevedeva di raggiungere secondo la scheda previsionale
                                                    dell’attività oggetto di analisi e i beneficiari effettivamente raggiunti
                                                    secondo la scheda finale della medesima attività. Il valore è quindi
                                                    disponibile per le sole attività effettivamente concluse, per le quali è
                                                    stata completata la scheda finale.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <AttentamenteChart data={allData}/>
                                    </div>
                                )
                            }
                            {
                                obiettivoData && (
                                    <div>
                                        <div className="section-title">
                                            <div>
                                                ...per attività con lo stesso obiettivo
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il tasso di raggiungimento dei beneficiati attesi per la
                                                    vostra attività a confronto la media dello stesso valore per tutte le
                                                    attività che, nell’ambito di tutti i progetti, hanno lo stesso obiettivo. Il
                                                    tasso di raggiungimento dei beneficiari attesi è ottenuto dal rapporto tra i
                                                    beneficiari che si prevedeva di raggiungere secondo la scheda previsionale
                                                    dell’attività oggetto di analisi e i beneficiari effettivamente raggiunti
                                                    secondo la scheda finale della medesima attività. Il valore è quindi
                                                    disponibile per le sole attività effettivamente concluse, per le quali è
                                                    stata completata la scheda finale.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <AttentamenteChart data={obiettivoData}/>
                                    </div>
                                )
                            }
                            {
                                modalitaData && (
                                    <div>
                                        <div className="section-title">
                                            <div>
                                                ...per attività con la stessa modalità di intervento
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il tasso di raggiungimento dei beneficiati attesi per la
                                                    vostra attività a confronto la media dello stesso valore per tutte le
                                                    attività che, nell’ambito di tutti i progetti, hanno la stessa modalità di
                                                    intervento. Il tasso di raggiungimento dei beneficiari attesi è ottenuto dal
                                                    rapporto tra i beneficiari che si prevedeva di raggiungere secondo la scheda
                                                    previsionale dell’attività oggetto di analisi e i beneficiari effettivamente
                                                    raggiunti secondo la scheda finale della medesima attività. Il valore è
                                                    quindi disponibile per le sole attività effettivamente concluse, per le
                                                    quali è stata completata la scheda finale.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <AttentamenteChart data={modalitaData}/>
                                    </div>
                                )
                            }
                            {
                                luogoData && (
                                    <div>
                                        <div className="section-title">
                                            <div>
                                                ...per attività con lo stesso luogo di svolgimento
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il tasso di raggiungimento dei beneficiati attesi per la
                                                    vostra attività a confronto la media dello stesso valore per tutte le
                                                    attività che, nell’ambito di tutti i progetti, hanno lo stesso luogo di
                                                    svolgimento. Il tasso di raggiungimento dei beneficiari attesi è ottenuto
                                                    dal rapporto tra i beneficiari che si prevedeva di raggiungere secondo la
                                                    scheda previsionale dell’attività oggetto di analisi e i beneficiari
                                                    effettivamente raggiunti secondo la scheda finale della medesima attività.
                                                    Il valore è quindi disponibile per le sole attività effettivamente concluse,
                                                    per le quali è stata completata la scheda finale.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <AttentamenteChart data={luogoData}/>
                                    </div>
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default AttentamenteChild;
