import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import Project from './Project';
import AllProjects from './AllProjects';
import ProjectDetails from './ProjectDetails';

import './styles.scss';

export const previsionaleCheckpoint: number = 42;
export const consuntivoCheckpoint: number = 43;

const LibriIniziative = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const iniziativa = searchParams.get('iniziativa');
    const beneficiary = searchParams.get('beneficiary');
    const uoa_id = parseInt(searchParams.get('uoa_id') || '0');
    const hashKey = searchParams.get('key');

    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_LIBRI || '');

    const [iniziativaSubmissions, setIniziativaSubmissions] = useState([]);
    const [beneficiarySubmissions, setBeneficiarySubmissions] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSurvey = async () => {
        setLoading(true);
        setUserToken(searchParams.get('token') || '')

        try {
            if (container && uoa_id && beneficiary && iniziativa && hashKey === hashKeyLocal) {
                const requests = [
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: iniziativa,
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: beneficiary,
                        }
                    }),
                ];

                const responses = await Promise.all(requests);

                setIniziativaSubmissions(responses[0]?.data?.submissions);
                setBeneficiarySubmissions(responses[1]?.data?.submissions);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className='container-parent'>
            {
                !loading && (
                    <>
                        <Project
                            iniziativaSubmissions={iniziativaSubmissions.filter((submission: any) => submission.uoa_id === uoa_id)}
                            beneficiarySubmissions={beneficiarySubmissions.filter((submission: any) => submission.uoa_id === uoa_id)}
                        />
                        <AllProjects
                            iniziativaSubmissions={iniziativaSubmissions}
                            beneficiarySubmissions={beneficiarySubmissions}
                            uoaParentId={uoa_id}
                        />
                        <ProjectDetails
                            iniziativaSubmissions={iniziativaSubmissions}
                            beneficiarySubmissions={beneficiarySubmissions}
                            uoaParentId={uoa_id}
                        />
                    </>
                )
            }
            {
                loading && (
                    <div className='loader-container'>
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default LibriIniziative;
