import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface ISizeOfFundsProps {
    final: {[key: string]: string}[];
}

const QUESTIONS = [
    'fund_size_t1_0',
    'fund_size_t1_1',
    'fund_size_t1_2',
    'fund_size_t1_3',
    'fund_size_t1_4',
    'fund_size_t1_5',
];

const transform = [
    {
        name: '0 - 5.000 $',
        value: 'FUND_SIZE_T1_5K',
    },
    {
        name: '6.000 – 15.000 $',
        value: 'FUND_SIZE_T1_15K',
    },
    {
        name: '16.000 - 30.000 $',
        value: 'FUND_SIZE_T1_30K',
    },
    {
        name: '31.000 - 50.000 $',
        value: 'FUND_SIZE_T1_50K',
    },
    {
        name: '51.000 - 100.000 $',
        value: 'FUND_SIZE_T1_100K',
    },
    {
        name: '100.000 $',
        value: 'FUND_SIZE_T1_+100K',
    },
];

const SizeOfFunds = ({final}: ISizeOfFundsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const data = transform.map((el) => ({
        name: el.name,
        y: 0,
    }));

    final.forEach((submission) => {
        QUESTIONS.forEach((question) => {
            const answer = submission[question];
            const index = transform.findIndex((el) => el.value === answer);

            if (answer && index !== -1) {
                data[index].y = data[index].y + 1;
            }
        });
    });

    const calculateSubmissions = () => {
        const title = 'Size of Funds';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default SizeOfFunds;
