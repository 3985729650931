import React, {useContext} from 'react';
import ImprovementsChart from './ImprovementsChart';
import AcceleratorsChart from './AcceleratorsChart';
import {ContainerContext} from '../../../../contexts/Container';
import {TYPE_MBA_STUDENT, TYPE_ACCELERATOR} from '../../helpers/mapping';

interface IIncreasedSkillsProps {
    baseline: any[];
    final: any[];
}

const IncreasedSkills = ({baseline, final}: IIncreasedSkillsProps) => {
    const {type} = useContext(ContainerContext);

    return (
        <>
            {
                Number(type) === TYPE_MBA_STUDENT && <ImprovementsChart
                    baseline={baseline}
                    final={final}
                />
            }
            {
                Number(type) === TYPE_ACCELERATOR && <AcceleratorsChart
                    baseline={baseline}
                    final={final}
                />
            }
        </>
    );
};

export default IncreasedSkills;
