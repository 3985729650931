import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    final: any[],
}

const B2BAgreements = ({final}: IBusinessProfileProps) => {
    let value = 0;
    const agreements = final.reduce((partialSum: number, submission: any) => partialSum + Number(submission.trade_agr_num_t1), 0)

    if (agreements > 0) {
        value = final.reduce((partialSum: number, submission: any) => partialSum + Number(submission.trade_agr_b2b_t1), 0) / agreements
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="space-y-4">
                    <Typography>
                        How many trade agreements through the B2B platform
                    </Typography>
                    <Box
                        sx={{
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        <div>
                            { value * 100 }%
                        </div>
                    </Box>

                </div>
            </CardContent>
        </Card>
    );
};

export default B2BAgreements;
