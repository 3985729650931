import React, {useEffect, useRef, useState} from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IMainSocialImpactProps {
    submissions: any[];
}

const PRODUCT_OR_SERVICE = 'Item 3';
const PEOPLE = 'Item 4';

const MainSocialImpact = ({submissions}: IMainSocialImpactProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const values = {
            product: 0,
            people: 0,
        };

        submissions.forEach((submission) => {
            for (let i = 0; i < 4; i++) {
                const key = `question24_${i}`;
                const value = submission[key];

               if (value === PRODUCT_OR_SERVICE) {
                   values.product++;
               } else if (value === PEOPLE) {
                   values.people++;
               }
            }
        });

        const data = [
            {
                name: 'Serving marginalised communities with my product/service',
                y: values.product,
                color: COLORS.blue,
            },
            {
                name: 'Employing vulnerable and marginalized people in my business',
                y: values.people,
                color: COLORS.yellow,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(
            'Main social impact purpose',
            data
        );

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default MainSocialImpact;
