import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IBusinessProfileProps {
    submissions: any[];
}

const transform = [
    {
        name: 'Established entrepreneur, running a business for more than 2 years',
        value: 'BIZ>2Y_T0',
    },
    {
        name: 'Start-upper, running a business for less than 2 years',
        value: 'BIZ<2Y_T0',
    },
    {
        name: 'Employed, in any role including managerial and executive, with a business idea',
        value: 'BIZ_IDEA_EM_T0',
    },
    {
        name: 'Unemployed with a business idea',
        value: 'BIZ_IDEA_UNEM_T0',
    },
];

const CareerProfile = ({submissions}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: 0,
        }));

        submissions.forEach((submission: any) => {
            const index = transform.findIndex((el) => el.value === submission.career_status_t0);

            if (index !== -1) {
                data[index].y = data[index].y + 1;
            }
        });

        const dashboardOptions: any = getDefaultPieFormatting('Career Profile', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default CareerProfile;
