import ChoicesBar from "../bar/ChoicesBar";
import Number from "../number";

interface SchedaEnteProps {
    submissions: [],
    elements: {
        name: string,
        choices: [],
    }[]
}

const SchedaIniziativa = ({submissions, elements}: SchedaEnteProps) => {
    return (
        <div className="container-row">
            <div>
                <div className="section-title">
                    Dimenzione di intervento
                </div>
                <ChoicesBar data={submissions} name="dimensione_intervento" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Categoria destinatari principali dell’iniziativa
                </div>
                <ChoicesBar data={submissions} name="cat_destinatari_iniziativa" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Fascia d’età
                </div>
                <ChoicesBar data={submissions} name="fascia_età" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Numero totale alleati previsti
                </div>
                <Number data={submissions} name="totale_alleati" />
            </div>

            <div>
                <div className="section-title">
                    Quali Obiettivi Di Sviluppo Sostenibile dell'Agenda 2030 Onu l'iniziativa contribuisce a raggiunger
                </div>
                <ChoicesBar data={submissions} name="obiettivi_onu" elements={elements}/>
            </div>
        </div>
    );
};

export default SchedaIniziativa;
