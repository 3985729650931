import {Card, CardContent} from '@mui/material';

import './styles.scss';

interface ITassoProps {
    data: any[],
    name: string
}

const Number = ({data, name}: ITassoProps) => {
    const result = data
        .map(item => item[name])
        .reduce((partialSum, a) => partialSum + a, 0);

    return (
        <div className="charts_average">

            <Card sx={{width: '100%'}}>
                <CardContent>
                    <div className="competenze-educative__charts_average_value">{result}</div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Number;
