import {Card, CardContent} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {getDefaultPieFormatting} from '../../charts/pie';
import LevelOfImprovement from './LevelOfImprovement';

interface IBookkeepingImprovementProps {
    baseline: any[];
    final: any[];
}

const CATEGORIES = [
    {
        name: 'Never',
        baseAnswer: 'NEVER_T0',
        finalAnswer: 'NEVER_T1',
    },
    {
        name: 'Sometimes',
        baseAnswer: 'SOMETIMES_T0',
        finalAnswer: 'SOMETIMES_T1',
    },
    {
        name: 'Yearly',
        baseAnswer: 'YEARLY_T0',
        finalAnswer: 'YEARLY_T1',
    },
    {
        name: 'Twice a year',
        baseAnswer: 'TWICE A YEAR_T0',
        finalAnswer: 'TWICE A YEAR_T1',
    },
    {
        name: 'Quarterly',
        baseAnswer: 'QUARTERLY_T0',
        finalAnswer: 'QUARTERLY_T1',
    },
];

const BookkeepingImprovement = ({final, baseline}: IBookkeepingImprovementProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [baseImproved, setBaseImproved] = useState<any[]>([]);
    const [finalImproved, setFinalImproved] = useState<any[]>([]);

    const calculateSubmissions = () => {
        const data = [
            {
                name: 'Not Improved',
                y: 0,
            },
            {
                name: 'Improved',
                y: 0,
            },
        ];

        const baseSubmissions: any[] = [];
        const finalSubmissions: any[] = [];

        baseline.forEach((submission: any) => {
            const finalSubmission = final.find((finalSubmission: any) => finalSubmission.uoa_id === submission.uoa_id);

            if (!finalSubmission) {
                return;
            }

            const indexBase = CATEGORIES.findIndex(
                (category) => category.baseAnswer === submission.bookkeeping_freq_t0
            );
            const indexFinal = CATEGORIES.findIndex(
                (category) => category.finalAnswer === finalSubmission.bookkeeping_freq_t1
            );

            if (indexBase !== -1 && indexFinal !== -1) {
                const dataIndex = indexBase >= indexFinal ? 0 : 1;
                data[dataIndex].y++;

                if (dataIndex === 1) {
                    baseSubmissions.push(submission);
                    finalSubmissions.push(finalSubmission);
                }
            }
        });

        const dashboardOptions: any = getDefaultPieFormatting(
            '% of entrepreneurs who improved the frequency of bookkeeping',
            data
        );

        setBaseImproved(baseSubmissions);
        setFinalImproved(finalSubmissions);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <div className='row'>
            <div>
                <Card className='chart-container'>
                    <CardContent className='space-y-4'>
                        {options && (
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        )}
                    </CardContent>
                </Card>
            </div>
            <div>
                {baseImproved.length > 0 && finalImproved.length > 0 && (
                    <LevelOfImprovement
                        baseline={baseImproved}
                        final={finalImproved}
                    />
                )}
            </div>
        </div>
    );
};

export default BookkeepingImprovement;
