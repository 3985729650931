import _ from 'lodash';

export default function getValidSubmissions(baseline: any[], final: any[]) {
    const validUoaIds = _.intersection(
        _.map(
            baseline.filter(({completed}) => completed),
            'uoa_id'
        ),
        _.map(
            final.filter(({completed}) => completed),
            'uoa_id'
        )
    );

    const filteredBaseline = baseline.filter(({uoa_id}) => validUoaIds.includes(uoa_id));
    const filteredFinal = final.filter(({uoa_id}) => validUoaIds.includes(uoa_id));

    return {filteredBaseline, filteredFinal};
}
