import apiCall from "../../../utils/api";

interface ISubmissionProps {
    selectedUoa: string,
    selectedYear: string,
    survey_id: string | number,
    container: string | number,
}

export const getSurveySubmissions = async ({container, survey_id, selectedUoa, selectedYear}: ISubmissionProps) => {
    const requests = [
        await apiCall({
            url: `containers/${container}/submissions`,
            params: {
                survey_id,
                ...(selectedUoa !== 'All') && {uoa_id: selectedUoa},
                ...(selectedYear !== 'All') && {checkpoint_id: selectedYear},
                additional_data: false,
            }
        })
    ];

    const responses = await Promise.all(requests);

    if (responses.length > 0) {
        return responses[0]?.data?.submissions
    }

    return []
}
