import React, {useEffect, useRef, useState} from 'react';
import {Card, CardContent, IconButton, Tooltip} from '@mui/material';
import {Info} from '@mui/icons-material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {COLORS} from '../../../../utils/constants';
import EnrolledItem from '../../base/EnrolledItem';

interface IAcceleratorsChartProps {
    baseline: any[];
    final: any[];
}

const submissionKeys = {
    business: ['question15_row_1'],
    business_planning: ['question15_row_2'],
    team_management: ['question15_row_3'],
    impact_measurement: ['question15_row_4'],
    financial_planning: ['question15_row_5'],
    managing_investors_relations: ['question15_row_6'],
};

const keys: ('business' | 'business_planning' | 'team_management' | 'impact_measurement' | 'financial_planning' | 'managing_investors_relations')[] = [
    'business',
    'business_planning',
    'team_management',
    'impact_measurement',
    'financial_planning',
    'managing_investors_relations',
];

const AcceleratorsChart = ({baseline, final}: IAcceleratorsChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [improvedPercentage, setImprovedPercentage] = useState<number>(0);

    const calcSum = (keys: string[], submission: any) => {
        return keys.map((key) => Number(submission[key].substr(submission[key].length - 1))).reduce((partialSum, a) => partialSum + a, 0);
    };

    function calculateImprovements(baselineSubmissions: any[], finalSubmissions: any[]) {
        const initObject = {
            business: 0,
            business_planning: 0,
            team_management: 0,
            impact_measurement: 0,
            financial_planning: 0,
            managing_investors_relations: 0,
        };

        const baselineData = {...initObject};
        const finalData = {...initObject};

        let improvedFinalCount = 0;

        finalSubmissions.forEach((submission: any) => {
            const baselineSubmission = baselineSubmissions.find((sub: any) => sub.uoa_id === submission.uoa_id);

            let currentValue = {...initObject};
            let baselineValue = {...initObject};

            keys.forEach((element) => {
                currentValue[element] = calcSum(submissionKeys[element], submission);
                baselineValue[element] = calcSum(submissionKeys[element], baselineSubmission);
            });

            const currentMean = Object.values(currentValue).reduce((partialSum, a) => partialSum + a, 0) / keys.length;
            const baselineMean =
                Object.values(baselineValue).reduce((partialSum, a) => partialSum + a, 0) / keys.length;

            if (currentMean > baselineMean) {
                improvedFinalCount++;

                keys.forEach((element) => {
                    finalData[element] += currentValue[element];
                    baselineData[element] += baselineValue[element];
                });
            }
        });

        keys.forEach((element) => {
            finalData[element] /= improvedFinalCount;
            baselineData[element] /= improvedFinalCount;
        });

        const finalDataImprovedPercentage = (improvedFinalCount / finalSubmissions.length) * 100;

        return {baselineData, finalData, finalDataImprovedPercentage};
    }

    const calculateSubmissions = (baselineSubmissions: any[], finalSubmissions: any[]) => {
        const {baselineData, finalData, finalDataImprovedPercentage} = calculateImprovements(
            baselineSubmissions,
            finalSubmissions
        );

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            xAxis: {
                categories: [
                    'Business modeling skills',
                    'Business planning skills',
                    'Team management skills',
                    'Impact measurement skills',
                    'Financial planning and accountability skills',
                    'Pitching and managing investors relations skills',
                ],
            },
            yAxis: {
                tickInterval: 1,
                min: 1,
                max: 7,
            },
            legend: {
                verticalAlign: 'top',
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                    },
                    groupPadding: 0.1,
                },
            },
            title: {
                text: '',
                align: 'left',
            },
            series: [
                {
                    type: 'column',
                    name: 'BASELINE',
                    data: Object.values(baselineData).map((data) => Number(data.toFixed(1))),
                    color: COLORS.blue,
                },
                {
                    type: 'column',
                    name: 'FINAL',
                    data: Object.values(finalData).map((data) => Number(data.toFixed(1))),
                    color: COLORS.yellow,
                },
            ],
        };

        return {dashboardOptions, finalDataImprovedPercentage};
    };

    useEffect(() => {
        const {dashboardOptions, finalDataImprovedPercentage} = calculateSubmissions(baseline, final);

        setImprovedPercentage(finalDataImprovedPercentage);

        setOptions(dashboardOptions);
    }, []);

    return (
        <>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <EnrolledItem
                            text='% of participants who increased their entrepreneurial skills'
                            value={improvedPercentage.toFixed(1)}
                        />
                    </div>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <div className='flex justify-end'>
                        <Tooltip
                            title={
                                <>
                                    <li>% increased business modeling skills</li>
                                    <li>% increased business planning skills</li>
                                    <li>% increased team management skills</li>
                                    <li>% increased impact measurement skills</li>
                                    <li>% increased financial planning and accountability skills</li>
                                    <li>% increased pitching and managing investors relations skills</li>
                                </>
                            }
                        >
                            <IconButton>
                                <Info
                                    htmlColor={COLORS.e4ImpactMain}
                                    fontSize='small'
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div>
                        <Card
                            elevation={0}
                            sx={{border: '1px solid #E0E0E0', borderRadius: '4px'}}
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                            />
                        </Card>
                    </div>
                </CardContent>
            </Card>
        </>
    );
};

export default AcceleratorsChart;
