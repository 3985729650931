import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Column from '../Column';

interface IDataProps {
    plannedData: any[],
    updatingData: any[],
    uoa: string | number,
}

const BeneficiariesActivationRate = ({plannedData, updatingData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: number; uoa: number; }>({
        average: 0,
        uoa: 0,
    });

    const calcData = () => {
        const average = {
            planned: 0,
            updating: 0,
        };
        const current = {
            planned: 0,
            updating: 0,
        }

        plannedData.forEach((plannedSubmission: any) => {
            const value = plannedSubmission.question8_in_possesso_dei_requisiti_richiesti_dal_bando_che_prevedete_aderiscano_al_progetto_firmando_il_documento_di_adesione_numero || 0;

            average.planned = average.planned + value;

            if (plannedSubmission.uoa_parent_id === Number(uoa)) {
                current.planned = average.planned + value;
            }
        })

        updatingData.forEach((updatingSubmission: any) => {
            const value = updatingSubmission.question10_che_avete_inserito_nel_mercato_del_lavoro_con_contratto_di_tirocinio_di_durata_a_6_mesi_o_contratto_di_lavoro_di_durata_a_4_mesi_full_time_o_part_time_non_inferiore_a_20_ore_settimanali_numero || 0;

            average.updating = average.updating + value;

            if (updatingSubmission.uoa_parent_id === Number(uoa)) {
                current.updating = average.updating + value;
            }
        })

        setData({
            average: average.updating / average.planned,
            uoa: current.updating / current.planned,
        })
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>TASSO DI ATTIVAZIONE DEI BENEFICIARI (SUL TOTALE DEGLI ADERENTI)</div>
                <Tooltip
                    title={<>
                        Il grafico mostra il tasso di attivazione dei beneficiari, complessivo e per ciascuna delle
                        tipologie di attivazione (inserimento nel mercato del lavoro e ripresa degli studi), per il
                        vostro progetto a confronto con la media degli stessi valori in tutti i progetti. I valori sono
                        ottenuti rispettivamente dal rapporto tra: <br/>
                        - i giovani NEET complessivamente attivati (somma tra i NEET inseriti nel mercato del lavoro e i
                        NEET che hanno ripreso gli studi) e i giovani NEET coinvolti in una o più delle attività
                        previste ai fini dell'attivazione, secondo la più recente scheda aggiornamento attività di
                        intercettazione; <br/>
                        - i giovani NEET in possesso dei requisiti richiesti dal bando che hanno aderito al progetto,
                        firmando il documento di adesione, secondo la stessa scheda
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Column
                    data={data}
                />
            }
        </div>
    );
};

export default BeneficiariesActivationRate;
