import * as Highcharts from 'highcharts';
import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Info from '@mui/icons-material/Info';
import {IUoaValue} from '../utils/models';
import makeAverageFromAverage from '../utils/makeAverageFromAverage';
import isNumeric from '../../../utils/isNumeric';
import makeAverage from '../../../utils/makeAverage';
import {COLORS} from '../../../utils/constants';

import './styles.scss';

interface IChartProps {
    submissions: any[];
    checkpoints: any[];
    uoas: {id: number; name: string}[];
    selectedUoa: string;
    checkpointId: null | string;
}

const transformations = {
    Avanzato: 5.0,
    Intermedio: 3.8,
    Base: 2.5,
    'In via di prima acquisizione': 1.3,
    Assente: 0,
};

const CompetenzeEducative = ({submissions, uoas, selectedUoa, checkpoints, checkpointId}: IChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const [giorniAssenza, setGiorniAssenza] = useState<any>();
    const [giorniPresenza, setGiorniPresenza] = useState<any>();
    const [votoMedio, setVotoMedio] = useState<any>();

    const processSubmission = (submission: any, line: IUoaValue[][]) => {
        if (submission.q1 && isNumeric(submission.q1)) {
            line[0].push({uoa: submission.uoa_id, value: Number(submission.q1)});
        }

        const q6 = transformations[submission?.q6 as keyof typeof transformations];
        if (q6) {
            line[1].push({uoa: submission.uoa_id, value: q6});
        }

        const q5 = transformations[submission?.q5 as keyof typeof transformations];
        if (q5) {
            line[2].push({uoa: submission.uoa_id, value: q5});
        }

        const q4 = transformations[submission?.q4 as keyof typeof transformations];
        if (q4) {
            line[3].push({uoa: submission.uoa_id, value: q4});
        }

        const q3 = transformations[submission?.q3 as keyof typeof transformations];
        if (q3) {
            line[4].push({uoa: submission.uoa_id, value: q3});
        }
    };

    const calculateSubmissions = (submissionsArray: any[]) => {
        const giorniAssenzaArr: number[] = [];
        const giorniPresenzaArr: number[] = [];
        const votoMedioArr: number[] = [];
        const lines: Array<IUoaValue[][]> = checkpoints.map(() => [[], [], [], [], []]);

        submissionsArray.forEach((submission: any) => {
            if (selectedUoa === 'All' || selectedUoa === submission.uoa_id) {
                const checkpointIndex = checkpoints.findIndex(
                    (checkpoint: any) => checkpoint.id === submission.checkpoint_id
                );

                if (checkpointIndex !== -1) {
                    processSubmission(submission, lines[checkpointIndex]);
                }

                if (submission.checkpoint_id === Number(checkpointId)) {
                    giorniAssenzaArr.push(submission.domanda1 ?? 0);
                    giorniPresenzaArr.push(submission.giorni_presenza ?? 0);
                    votoMedioArr.push(submission.domanda2 ?? 0);
                }
            }
        });

        if (giorniAssenzaArr.filter((el) => el).length) {
            setGiorniAssenza(makeAverage(giorniAssenzaArr, 2));
        } else {
            setGiorniAssenza(0);
        }

        if (giorniPresenzaArr.filter((el) => el).length) {
            setGiorniPresenza(makeAverage(giorniPresenzaArr, 2));
        } else {
            setGiorniPresenza(0);
        }

        if (votoMedioArr.filter((el) => el).length) {
            setVotoMedio(makeAverage(votoMedioArr, 2));
        } else {
            setVotoMedio(0);
        }

        const series: any[] = checkpoints.map((checkpoint: any, i: number) => ({
            type: 'line',
            name: checkpoint.name,
            color: Object.values(COLORS)[i] || '',
            data: makeAverageFromAverage(lines[i], selectedUoa, uoas),
        }));

        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif',
                },
                backgroundColor: '#fafafa',
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: ['Alfabetica Fuzionale', 'Culturale', 'Civica', 'Emotivo-relazionale', 'Digitale'],
            },
            yAxis: {
                tickAmount: 6,
                min: 0,
                max: 5,
                labels: {
                    format: '{value:.2f}',
                },
            },
            series,
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions(submissions);
    }, [selectedUoa]);

    return (
        <div className='competenze-educative'>
            {options && (
                <>
                    <div className='competenze-educative__charts'>
                        <div>
                            <div className='section-title'>
                                Competenze educative
                                <Tooltip
                                    sx={{
                                        marginLeft: '0.5rem',
                                    }}
                                    title={
                                        <div className='space-y-4'>
                                            <div className='px-4'>
                                                <div>Alfabetica Funzionale</div>
                                                <li>Avanzato = 5</li>
                                                <li>Intermedio = 4</li>
                                                <li>Base = 3</li>
                                                <li>In via di prima acquisizione = 2</li>
                                                <li>Assente = 1</li>
                                                <li>
                                                    Non dispongo di abbastanza elementi per rispondere = Escluso dal
                                                    grafico
                                                </li>
                                                <li>
                                                    Non applicabile alla situazione del minore = Escluso dal grafico
                                                </li>
                                            </div>

                                            <div className='px-4'>
                                                <div>Culturale</div>
                                                <li>Avanzato = 5.0</li>
                                                <li>Intermedio = 3.8</li>
                                                <li>Base = 2.5</li>
                                                <li>In via di prima acquisizione = 1.3</li>
                                                <li>Assente = 0</li>
                                                <li>
                                                    Non dispongo di abbastanza elementi per rispondere = Escluso dal
                                                    grafico
                                                </li>
                                                <li>
                                                    Non applicabile alla situazione del minore = Escluso dal grafico
                                                </li>
                                            </div>

                                            <div className='px-4'>
                                                <div>Civica</div>
                                                <li>Avanzato = 5.0</li>
                                                <li>Intermedio = 3.8</li>
                                                <li>Base = 2.5</li>
                                                <li>In via di prima acquisizione = 1.3</li>
                                                <li>Assente = 0</li>
                                                <li>
                                                    Non dispongo di abbastanza elementi per rispondere = Escluso dal
                                                    grafico
                                                </li>
                                                <li>
                                                    Non applicabile alla situazione del minore = Escluso dal grafico
                                                </li>
                                            </div>

                                            <div className='px-4'>
                                                <div>Emotivo-relazionale</div>
                                                <li>Avanzato = 5.0</li>
                                                <li>Intermedio = 3.8</li>
                                                <li>Base = 2.5</li>
                                                <li>In via di prima acquisizione = 1.3</li>
                                                <li>Assente = 0</li>
                                                <li>
                                                    Non dispongo di abbastanza elementi per rispondere = Escluso dal
                                                    grafico
                                                </li>
                                                <li>
                                                    Non applicabile alla situazione del minore = Escluso dal grafico
                                                </li>
                                            </div>

                                            <div className='px-4'>
                                                <div>Digitale</div>
                                                <li>Avanzato = 5.0</li>
                                                <li>Intermedio = 3.8</li>
                                                <li>Base = 2.5</li>
                                                <li>In via di prima acquisizione = 1.3</li>
                                                <li>Assente = 0</li>
                                                <li>
                                                    Non dispongo di abbastanza elementi per rispondere = Escluso dal
                                                    grafico
                                                </li>
                                                <li>
                                                    Non applicabile alla situazione del minore = Escluso dal grafico
                                                </li>
                                            </div>
                                        </div>
                                    }
                                >
                                    <IconButton>
                                        <Info fontSize='small' />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Card>
                                <CardContent>
                                    {options && (
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={options}
                                            ref={chartComponentRef}
                                        />
                                    )}
                                </CardContent>
                            </Card>
                        </div>
                        {
                            <div>
                                <div className='section-title'>Performance scolastica</div>
                                <div className='competenze-educative__charts_average'>
                                    {
                                        <Card sx={{width: '100%'}}>
                                            <CardContent>
                                                <div className='competenze-educative__charts_average_title'>
                                                    Giorni Assenza ultimo a.s.
                                                </div>
                                                <div className='competenze-educative__charts_average_value'>
                                                    {giorniAssenza}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    }
                                    {
                                        <Card sx={{width: '100%'}}>
                                            <CardContent>
                                                <div className='competenze-educative__charts_average_title'>
                                                    Giorni di presenza alle attività della Fondazione - ultimo a.s
                                                </div>
                                                <div className='competenze-educative__charts_average_value'>
                                                    {giorniPresenza}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    }

                                    {
                                        <Card sx={{width: '100%'}}>
                                            <CardContent>
                                                <div className='competenze-educative__charts_average_title'>
                                                    Voto medio
                                                </div>
                                                <div className='competenze-educative__charts_average_value'>
                                                    {votoMedio}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </>
            )}
        </div>
    );
};

export default CompetenzeEducative;
