import {PlotColumnDataLabelsOptions} from 'highcharts';

export const percentageFormatter: (data: any[]) => PlotColumnDataLabelsOptions['formatter'] = (data: any[]) => {
    return function () {
        const denominator = data.map((el) => el.y).reduce((a: number, b: number) => a + b);

        if (denominator === 0) {
            return '0%';
        }

        const p = ((this.y ?? 0) / denominator) * 100;

        return p.toFixed(1) + '%';
    };
};
