import * as React from 'react';
import _ from 'lodash';
import {Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import apiCall from '../../../utils/api';
import getValidSubmissions from '../helpers/getValidSubmissions';
import Loader from '../../../shared/Loader';
import ScaledBusiness from './ScaledBusiness';
import Employees from './Employees';
import Turnover from './Turnover';
import Ebitda from './EBITDA';
import Import from './Import';
import Export from './Export';
import ImportExportMap from './ImportExportMap';
import Branches from './Branches';
import SuccessfullyStart from './SuccessfullyStart';
import BusinessSector from './BusinessSector';
import BusinessChange from './BusinessChange';
import ContributionToResult from './ContributionToResult';
import SuccessFactor from './SuccessFactor';
import FoodProcessingSector from './FoodProcessingSector';
import AgrifoodSector from './AgrifoodSector';
import NewBusinessRegistration from './NewBusinessRegistration';
import ContributionToResultBiz from './ContributionToResultBiz';
import FailureFactors from './FailureFactors';
import TeamComposition from './TeamComposition';
import ContributionToResultScale from './ContributionToResultScale';

interface ILongTermProps {
    final: any[];
    baseline: any[];
}

const LongTerm = ({final, baseline}: ILongTermProps) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [countries, setCountries] = useState<{text: string; value: string}[]>([]);

    const getCountries = async () => {
        const requests = [
            await apiCall({
                url: `choices/5`,
            }),
        ];
        const responses = await Promise.all(requests);

        if (responses.length > 0) {
            setCountries(responses[0].data);
        }
    };

    const {filteredBaseline, filteredFinal} = getValidSubmissions(baseline, final);

    useEffect(() => {
        getCountries().then((_) => setIsLoaded(true));
    }, []);

    return (
        <>
            {isLoaded && (
                <>
                    <Typography variant='h5'>
                        ENTREPRENEURS WITH AN INITIAL BUSINESS IDEA WHO STARTED A NEW BUSINESS
                    </Typography>
                    <br />
                    <Typography variant='h6'>Startup of new businesses</Typography>
                    <br />
                    <SuccessfullyStart
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <BusinessChange final={filteredFinal} />
                    <br />
                    <div className='row'>
                        <div>
                            <ContributionToResult final={filteredFinal} />
                        </div>
                        <div>
                            <SuccessFactor final={filteredFinal} />
                        </div>
                    </div>
                    <br />
                    <BusinessSector final={filteredFinal} />
                    <br />
                    <AgrifoodSector submissions={filteredFinal} />
                    <br />
                    <FoodProcessingSector submissions={filteredFinal} />
                    <br />
                    <NewBusinessRegistration submissions={filteredFinal} />
                    <br />
                    <Typography variant='h5'>ENTREPRENEURS WITH AN ETABLISHED BUSINESS WHO SCALED IT</Typography>
                    <Typography variant='h6'>Scale up of established businesess</Typography>
                    <br />
                    <ScaledBusiness
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <div className='row'>
                        <div>
                            <ContributionToResultBiz final={filteredFinal} />
                        </div>
                        <div>
                            <SuccessFactor final={filteredFinal} />
                        </div>
                    </div>
                    <br />
                    <FailureFactors final={filteredFinal} />
                    <br />
                    <Typography variant='h6'>Increased Employees</Typography>
                    <br />
                    <Employees
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <TeamComposition final={filteredFinal} />
                    <br />
                    <Typography variant='h5'>Increased Turnover</Typography>
                    <br />
                    <Turnover
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <Typography variant='h5'>Increased EBITDA</Typography>
                    <br />
                    <Ebitda
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <Typography variant='h5'>Access to international markets</Typography>
                    <br />
                    <Import
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <Export
                        baseline={filteredBaseline}
                        final={filteredFinal}
                    />
                    <br />
                    <div className='row'>
                        <ImportExportMap
                            final={filteredFinal}
                            countries={countries}
                        />
                    </div>
                    <br />
                    <Typography variant='h5'>New branches opening</Typography>
                    <br />
                    <Branches final={filteredFinal} />
                    <br />
                    <ContributionToResultScale final={filteredFinal} />
                </>
            )}
            {!isLoaded && (
                <div className='loader-container'>
                    <Loader size={60} />
                </div>
            )}
        </>
    );
};

export default LongTerm;
