import ChoicesBar from "../bar/ChoicesBar";
import Bar from "../bar/Bar";

interface SchedaEnteProps {
    submissions: [],
    elements: {
        name: string,
        choices: [],
    }[]
}

const SchedaEnte = ({submissions, elements}: SchedaEnteProps) => {
    return (
        <div className="container-row">
            <div>
                <div className="section-title">
                    Ambito di Attività
                </div>
                <ChoicesBar data={submissions} name="ambito_attività" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    L'organizzazione ha già ricevuto dei contributi da Fondazione Unipolis?
                </div>
                <Bar data={submissions} name="supporto_unipolis" elements={elements}/>
            </div>
        </div>
    );
};

export default SchedaEnte;
