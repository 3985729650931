import ChoicesBar from "../bar/ChoicesBar";
import Bar from "../bar/Bar";
import Number from "../number";
import RowsBar from "../bar/RowsBar";
import Pie from "../pie";
import Avg from "../number/Avg";

interface SchedaEnteProps {
    submissions: [],
    elements: {
        name: string,
        choices: [],
        rows: [],
    }[]
}

const SchedaRendicontazione = ({submissions, elements}: SchedaEnteProps) => {
    return (
        <div className="container-row">
            <div>
                <div className="section-title">
                    Gli obiettivi del progetto sono stati raggiunti in quale percentuale?
                </div>
                <Bar data={submissions} name="%_obiettivi" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Dimensione di intervento
                </div>
                <ChoicesBar data={submissions} name="dimensione_intervento" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Totale Lavoratori - Volontari
                </div>
                <Number data={submissions} name="altre_risorse_volontari_numero"/>
            </div>
            <div>
                <div className="section-title">
                    Numero totale destinatari rilevati
                </div>
                <Number data={submissions} name="num_destinatari"/>
            </div>

            <div>
                <div className="section-title">
                    Destinatari - Fascia Anagrafica
                </div>
                <RowsBar data={submissions} name="fascia_anagrafica" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Destinatari - Livello di studio
                </div>
                <RowsBar data={submissions} name="livello_studio" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Destinatari - Genere
                </div>
                <RowsBar data={submissions} name="Genere" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Destinatari - Categorie specifiche
                </div>
                <RowsBar data={submissions} name="categorie_specifiche" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Destinatari - Provenienza geografica
                </div>
                <RowsBar data={submissions} name="prov_geografica" elements={elements}/>
            </div>

            <div>
                <div className="section-title">
                    Numero totale alleati coinvolti
                </div>
                <Number data={submissions} name="tot_alleati" />
            </div>
            <div className="col-span-2">
                <div className="section-title">
                    ALLINEAMENTO FONDAZIONE UNIPOLIS
                </div>
                <div className="container-row gap-4">
                    <div className="section-title py-0">
                        BENESSERE: Quali di questi obiettivi ha intercettato l'iniziativa?
                    </div>

                    <div className="section-title py-0">
                        CULTURA: Quali di questi obiettivi ha intercettato l'iniziativa?
                    </div>

                    <div>
                        <Bar data={submissions} name="BENESSERE" elements={elements}/>
                    </div>

                    <div>
                        <Bar data={submissions} name="CULTURA" elements={elements}/>
                    </div>

                    <div className="section-title py-0">
                        MOBILITÀ: Quali di questi obiettivi ha intercettato l'iniziativa?
                    </div>

                    <div className="section-title py-0">
                        Qual è stato il contributo dell’iniziativa agli Obiettivi di sviluppo sostenibile dell'Agenda 2030 ONU?
                    </div>

                    <div>
                        <Bar data={submissions} name="MOBILITà" elements={elements}/>
                    </div>

                    <div>
                        <Bar data={submissions} name="CONTRIBUTO_SDG" elements={elements}/>
                    </div>
                </div>
            </div>

            <div className="col-span-2">
                <div className="section-title">
                    NUMERI DELL'INIZIATIVA
                </div>
                <div className="container-row gap-4">
                    <div className="section-title py-0">
                        Attività di sensibilizzazione delle comunità sui temi oggetto dell’azione
                    </div>

                    <div className="section-title py-0">
                        Temi affrontati
                    </div>

                    <div>
                        <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="sensibilizzare" elements={elements}/>
                    </div>

                    <div>
                        <Bar data={submissions} name="question3" elements={elements}/>
                    </div>

                    <div className="section-title py-0">
                        L’iniziativa ha previsto l’elaborazione di un report, di una ricerca o di un’indagine?
                    </div>

                    <div className="section-title py-0">
                        Dettagli report
                    </div>

                    <div>
                        <Pie data={submissions} name="elaborazione_report" elements={elements}/>
                    </div>

                    <div>
                        <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="dettagli_report" elements={elements}/>
                    </div>
                </div>
            </div>

            <div className="col-span-2">
                <div className="section-title">
                    SENSIBILIZZAZIONE
                </div>
                <div className="container-row gap-4">
                    <div className="section-title py-0">
                        È stata riscontrata l'efficacia in termini di acquisizione consapevolezza sui temi grazie alle attività di sensibilizzazione?
                    </div>

                    <div className="section-title py-0">
                        e con che risultati?
                    </div>

                    <div>
                        <Pie data={submissions} name="efficacia_sensibilizzazione" elements={elements}/>
                    </div>

                    <div>
                        <Avg data={submissions} name="risultati_sensibilizzazione" elements={elements}/>
                    </div>
                </div>
            </div>

            <div className="col-span-2">
                <div className="section-title">
                    FORMARE
                </div>
                <div className="container-row gap-4">
                    <div className="section-title py-0">
                        Attività di formazione svolte in presenza o in digitale per lo sviluppo di competenze
                    </div>

                    <div className="section-title py-0">
                        Strumenti di formazione innovativi
                    </div>

                    <div>
                        <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="ATT_formazione" elements={elements}/>
                    </div>

                    <div>
                        <Pie data={submissions} name="strumenti_formazione_innovativi" elements={elements}/>
                    </div>

                    <div className="section-title py-0">
                        Strumenti di valutazione dei percorsi formativi
                    </div>

                    <div className="section-title py-0">
                        Dettagli strumenti di valutazione dei percorsi formativi
                    </div>

                    <div>
                        <Pie data={submissions} name="strumenti_valutazione_formazione" elements={elements}/>
                    </div>

                    <div>
                        <Avg data={submissions} name="dettagli_strumenti_valutazione_formazione" elements={elements}/>
                    </div>
                </div>
            </div>
            <div className="col-span-2">
                <div className="section-title">
                    COLLABORARE
                </div>
                <div className="section-title">
                    Attività di advocacy, di co-progettazione e di co-creazione
                </div>

                <div>
                    <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="advocacy_progettazione_" elements={elements}/>
                </div>
            </div>
            <div className="col-span-2">
                <div className="section-title">
                    CONNETTERE
                </div>
                <div className="section-title">
                    Attività per lo sviluppo delle reti e delle relazioni
                </div>

                <div>
                    <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="reti_relazioni" elements={elements}/>
                </div>
            </div>

            <div className="col-span-2">
                <div className="section-title">
                    MISURARE
                </div>
                <div className="container-row gap-4">
                    <div className="section-title py-0">
                        Attività di monitoraggio e valutazione dell'impatto previsto
                    </div>

                    <div className="section-title py-0">
                        Attività di comunicazione e storytelling delle attività e dei risultati
                    </div>

                    <div>
                        <Pie data={submissions} name="mis_impatto_prodotto" elements={elements}/>
                    </div>

                    <div>
                        <RowsBar seriesName='Count' slugKey='_numero' data={submissions} name="Com_storytelling" elements={elements}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchedaRendicontazione;
