import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {COLORS, EXPORTING} from '../../../../utils/constants';
import {ISubmissionsUoaData} from '../../utils/data';
import {previsionaleCheckpoint} from '../../index';
import makeAverage from '../../../../utils/makeAverage';

const WeakReadersChart = ({iniziativaSubmissions, beneficiarySubmissions, uoaParentId}: ISubmissionsUoaData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const answers: any = {};

        iniziativaSubmissions
            .filter((submission) => submission.checkpoint_id === previsionaleCheckpoint)
            .forEach((submission) => {
                let sum = 0
                const submissions = beneficiarySubmissions.filter((el) => el.uoa_id === submission.uoa_id)

                if (submissions.length > 0) {
                    if (!answers.hasOwnProperty(submission.uoa_parent_id)) {
                        answers[submission.uoa_parent_id] = [];
                    }

                    submissions.forEach((el) => {
                        sum = sum + ((el.a1 === '1' && el.a2 <= 3) ? 1 : 0)
                    })

                    answers[submission.uoa_parent_id].push((sum / submissions.length))
                }
            })

        const data = makeAverage(Object.values(answers).map((answer: any) => makeAverage(answer, 2) * 100), 0)
        const dataOwn = makeAverage(answers[uoaParentId] || [], 2) * 100

        const dashboardOptions: any = {
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: '',
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                }

            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.1f}%'
                    }
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                headerFormat: '',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b>'
            },
            series: [
                {
                    colorByPoint: true,
                    data: [
                        {
                            name: 'Percentuale di lettore deboli',
                            y: dataOwn,
                            color: COLORS.green
                        },
                        {
                            name: 'Percentuale media di lettori deboli tra i progetti',
                            y: data,
                            color: COLORS.red
                        },
                    ]
                }
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default WeakReadersChart;
