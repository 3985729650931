import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import React, {useEffect, useState} from 'react';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import Tasso from './Tasso';
import AgeGroups from './AgeGroups';
import Autonomy from './Autonomy';
import ProjectSameObjective from './ProjectSameObjective';
import ActorSameObjective from './ActorSameObjective';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const WIAChild = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const actual_id = searchParams.get('actual_id');
    const previsioned_id = searchParams.get('previsioned_id');
    const uoa_id = searchParams.get('uoa_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_PARENT || '');

    const [loading, setLoading] = useState(false);
    const [beneficiaryActualSubmissions, setBeneficiaryActualSubmissions] = useState([]);
    const [beneficiarySubmissions, setBeneficiarySubmissions] = useState([]);

    const getSurvey = async () => {
        setUserToken(searchParams.get('token') || '')

        try {
            if (actual_id && previsioned_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: actual_id,
                        }
                    }),
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id: previsioned_id,
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setBeneficiaryActualSubmissions(responses[0].data.submissions);
                setBeneficiarySubmissions(responses[1].data.submissions);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className="attentamente-parent">
            {
                !loading && (
                    <>
                        {
                            (beneficiarySubmissions && beneficiaryActualSubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>TASSO DI RAGGIUNGIMENTO DEI BENEFICIARI PER LE ATTIVITA’ CHE HANNO LO STESSO
                                            OBIETTIVO
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico mostra il tasso di raggiungimento dei beneficiari attesi per la
                                                vostra attività a confronto con la media dello stesso valore per tutte le
                                                attività che, nell’ambito di tutti i progetti, hanno lo stesso obiettivo. Il
                                                tasso di raggiungimento dei beneficiari attesi è ottenuto dal rapporto tra i
                                                beneficiari che si prevedeva di raggiungere secondo la scheda previsionale
                                                dell’attività oggetto di analisi e i beneficiari effettivamente raggiunti
                                                secondo la più recente scheda aggiornamento delle attività rivolte ai
                                                beneficiari.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <Tasso
                                        submissions={beneficiarySubmissions}
                                        actualSubmissions={beneficiaryActualSubmissions}
                                        uoa={parseInt(uoa_id)}
                                    />
                                </div>
                            )
                        }
                        {
                            (beneficiarySubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>COMPOSIZIONE BENEFICIARI ATTESI PER LE ATTIVITA’ CHE HANNO LO STESSO OBIETTIVO –
                                            PERSONE ANZIANE
                                        </div>
                                        <Tooltip
                                            title={<>
                                                Il grafico descrive i beneficiari anziani attesi per la vostra attività a
                                                confronto con la media delle attività che hanno la stessa finalità. Sono
                                                presentati un grafico con la composizione dei beneficiari per fasce d’età, e un
                                                grafico con la composizione dei beneficiari per livello di autonomia.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <div className="attentamente-parent">
                                        <AgeGroups
                                            submissions={beneficiarySubmissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                        <Autonomy
                                            submissions={beneficiarySubmissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            (beneficiarySubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>
                                            COMPOSIZIONE DELL’ÉQUIPE DI PROGETTO PER LE ATTIVITA’ CHE HANNO LO STESSO OBIETTIVO
                                        </div>
                                        <Tooltip
                                            title={<>
                                                La tabella presenta le tipologie di figure professionali coinvolte
                                                nell’attività, a confronto con le altre attività che hanno la stessa finalità,
                                                in tutti i progetti.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <ProjectSameObjective
                                        submissions={beneficiarySubmissions}
                                        uoa={parseInt(uoa_id)}
                                    />

                                </div>
                            )
                        }
                        {
                            (beneficiarySubmissions && uoa_id) && (
                                <div className="w-full">
                                    <div className="section-title">
                                        <div>
                                            ATTORI CHE CONTRIBUISCONO RISORSE FINANZIARIE PER LE ATTIVITÀ CHE HANNO LO STESSO
                                            OBIETTIVO
                                        </div>
                                        <Tooltip
                                            title={<>
                                                La tabella presenta le tipologie di attori che contribuiscono finanziariamente
                                                all’attività, a confronto con le altre attività che hanno la stessa finalità, in
                                                tutti i progetti.
                                            </>}
                                        >
                                            <IconButton>
                                                <Info fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>

                                    <ActorSameObjective
                                        submissions={beneficiarySubmissions}
                                        uoa={parseInt(uoa_id)}
                                    />

                                </div>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default WIAChild;
