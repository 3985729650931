import * as React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';
import EnrolledItem from '../../base/EnrolledItem';

interface IBranchesProps {
    final: any[];
}

const Branches = ({final}: IBranchesProps) => {
    let count = 0;
    let value = '0';
    let average = '0';
    let branchesNum = 0;

    final.forEach((submission: any) => {
        if (submission.branches_t1 === 'Yes') {
            count++;
        }

        branchesNum += submission.branches_num_t1;
    });

    if (final.length > 0) {
        value = ((count / final.length) * 100).toFixed(1);
    }

    if (count > 0) {
        average = (branchesNum / count).toFixed(1);
    }

    return (
        <Card className='chart-container'>
            <CardContent>
                <div className='enrolled-item space-x-4'>
                    <EnrolledItem
                        text='% of entrepreneurs who opened new branches'
                        value={value + '%'}
                    />
                </div>
                <div className='enrolled-item space-x-4'>
                    <EnrolledItem
                        text='Average number of new branches'
                        value={average}
                    />
                </div>
            </CardContent>
        </Card>
    );
};

export default Branches;
