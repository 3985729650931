import ImprovementsChart from './ImprovementsChart';

interface IEntrepreneursIncreaseProps {
    baseline: any[];
    final: any[];
}

const EntrepreneursIncrease = ({baseline, final}: IEntrepreneursIncreaseProps) => {
    return (
        <ImprovementsChart
            baseline={baseline}
            final={final}
        />
    );
};

export default EntrepreneursIncrease;
