import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import { percentageFormatter } from '../../helpers/formatters';

interface IBusinessProfileProps {
    final: any[];
}

const transform = [
    {value: 1, name: '1'},
    {value: 2, name: '2'},
    {value: 3, name: '3'},
    {value: 4, name: '4'},
    {value: 5, name: '5'},
    {value: 6, name: '6'},
    {value: 7, name: 'Very High'},
];

const BusinessModelImproved = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const data = transform.map((el) => ({
        name: el.name,
        y: final.filter((submission: any) => submission.biz_mod_improved_t1 === el.value).length,
    }));

    const calculateSubmissions = () => {
        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                align: 'left',
                text: 'Level of improvement of the business model during the program',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: transform.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data,
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default BusinessModelImproved;
