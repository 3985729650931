import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import {COLORS, EXPORTING} from '../../../utils/constants';
import makeRatio from "../../../utils/makeRatio";

interface IAgeGroupsData {
    submissions: any[];
    uoa: string | number
}

interface IGroupData {
    '65_75': number;
    '75_85': number;
    'over_85': number;
}

const AgeGroups = ({submissions, uoa}: IAgeGroupsData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const allSubmissions: IGroupData = {
            '65_75': 0,
            '75_85': 0,
            'over_85': 0,
        }
        const currentSubmissions: IGroupData = {
            '65_75': 0,
            '75_85': 0,
            'over_85': 0,
        }

        submissions.forEach((submission: any) => {
            allSubmissions['65_75'] += submission.question13_persone_anziane_di_eta_65_75_numero || 0;
            allSubmissions['75_85'] += submission.question13_persone_anziane_di_eta_75_85_numero || 0;
            allSubmissions['over_85'] += submission.question13_persone_anziane_over_85_numero || 0;

            if (submission.uoa_id === uoa) {
                currentSubmissions['65_75'] += submission.question13_persone_anziane_di_eta_65_75_numero || 0;
                currentSubmissions['75_85'] += submission.question13_persone_anziane_di_eta_75_85_numero || 0;
                currentSubmissions['over_85'] += submission.question13_persone_anziane_over_85_numero || 0;
            }
        })

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'COMPOSIZIONE BENEFICIARI ATTESI PER LE ATTIVITA’ CHE HANNO LO STESSO OBIETTIVO - fasce di età',
                align: 'left'
            },
            xAxis: {
                categories: ['Il mio progetto', 'Media attività con stessa finalità in tutti i progetti']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: false,
                },
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'percent',
                }
            },
            series: [
                {
                    name: '% di beneficiari anziani attesi over 85',
                    color: COLORS.yellow,
                    data: [
                        makeRatio(currentSubmissions.over_85, Object.values(currentSubmissions)),
                        makeRatio(allSubmissions.over_85, Object.values(allSubmissions))
                    ]
                },
                {
                    name: '% di beneficiari anziani attesi in età 75-85',
                    color: COLORS.red,
                    data: [
                        makeRatio(currentSubmissions['75_85'], Object.values(currentSubmissions)),
                        makeRatio(allSubmissions['75_85'], Object.values(allSubmissions))
                    ]
                },
                {
                    name: '% di beneficiari anziani attesi in età 65-75',
                    color: COLORS.blue,
                    data: [
                        makeRatio(currentSubmissions['65_75'], Object.values(currentSubmissions)),
                        makeRatio(allSubmissions['65_75'], Object.values(allSubmissions))
                    ]
                }
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default AgeGroups;
