import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IAlreadySoldChartProps {
    baseline: any[];
}

const AlreadySoldChart = ({baseline}: IAlreadySoldChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let values = baseline.map((submission) => submission.product_sold_t0);
        values = values.filter((value) => value !== null && value !== '');

        let data: any = [];

        values.forEach((value: any) => {
            const index = data.findIndex((item: any) => item.name === value);

            if (index === -1) {
                data.push({name: value, y: 1});
            } else {
                data[index].y += 1;
            }
        });

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));

        const dashboardOptions: any = getDefaultPieFormatting('Product sold in the market at the beginning', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default AlreadySoldChart;
