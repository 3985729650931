import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';
import { getDefaultPieFormatting } from '../../charts/pie';

interface IIncreasedCustomerNetworkProps {
    final: any[];
}

const IncreasedCustomerNetwork = ({final}: IIncreasedCustomerNetworkProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const success = final.filter((submission: any) => submission.netw_growth_t1 === 'Yes').length;
        const unSuccess = final.filter((submission: any) => submission.netw_growth_t1 === 'No').length;

        const title = 'Business network increased';
        const data = [
            {
                name: 'Yes',
                y: success,
                color: COLORS.blue,
            },
            {
                name: 'No',
                y: unSuccess,
                color: COLORS.yellow,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(title, data, 1, {
            subtitle: {
                text: '% of entrepreneurs that increased the customer network',
                align: 'left',
            },
        });
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default IncreasedCustomerNetwork;
