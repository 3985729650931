import * as Highcharts from 'highcharts';
import React, {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import isNumeric from '../../../utils/isNumeric';
import {IChartProps, IUoaValue} from '../utils/models';
import makeAverageFromAverage from '../utils/makeAverageFromAverage';
import {COLORS} from '../../../utils/constants';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Info from "@mui/icons-material/Info";

const partecipazioneFamigliariTransformations: any = {
    Avanzato: 0,
    Intermedio: 1.7,
    Elemento1: 3.3,
    Elemento2: 5
};

const frequenzaPartecipazioneTransformations: any = {
    Avanzato: 0,
    Intermedio: 1.7,
    Elemento1: 3.3,
    Elemento2: 5
};

const RelazioniSociali = ({submissions, uoas, selectedUoa, checkpoints}: IChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const processSubmission = (submission: any, line: IUoaValue[][]) => {
        const q11 = +submission.q11?.slice(8);

        if (isNumeric(q11)) {
            line[0].push({uoa: submission.uoa_id, value: q11 / 2});
        }

        const partecipazioneFamigliari = partecipazioneFamigliariTransformations.hasOwnProperty(submission?.q9) ? partecipazioneFamigliariTransformations[submission.q9] : 0
        line[1].push({uoa: submission.uoa_id, value: partecipazioneFamigliari});

        const frequenzaPartecipazione = frequenzaPartecipazioneTransformations.hasOwnProperty(submission?.question8) ? frequenzaPartecipazioneTransformations[submission.question8] : 0
        line[2].push({uoa: submission.uoa_id, value: frequenzaPartecipazione});
    };

    const calculateSubmissions = (submissionsArray: any[]) => {
        const lines: Array<IUoaValue[][]> = checkpoints.map(() => ([[], [], []]));

        submissionsArray.forEach((submission: any) => {
            if (selectedUoa === 'All' || selectedUoa === submission.uoa_id) {
                const checkpointIndex = checkpoints.findIndex((checkpoint: any) => checkpoint.id === submission.checkpoint_id);

                if (checkpointIndex !== -1) {
                    processSubmission(submission, lines[checkpointIndex]);
                }
            }
        });

        const series: any[] = checkpoints.map((checkpoint: any, i: number) => ({
            type: 'line',
            name: checkpoint.name,
            color: Object.values(COLORS)[i] || '',
            data: makeAverageFromAverage(lines[i], selectedUoa, uoas),
        }));

        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: ['Livello inclusione sociale', 'Partecipazione famigliari', 'Partecipazione opportunità culturali']
            },
            yAxis: {
                min: 0,
                max: 6,
                labels: {
                    format: '{value:.2f}'
                },
            },
            series
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions(submissions);
    }, [selectedUoa]);

    return (
        <div className="relazioni-sociali">
            <div className="section-title">
                Relazioni sociali
                <Tooltip
                    sx={{
                        marginLeft: '0.5rem'
                    }}
                    title={<div className="space-y-4">
                        <div className="px-4">
                            <div>Livello inclusione sociale</div>
                            <li>10 = 5.0</li>
                            <li>9 = 4.5</li>
                            <li>8 = 4</li>
                            <li>7 = 3.5</li>
                            <li>6 = 3</li>
                            <li>5 = 2.5</li>
                            <li>4 = 2</li>
                            <li>3 = 1.5</li>
                            <li>2 = 1</li>
                            <li>1 = 0.5</li>
                        </div>

                        <div className="px-4">
                            <div>Partecipazione famigliari</div>
                            <li>Mai = 0</li>
                            <li>1-2 volte all'anno = 1.7</li>
                            <li>Continuativamente nel corso dei mesi con una partecipazione/presenza fisica alle
                                attività = 3.3
                            </li>
                            <li>Continuativamente nel corso dei mesi in modo proattivo ovvero aggiungendo alla presenza
                                fisica anche un ulteriore interesse e impegno per la crescita e inclusione del/della
                                minore ad esempio attraverso la cooperazione con la scuola e la Fondazione Alessandro
                                Pavesi = 5.0
                            </li>
                        </div>

                        <div className="px-4">
                            <div>Frequenza di partecipazione</div>
                            <li>Mai = 0</li>
                            <li>1-2 volte all'anno = 1.7</li>
                            <li>Continuativamente nel corso dei mesi con una partecipazione/presenza fisica alle
                                attività = 3.3
                            </li>
                            <li>Continuativamente nel corso dei mesi in modo proattivo ovvero aggiungendo alla presenza
                                fisica anche un ulteriore interesse e impegno per la crescita e inclusione del/della
                                minore ad esempio attraverso la cooperazione con la scuola e la Fondazione Alessandro
                                Pavesi = 5.0
                            </li>
                        </div>
                    </div>}
                >
                    <IconButton>
                        <Info fontSize="small"/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                options && (
                    <Card>
                        <CardContent>
                            {
                                options && (
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                        ref={chartComponentRef}
                                    />
                                )
                            }
                        </CardContent>
                    </Card>
                )
            }
        </div>
    );
};

export default RelazioniSociali;
