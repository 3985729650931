import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

interface IBusinessProfileProps {
    baseline: any[],
    final: any[],
}

const VALUES = [
    'BIZ>2Y_T0',
    'BIZ<2Y_T0',
]

const StartedSell = ({baseline, final}: IBusinessProfileProps) => {
    let value = 0;
    const newSales = final.filter((submission: any) => submission.biz_product_sold_t1 === 'Yes').length

    if (newSales > 0) {
        value = baseline.filter((submission: any) => submission.product_sold_t0 === 'No' && VALUES.includes(submission.career_status_t0)).length / newSales
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 58,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        {value * 100}
                    </Box>
                    <Typography>
                        % of existing entrepreneurs that started to sell their product on the market
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default StartedSell;
