import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more'
import {sha256} from 'js-sha256';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from "../../shared/Loader";
import SchedaEnte from "./SchedaEnte";
import SchedaIniziativa from "./SchedaIniziativa";
import SchedaRendicontazione from "./SchedaRendicontazione";

import './styles.scss';

HighchartsMore(Highcharts);

const Unipolis = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const surveyEnte = searchParams.get('survey_ente');
    const surveyIniziativa = searchParams.get('survey_iniziativa');
    const surveyRendicontazione = searchParams.get('survey_rendicontazione');
    const hashKey = searchParams.get('key');

    setUserToken( searchParams.get('token') || '')

    const [enteElements, setEnteElements] = useState<[]>([]);
    const [submissionsEnte, setSubmissionsEnte] = useState<[]>([]);
    const [iniziativaElements, setIniziativaElements] = useState<[]>([]);
    const [submissionsIniziativa, setSubmissionsIniziativa] = useState<[]>([]);
    const [rendicontazioneElements, setRendicontazioneElements] = useState<[]>([]);
    const [submissionsRendicontazione, setSubmissionsRendicontazione] = useState<[]>([]);
    const [loading, setLoading] = useState(true);

    const fetchSubmissions = async (survey_id: string) => {
        const requests = apiCall({
            url: `containers/${container}/submissions`,
            params: {
                survey_id,
                additional_data: false,
            }
        })

        const responses = await Promise.all([requests]);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
            return responses[0].data.submissions
        }

        return [];
    }

    const fetchSurvey = async (survey_id: string) => {
        const requests = apiCall({
            url: `containers/${container}/surveys/${survey_id}`,
        })

        const responses = await Promise.all([requests]);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('survey')) {
            return responses[0].data.survey.json.pages.map((page: { elements: any }) => page.elements).flat(1)
        }

        return [];
    }

    const getSurvey = async () => {
        const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_UNIPOLIS || '');

        try {
            if (surveyEnte && surveyIniziativa && surveyRendicontazione && hashKey === hashKeyLocal) {
                setEnteElements(await fetchSurvey(surveyEnte))
                setSubmissionsEnte(await fetchSubmissions(surveyEnte))
                setIniziativaElements(await fetchSurvey(surveyIniziativa))
                setSubmissionsIniziativa(await fetchSubmissions(surveyIniziativa))
                setRendicontazioneElements(await fetchSurvey(surveyRendicontazione))
                setSubmissionsRendicontazione(await fetchSubmissions(surveyRendicontazione))
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className="container space-y-8">
            {
                !loading && (
                    <>
                        {
                            submissionsEnte.length > 0 && (
                                <div>
                                    <div className="section-title">
                                        SCHEDA ENTE
                                    </div>
                                    <SchedaEnte submissions={submissionsEnte} elements={enteElements}/>
                                </div>
                            )
                        }
                        {
                            submissionsIniziativa.length > 0 && (
                                <div>
                                    <div className="section-title">
                                        SCHEDA INIZIATIVA
                                    </div>
                                    <SchedaIniziativa submissions={submissionsIniziativa} elements={iniziativaElements}/>
                                </div>
                            )
                        }
                        {
                            submissionsRendicontazione.length > 0 && (
                                <div>
                                    <div className="section-title">
                                        SCHEDA RENDICONTAZIONE
                                    </div>
                                    <SchedaRendicontazione submissions={submissionsRendicontazione}
                                                           elements={rendicontazioneElements}/>
                                </div>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default Unipolis;
