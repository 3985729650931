import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IBusinessProfileProps {
    final: any[],
}

const CHOICES = [
    "Accounting",
    "Agrifood",
    "Automotive",
    "Art and Culture",
    "Business Services",
    "Consulting",
    "Cosmetics",
    "Education",
    "Finance",
    "Energy",
    "Fashion and Textile",
    "Food Processing",
    "Health",
    "Housing and Construction",
    "ICT",
    "Infrastructure",
    "Leisure",
    "Manufacturing and consumer goods",
    "Mechanical engeneering",
    "Media, PR, digital comunication and marketing",
    "Mining",
    "Oil and gas",
    "Pharmaceuticals",
    "Personal services",
    "Public Administration",
    "Real Estate",
    "Renewable Energy",
    "Tourism and Hospitality",
    "Waste Management ",
    "Water Management",
    "Wholesale and retail trade"
]

const BusinessSector = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data = CHOICES.map((el) => ({
            name: el,
            y: 0,
        }));

        final.forEach((submission: any) => {
            CHOICES.forEach((el, index) => {
                if (submission.sector_t1 && submission.sector_t1.indexOf(el) > -1) {
                    data[index].y = data[index].y + 1
                }
            })
        })

        const dashboardOptions: any = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: 'Business sector:',
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                colorByPoint: true,
                data
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default BusinessSector;
