import {useEffect, useState} from 'react';
import {IFoodBanksProps} from "../../utils/models";
import PieChart from "../../PieChart";

const SourceFoodIn = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let collected = 0, donatedDistribution = 0, donatedManufacturing = 0, national = 0;
        let foodAidEU = 0, foodAidNational = 0, surplusDistribution = 0, surplusManufacturing = 0, other = 0;

        submissions.forEach((submission) => {
            collected = collected + (Number(submission.collected_food) || 0)
            donatedDistribution = donatedDistribution + (Number(submission.donated_food_from_distribution_sector) || 0)
            donatedManufacturing = donatedManufacturing + (Number(submission.donated_food_from_manufacturing_sector) || 0)
            national = national + (Number(submission.eu_national_withdrals_fruits_vegetables) || 0)
            foodAidEU = foodAidEU + (Number(submission.food_aid_eu_fead) || 0)
            foodAidNational = foodAidNational + (Number(submission.food_aid_national_programme) || 0)
            surplusDistribution = surplusDistribution + (Number(submission.surplus_food_from_distribution_sector) || 0)
            surplusManufacturing = surplusManufacturing + (Number(submission.surplus_food_from_manufacturing_sector) || 0)
            other = other + (
                (Number(submission.other_sources) || 0)
                + (Number(submission.donated_food_from_horeca_and_catering_sector) || 0)
                + (Number(submission.surplus_food_from_horeca_and_catering_sector) || 0)
                + (Number(submission.food_aid_eu_react_eu) || 0)
                + (Number(submission.food_from_other_food_banks) || 0)
                + (Number(submission.purchased_food) || 0)
            )
        })

        setSeries([{
            name: 'Sources',
            size: "100%",
            innerSize: '50%',
            data: [
                {
                    name: 'Collected food',
                    y: collected,
                    color: '#509ee3'
                },
                {
                    name: 'Donated food from distribution sector',
                    y: donatedDistribution,
                    color: '#227fd2'
                },
                {
                    name: 'Donated food from manufacturing sector',
                    y: donatedManufacturing,
                    color: '#689636'
                },
                {
                    name: 'EU & national withdrals (fruits & vegetables)',
                    y: national,
                    color: '#a989c5'
                },
                {
                    name: 'Food aid - EU FEAD',
                    y: foodAidEU,
                    color: '#8a5eb0'
                },
                {
                    name: 'Food aid - National programme',
                    y: foodAidNational,
                    color: '#e75454'
                },
                {
                    name: 'Surplus food from distribution sector',
                    y: surplusDistribution,
                    color: '#ed8535'
                },
                {
                    name: 'Surplus food from manufacturing sector',
                    y: surplusManufacturing,
                    color: '#69c8c8'
                },
                {
                    name: 'Other',
                    y: other,
                    color: '#b8bbc3'
                },
            ],
        }]);
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series && (
                    <PieChart
                        title="Sources Total Food In"
                        fractionDigits={2}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default SourceFoodIn;