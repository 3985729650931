import * as React from "react";
import {useEffect, useState} from "react";
import {useSearchParams} from 'react-router-dom';
import Loader from "../../shared/Loader";
import {sha256} from "js-sha256";
import apiCall, {setUserToken} from "../../utils/api";
import Table from "./Table";
import NoResults from "../../shared/NoResults";

import './styles.scss';

const CdpAnagrafica = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const anagrafica = searchParams.get('anagrafica');
    const hashKey = searchParams.get('key');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_CDP || '');

    setUserToken( searchParams.get('token') || '')

    const [loading, setLoading] = useState(true);

    const [submissions, setSubmissions] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [meta, setMeta] = useState<{
        current_page: number,
        total: number,
        per_page: number,
    }>({
        current_page: 1,
        total: 0,
        per_page: 10,
    });

    const getSubmission = async (rowsPerPage: number, page: number): Promise<any[]> => {
        const requests = [
            await apiCall({
                url: `containers/${container}/submissions`,
                params: {
                    completed: 1,
                    per_page: rowsPerPage,
                    page: page,
                    survey_id: anagrafica,
                    additional_data: 'false',
                    pagination: 'true',
                }
            }),
        ];

        return await Promise.all(requests)
    }

    const getSurvey = async () => {
        try {
            setLoading(true);

            if (anagrafica && hashKey === hashKeyLocal) {
                const responses = await getSubmission(rowsPerPage, page);

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    const data = responses[0].data.submissions

                    setSubmissions(data);
                    setMeta(responses[0].data.meta);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAllSurveys = async () => {
        let submissions: any[] = []

        if (anagrafica && hashKey === hashKeyLocal) {
            let page = 1
            let meta = {
                last_page: 1,
            }

            do {
                const responses = await getSubmission(50, page);
                page++

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    submissions = [...submissions, ...responses[0].data.submissions]
                    meta = responses[0].data.meta;
                }

                if (page > meta.last_page) {
                    break
                }
            } while (true)
        }

        return submissions
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
    };

    return (
        <div className="container space-y-8">
            {
                !loading && (
                    <>
                        <div>
                            {
                                submissions.length > 0 && (
                                    <Table
                                        submissions={submissions}
                                        meta={meta}
                                        handleChangePage={handleChangePage}
                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                        getAllSurveys={getAllSurveys}
                                    />
                                )
                            }
                            {
                                submissions.length === 0 && (
                                    <NoResults/>
                                )
                            }
                        </div>
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default CdpAnagrafica;
