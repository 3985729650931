import {useEffect, useState} from 'react';
import {IFoodBanksProps} from "../../utils/models";
import PieChart from "../../PieChart";

const BeneficiariesPie = ({submissions}: IFoodBanksProps) => {
    const [series, setSeries] = useState<any[]>();

    const calculateSubmissions = () => {
        let over = 0, under = 0;

        submissions.forEach((submission) => {
            under = under + (Number(submission.number_of_end_beneficiaries_under_18) || 0)
            over = over + (Number(submission.number_of_end_beneficiaries_over_18) || 0)
        })

        setSeries([
            {
                name: 'Beneficiaries',
                size: "100%",
                innerSize: '50%',
                data: [
                    {
                        name: 'Number of end beneficiaries over 18',
                        y: over,
                        color: '#716fad'
                    },
                    {
                        name: 'Number of end beneficiaries under 18',
                        y: under,
                        color: '#f2a86f'
                    },
                ],
            }
            ]
        );
    };

    useEffect(() => {
        calculateSubmissions();
    }, [submissions]);

    return (
        <div>
            {
                series &&  (
                    <PieChart
                        title="Pie chart for beneficiaries over and under 18"
                        fractionDigits={2}
                        series={series}
                    />
                )
            }
        </div>
    );
}

export default BeneficiariesPie;