import * as React from 'react';
import Highcharts from "highcharts";
import {useEffect, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/custom/world.topo.json";
import countries from "./list.json";

interface IBusinessProfileProps {
    final: any[],
}

HighchartsMap(Highcharts);

const EXPORT_QUESTIONS = [
    'export_where_t1_0',
    'export_where_t1_1',
    'export_where_t1_2',
]
const IMPORT_QUESTIONS = [
    'import_where_t1_0',
    'import_where_t1_1',
    'import_where_t1_2',
]

const Map = ({final}: IBusinessProfileProps) => {
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let exportData: any[] = []
        let importData: any[] = []
        const getData = (submission: any, questions: string[]) => {
            const data: any[] = []
            questions.forEach((question) => {
                const answer = countries.find((country) => country.value === submission[question])

                if (answer) {
                    const index = data.findIndex((el: any) => el.key === answer.code)

                    if (index !== -1) {
                        data[index].value = data[index].value + 1
                    } else {
                        data.push({
                            'key': answer.code,
                            value: 1,
                            visible: true
                        })
                    }
                }
            })

            return data
        }

        final.forEach((submission: any) => {
            exportData = [
                ...exportData,
                ...getData(submission, EXPORT_QUESTIONS)
            ]

            importData = [
                ...importData,
                ...getData(submission, IMPORT_QUESTIONS)
            ]
        })

        const dashboardOptions: any = {
            chart: {
                map: mapData,
            },
            title: {
                text: null
            },
            colorAxis: {
                min: 0,
                stops: [
                    [0, '#EFEFFF'],
                    [0.5, '#2caffe'],
                    [1, 'rgb(0,48,127)']
                ]
            },
            accessibility: {
                series: {
                    descriptionFormat: '{series.name}, map with {series.points.length} areas.',
                    pointDescriptionEnabledThreshold: 50
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'bottom'
            },
            plotOptions: {
                map: {
                    joinBy: ['hc-a2', 'key'],
                }
            },
            series: [
                {
                    name: 'Export',
                    data: exportData
                },
                {
                    name: 'Import',
                    data: importData
                }
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={"mapChart"}
            />
        </>

    )
};

export default Map;
