export const dateString = (date: string) => {
    const [datePart, timePart] = date.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hour, minute, second] = timePart.split(':');

    return new Date(
        Number(year),
        (Number(month) - 1),
        Number(day),
        Number(hour),
        Number(minute),
        Number(second)
    );
}

export const addHours = (date: string, hours: number) => {
    const originalDate = dateString(date);
    originalDate.setHours(originalDate.getHours() + hours);

    return originalDate.toLocaleDateString('en-CA') + ' ' + originalDate.toLocaleTimeString('it-IT');
}