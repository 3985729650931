import * as React from 'react';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IYoungProps {
    final: any[];
}

const Young = ({final}: IYoungProps) => {
    const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = React.useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const young = final
            .filter((submission: any) => submission.type_jobs_t1_under_30)
            .map((submission: any) => parseInt(submission.type_jobs_t1_under_30))
            .reduce((a, b) => a + (b ?? 0), 0);
        const total = final
            .map((submission: any) => submission.jobs_t1_men_t1_number + submission.jobs_t1_women_t1_number)
            .reduce((a, b) => a + (b ?? 0), 0);

        const data = [
            {
                name: 'Under 30',
                y: young,
            },
            {
                name: 'Over 30',
                y: total - young,
            },
        ];

        const title = '% of youth in the team';
        const dashboardOptions: any = getDefaultPieFormatting(title, data);
        setOptions(dashboardOptions);
    };

    React.useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default Young;
