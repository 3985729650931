import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";
import Map from "./Map";

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const ImportExport = ({final, baseline}: IBusinessProfileProps) => {
    let baselineImportCount = 0;
    let finalImportCount = 0;
    let baselineExportCount = 0;
    let finalExportCount = 0;

    baseline.forEach((submission: any) => {
        if (submission.import_t0 === 'No') {
            baselineImportCount++
        }

        if (submission.export_t0 === 'NO') {
            baselineExportCount++
        }
    })

    final.forEach((submission: any) => {
        if (submission.import_t1 === 'Yes') {
            finalImportCount++
        }

        if (submission.export_t1 === 'Yes') {
            finalExportCount++
        }
    })

    const valueImport = (finalImportCount / baselineImportCount * 100).toFixed(1)
    const valueExport = (finalExportCount / baselineExportCount * 100).toFixed(1)

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{valueImport}
                    </Box>
                    <Typography>
                        % of entrepreneurs that started import
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 120,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        +{valueExport}
                    </Box>
                    <Typography>
                        % of entrepreneurs that started EXPORT
                    </Typography>
                </div>
                <div>
                    <Map  final={final}/>
                </div>
            </CardContent>
        </Card>
    );
};

export default ImportExport;
