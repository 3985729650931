import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {COLORS, EXPORTING} from '../../../utils/constants';
import makeRatio from "../../../utils/makeRatio";

interface IPublicServicesData {
    submissions: any[];
    uoa: string | number
}

const ANSWERS: string [] = [
    'Sì, di servizi erogati da enti pubblici parte del partenariato del progetto corrente',
    'Sì, di servizi erogati da enti pubblici non parte del partenariato del progetto corrente',
    'Sì, di servizi erogati da enti del terzo settore parte del partenariato del progetto corrente',
    'Sì, di servizi erogati da enti del terzo settore non parte del partenariato del progetto corrente',
]

const PublicServices = ({submissions, uoa}: IPublicServicesData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let allSubmissions: number = 0;
        let currentSubmissions:  number = 0;
        const currentCount: number = submissions.filter((submission: any) => submission.uoa_parent_id === uoa).length;
        const allCount = submissions.length;

        submissions.forEach((submission: any) => {
            for (const [key, answer] of Object.entries(submission)) {
                if (key.startsWith('question20_')) {
                    if (ANSWERS.includes(answer as string)) {
                        allSubmissions++

                        if (submission.uoa_parent_id === uoa) {
                            currentSubmissions++
                        }

                        break;
                    }
                }
            }
        })

        const dashboardOptions: any = {
            chart: {
                height: 400,
                type: 'column',
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa'
            },
            title: {
                text: 'Percentuale persone anziane prese in carico dal progetto che erano beneficiarie di servizi pubblici o del terzo settore prima dell’inclusione nel progetto',
                align: 'left'
            },
            xAxis: {
                categories: ['Proprio progetto', 'Media Progetti']
            },
            yAxis: {
                min: 0,
                max: 100,
                labels: {
                    format: '{value}%'
                },
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                column: {
                    cursor: 'pointer',
                    stacking: 'normal',
                }
            },
            series: [
                {
                    showInLegend: false,
                    color: COLORS.blue,
                    name: '',
                    data: [
                        makeRatio(currentSubmissions, [currentCount]),
                        makeRatio(allSubmissions, [allCount]),
                    ]
                },
            ],
            exporting: EXPORTING
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default PublicServices;
