import * as React from "react";
import {IFoodBanksProps} from "../utils/models";
import TotalFood from "./TotalFood";

const NonFoodOut = ({ submissions }: IFoodBanksProps) => {
    return (
       <>
           <div className="content-title col-span-2">
               Non Food Out
           </div>
           <div className="space-y-4">
               <TotalFood submissions={submissions}/>
           </div>
       </>
    );
}

export default NonFoodOut;