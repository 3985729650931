import * as React from "react";
import DataImage from "../../DataImage";
import {IFoodBanksProps} from "../../utils/models";
import makeSum from "../../../../utils/makeSum";

const Warehouses = ({ submissions }: IFoodBanksProps ) => {
    return (
        <DataImage
            image="https://www.eurofoodbank.org/wp-content/uploads/2023/03/Warehouse.png"
            value={makeSum(submissions, 'total_number_of_warehouses')}
            text="Total number of Warehouses"
        />
    );
}

export default Warehouses;