import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {COLORS} from '../../../../utils/constants';
import {getDefaultPieFormatting} from '../../charts/pie';

interface IIncreasedGeographicalAreaProps {
    final: any[];
    baseline: any[];
}

const priority = [
    'item1',
    'item6',
    'item2',
    'item3',
    'item4',
    'item5',
];

const IncreasedGeographicalArea = ({baseline, final}: IIncreasedGeographicalAreaProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const getTopAnswer = (submission: any, question: string) => {
        let answers: number[] = [0];

        for (let i = 0; i < 6; i++) {
            const key = `${question}_${i}`;
            const value = submission[key];

            if (value === null) continue;

            const index = priority.indexOf(value);

            if (index !== -1) {
                answers.push(index + 1);
            }
        }

        return Math.max(...answers);
    }

    const calculateSubmissions = () => {
        let success = 0;
        let unSuccess = 0;

        final.forEach((submission: any) => {
            const baselineSubmission = baseline.find((sub: any) => sub.uoa_id === submission.uoa_id);

            if (baselineSubmission) {
                const baselineAnswer = getTopAnswer(baselineSubmission, 'question7');
                const finalAnswer = getTopAnswer(submission, 'question80');

                if (finalAnswer > baselineAnswer) {
                    success++;
                } else {
                    unSuccess++;
                }
            }
        });

        const title = '% of entrepreneurs who increased the geographical area of activity';
        const data = [
            {
                name: 'Increased',
                y: success,
                color: COLORS.blue,
            },
            {
                name: 'Not increased',
                y: unSuccess,
                color: COLORS.yellow,
            },
        ];

        const dashboardOptions: any = getDefaultPieFormatting(title, data, 1);
        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default IncreasedGeographicalArea;
