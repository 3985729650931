import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IBusinessProfileProps {
    final: any[],
}

const transform = [
    {
        name: 'I was able to grow my business',
        value: 'BIZ_SCALE_T1',
    },
    {
        name: 'I started another business in addition to the one I had',
        value: 'BIZ_NEW_T1',
    },
    {
        name: 'Nothing has changed',
        value: 'BIZ_NO_CHANGE_T1',
    },
    {
        name: 'I close my business',
        value: 'BIZ_CLOSE_T1',
    },
]

const CareerStatus = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data = transform.map((el) => ({
            name: el.name,
            y: 0,
        }));

        final.forEach((submission: any) => {
            transform.forEach((el, index) => {
                if (submission.career_status_biz_t1 && submission.career_status_biz_t1.indexOf(el.value) > -1) {
                    data[index].y = data[index].y + 1
                }
            })
        })

        const dashboardOptions: any = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                    }
                }
            },
            series: [{
                colorByPoint: true,
                data
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default CareerStatus;
