import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {COLORS} from "../../../utils/constants";
import isNumeric from "../../../utils/isNumeric";
import makeAverage from "../../../utils/makeAverage";

interface ISocialLifeData {
    submissions: any[];
    uoa: string | number
}

const KEYS = [
    'incontra_famigliari',
    'incontra_amici',
    'telefonariceve_telefonate_ada_famigliari',
    'telefonariceve_telefonate_ada_amici',
    'esce_di_casa',
    'usa_internet',
    'camminafa_sport',
    'legge_quotidiani_cartacei_o_online',
]

const SocialLife = ({submissions, uoa}: ISocialLifeData) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const allSubmissions: any = {}
        const currentSubmissions: any = {}

        submissions.forEach((submission: any) => {
            KEYS.forEach((key: string) => {
                const question = `question19_${key}`;

                if (submission.hasOwnProperty(question) && isNumeric(submission[question])) {
                    const answer = parseInt(submission[question])

                    if (!allSubmissions.hasOwnProperty(key)) {
                        allSubmissions[key] = []
                    }

                    allSubmissions[key].push(answer)

                    if (submission.uoa_parent_id === uoa) {
                        if (!currentSubmissions.hasOwnProperty(key)) {
                            currentSubmissions[key] = []
                        }

                        currentSubmissions[key].push(answer)
                    }
                }
            })
        })

        const dashboardOptions: Highcharts.Options = {
            chart: {
                polar: true,
                style: {
                    fontFamily: 'Rubik, sans-serif'
                },
                backgroundColor: '#fafafa',
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: [
                    'incontra famigliari',
                    'incontra amici',
                    'telefona/riceve telefonate a/da famigliari',
                    'telefona/riceve telefonate a/da amici',
                    'esce di casa',
                    'usa internet',
                    'cammina/fa sport',
                    'legge quotidiani (cartacei o online)',
                ]
            },
            series: [
                {
                    type: 'line',
                    name: 'Il mio progetto',
                    data: KEYS.map((key) => currentSubmissions.hasOwnProperty(key) ? makeAverage(currentSubmissions[key]) : 0),
                    color: COLORS.blue,
                },
                {
                    type: 'line',
                    name: 'Media dei progetti',
                    data: KEYS.map((key) => allSubmissions.hasOwnProperty(key) ? makeAverage(allSubmissions[key]) : 0),
                    color: COLORS.red,
                },
            ]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>

    );
};

export default SocialLife;
