import { Card, CardContent, Typography } from '@mui/material';
import { IPavesiBeneficiaryProps } from '../../utils/models';

const BeneficiaryCounter = ({data}: IPavesiBeneficiaryProps) => (
  <Card className="chart-container">
    <CardContent>
      <Typography variant="h1" sx={{fontSize: 30}}>
        {data}
      </Typography>
    </CardContent>
  </Card>
);

export default BeneficiaryCounter;
