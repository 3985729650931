import React, {useEffect, useState} from 'react';
import HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import {useSearchParams} from 'react-router-dom';
import {sha256} from 'js-sha256';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import apiCall, {setUserToken} from '../../utils/api';
import Loader from '../../shared/Loader';
import AutonomyLevel from './AutonomyLevel';
import SocialLife from './SocialLife';
import GlobalWellLevel from './GlobalWellLevel';
import CareWorkCoordinator from './CareWorkCoordinator';
import PublicServices from './PublicServices';

import './styles.scss';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const WIAParentBeneficiary = () => {
    const [searchParams] = useSearchParams();
    const container = searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const survey_id = searchParams.get('survey_id');
    const uoa_id = searchParams.get('uoa_id');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_ATTENTAMENTE_PARENT || '');

    const [loading, setLoading] = useState(false);
    const [submissions, setSubmissions] = useState([]);

    const getSurvey = async () => {
        setUserToken(searchParams.get('token') || '')

        try {
            if (survey_id && uoa_id && hashKey === hashKeyLocal) {
                setLoading(true);

                const requests = [
                    await apiCall({
                        url: `containers/${container}/submissions`,
                        params: {
                            survey_id,
                        }
                    })
                ];

                const responses = await Promise.all(requests);

                setSubmissions(responses[0].data.submissions);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getSurvey().then(_ => setLoading(false));
    }, []);

    return (
        <div className="attentamente-parent">
            {
                !loading && (
                    <>
                        {
                            (submissions && uoa_id) && (
                                <>
                                    <div className="w-full">
                                        <div className="section-title">
                                            <div>
                                                LIVELLO DI AUTONOMIA DEI BENEFICIARI PRESI IN CARICO
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il livello di autonomia dei beneficiari presi in carico
                                                    dal
                                                    vostro progetto lungo una serie di dimensioni, a confronto con la media
                                                    degli
                                                    stessi valori in tutti i progetti.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        <AutonomyLevel
                                            submissions={submissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>

                                    <div className="w-full">
                                        <div className="section-title">
                                            <div>
                                                VITA SOCIALE DEI BENEFICIARI PRESI IN CARICO
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il livello di interazione sociale dei beneficiari presi in
                                                    carico dal vostro progetto lungo una serie di dimensioni, a confronto con la
                                                    media degli stessi valori in tutti i progetti.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        <SocialLife
                                            submissions={submissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>

                                    <div className="w-full">
                                        <div className="section-title">
                                            <div>
                                                LIVELLO DI BENESSERE GLOBALE DEI BENEFICIARI PRESI IN CARICO
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra il livello di benessere globale dei beneficiari presi in
                                                    carico dal vostro progetto misurato lungo una serie di dimensioni, a
                                                    confronto con la media degli stessi valori in tutti i progetti.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        <GlobalWellLevel
                                            submissions={submissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>

                                    <div className="w-full">
                                        <div className="section-title">
                                            <div>
                                                PRINCIPALE COORDINATORE DEL LAVORO DI CURA DEI BENEFICIARI PRESI IN CARICO
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra in termini percentuali quali attori sono i principali
                                                    coordinatori delle attività di cura delle persone anziane prese in carico
                                                    dal progetto, a confronto con la media di tutti i progetti. Qui ‘principale
                                                    coordinatore delle attività di cura’ indica la persona che, per ciascun
                                                    beneficiario, è in primo piano nel coordinamento della sua cura.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        <CareWorkCoordinator
                                            submissions={submissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>

                                    <div className="w-full">
                                        <div className="section-title">
                                            <div>
                                                BENEFICIARI GIÀ IN CARICO A SERVIZI PUBBLICI/DEL TERZO SETTORE AL MOMENTO DELLA
                                                PRESA IN CARICO
                                            </div>
                                            <Tooltip
                                                title={<>
                                                    Il grafico mostra quale percentuale di beneficiari era già in carico a
                                                    servizi pubblici o del terzo settore prima dell’inclusione nel progetto, a
                                                    confronto con la media dello stesso valore in tutti i progetti.
                                                </>}
                                            >
                                                <IconButton>
                                                    <Info fontSize="small"/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>

                                        <PublicServices
                                            submissions={submissions}
                                            uoa={parseInt(uoa_id)}
                                        />
                                    </div>

                                </>
                            )
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default WIAParentBeneficiary;
