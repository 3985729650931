import * as React from 'react';
import {Card, CardContent} from '@mui/material';
import Map from './Map';

interface IImportExportMapProps {
    final: any[],
    countries: {
        text: string, value: string
    }[],
}

const EXPORT_QUESTIONS = [
    'question2_0',
    'question2_1',
    'question2_2',
]
const IMPORT_QUESTIONS = [
    'question1_0',
    'question1_1',
    'question1_2',
]

const ImportExportMap = ({final, countries}: IImportExportMapProps) => {
    return (
        <>
            <div>
                <Card className='chart-container'>
                    <CardContent>
                        <div>
                            <Map
                                final={final}
                                questions={IMPORT_QUESTIONS}
                                countries={countries}
                                title='Countries Importing'
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div>
                <Card className='chart-container'>
                    <CardContent>
                        <div>
                            <Map
                                final={final}
                                questions={EXPORT_QUESTIONS}
                                countries={countries}
                                title='Countries Exporting'
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    );
};

export default ImportExportMap;
