import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';

interface IMainBusinessPurposesProps {
    submissions: any[];
}

const MainBusinessPurposes = ({submissions}: IMainBusinessPurposesProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        let data: { name: string, y: number }[] = [];

        submissions.forEach((submission) => {
            for (let i = 0; i < 4; i++) {
                const key = `question24_${i}`;
                const value = submission[key];

                if (value === null) continue;

                const index = data.findIndex((item: any) => item.name === value);

                if (index === -1) {
                    data.push({name: value, y: 1});
                } else {
                    data[index].y += 1;
                }
            }
        });

        data = data.sort((a, b) => a.name.localeCompare(b.name));

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'bar',
            },
            title: {
                text: 'Main purposes of the business',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: [
                    'Achieving market and profits',
                    'Addressing environmental problems',
                    'Serving marginalised communities with my product/service',
                    'Employing vulnerable and marginalized people in my business',
                    'Other'
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default MainBusinessPurposes;
