import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Impatto, {IImpattoData} from '../Impatto';
import {getEntries, groupBy} from '../../../utils/groupBy';

interface IDataProps {
    baseData: any[],
    newData: any[],
    uoa: string | number,
}

const ANSWERS = [
    'Tutti i giorni',
    'Una o più volte alla settimana'
];

const ExtraSchoolActivities = ({baseData, newData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: IImpattoData; uoa: IImpattoData; }>({
        average: {
            same: 0,
            worse: 0,
            better: 0
        },
        uoa: {
            same: 0,
            worse: 0,
            better: 0
        }
    });

    const calcData = () => {
        let average = {
            same: 0,
            worse: 0,
            better: 0
        };
        let current = {
            same: 0,
            worse: 0,
            better: 0
        };

        for (const [parent, newSubmissions] of getEntries(groupBy(newData, 'uoa_parent_id'))) {
            const calc = {
                same: 0,
                worse: 0,
                better: 0
            };
            let newSubmissionsCount = 0;
            let baseSubmissionsCount = 0;
            const baseSubmissions = baseData.filter((submission) => submission.uoa_parent_id === Number(parent));

            newSubmissions.forEach((newSubmission: any) => {
                for (const [question, answer] of Object.entries(newSubmission)) {
                    if (question.startsWith('5_') && ANSWERS.includes(answer as string)) {
                        newSubmissionsCount++;
                    }
                }
            })

            baseSubmissions.forEach((baseSubmission) => {
                for (const [question, answer] of Object.entries(baseSubmission)) {
                    if (question.startsWith('14_') && ANSWERS.includes(answer as string)) {
                        baseSubmissionsCount++;
                    }
                }
            })

            const variation = (newSubmissionsCount - baseSubmissionsCount) / newData.length;

            if (variation > 0) {
                calc.worse++;
            } else if (variation < 0) {
                calc.better++;
            } else {
                calc.same++;
            }

            average = {
                same: +calc.same,
                worse: +calc.worse,
                better: +calc.better
            };

            if (Number(parent) === Number(uoa)) {
                current = calc;
            }
        }

        setData({
            average,
            uoa: current
        });
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>Variazione della frequenza di attività extra-scolastiche</div>
                <Tooltip
                    title={<>
                        Il grafico mostra le eventuali variazioni nella frequenza delle
                        attività extra-scolastiche svolte dai beneficiari presi in carico dal
                        vostro progetto a confronto con la media di tutti i progetti. In
                        particolare, si osserva la distribuzione dei beneficiari presi incarico
                        (per i quali è stata completata almeno una scheda di aggiornamento)
                        fra:<br/>
                        1) beneficiari che hanno aumentato il numero di attività
                        extra-scolastiche svolte almeno una volta a settimana (cioè che nella
                        scheda di aggiornamento più recente presentano un numero di attività
                        extra-scolastiche svolte almeno una volta a settimana superiore a
                        quello registrato nella rispettiva scheda iniziale); <br/>
                        2) beneficiari che hanno mantenuto un livello costante di attività
                        extra-scolastiche svolte almeno una volta a settimana (cioè che nella
                        scheda di aggiornamento più recente presentano un numero di attività
                        extra-scolastiche svolte almeno una volta a settimana uguale a quello
                        registrato nella rispettiva scheda iniziale);<br/>
                        3) beneficiari che hanno diminuito il numero di attività
                        extra-scolastiche svolte almeno una volta a settimana (cioè che nella
                        scheda di aggiornamento più recente presentano un numero di attività
                        extra-scolastiche svolte almeno una volta a settimana inferiore a
                        quello registrato nella rispettiva scheda iniziale)
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Impatto
                    data={data}
                    categories={[
                        {
                            name: 'Beneficiari con diminuzione del numero di attività extra-scolastiche (a settimana)',
                            key: 'better'
                        },
                        {
                            name: 'Beneficiari con andamento stabile del numero di  attività extra-scolastiche (a settimana)',
                            key: 'same'
                        },
                        {
                            name: 'Beneficiari con aumento del numero di attività extra-scolastiche (a settimana)',
                            key: 'worse'
                        }
                    ]}
                />
            }
        </div>
    );
};

export default ExtraSchoolActivities;
