import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import React, {ReactNode} from 'react';

interface ISharedThemeProvider {
    children: ReactNode;
    mainColor: string;
}

const SharedThemeProvider = ({children, mainColor, ...themeOptions}: ISharedThemeProvider) => {
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Rubik',
                'ui-sans-serif',
                'system-ui',
                '-apple-system',
                'BlinkMacSystemFont',
                'Segoe UI',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'Noto Sans',
                'sans-serif',
                'Apple Color Emoji',
                'Segoe UI Emoji',
                'Segoe UI Symbol',
                'Noto Color Emoji',
            ].join(',')
        },
        palette: {
            primary: {
                main: mainColor
            }
        },
        ...themeOptions
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    );
};

export default SharedThemeProvider;
