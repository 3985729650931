import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

import './styles.scss';

interface IBusinessProfileProps {
    final: any[],
}

const EntrepreneursMeasure = ({final}: IBusinessProfileProps) => {
    let value = 0

    if (final.length > 0) {
        value = final.filter((submission: any) => submission.IM_T1 === 'Yes').length / final.length
    }

    return (
        <Card className="chart-container">
            <CardContent className="space-y-4">
                <div className="bold">
                    <Typography variant="h5"  sx={{ fontWeight: 600 }}>
                        Entrepreneurs measure the impact of their business
                    </Typography>
                </div>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 58,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        { value * 100 }
                    </Box>
                    <Typography>
                        % of entrepreneurs that somehow improved the measurement of impact
                    </Typography>
                </div>
                <div>
                    <Typography>
                        Measures introduced to measure the impact
                    </Typography>
                </div>
                <div>
                    <Typography sx={{ fontWeight: 500 }}>
                        WORDCLOUD
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default EntrepreneursMeasure;
