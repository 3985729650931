import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {getDefaultPieFormatting} from '../../charts/pie';

interface INewBusinessRegistrationProps {
    submissions: any[];
}

const transform = [
    {value: 'Yes', name: 'Yes'},
    {value: 'No', name: 'No'},
    {value: 'Ongoing', name: 'The registration process is ongoing'},
];

const NewBusinessRegistration = ({submissions}: INewBusinessRegistrationProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform.map((el) => ({
            name: el.name,
            y: submissions.filter((submission: any) => submission.new_biz_reg === el.value).length,
        }));

        const dashboardOptions: any = getDefaultPieFormatting('New businesses registration', data);

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default NewBusinessRegistration;
