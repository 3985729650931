import * as React from 'react';
import {useEffect, useState} from 'react';
import CustomTable from '../../../shared/ApiTable';
import {Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Download} from '@mui/icons-material';
import {download} from '../../../utils/download';

interface INumeroValutatoreProps {
    submissions: any[],
    foodInSubmissions: any[],
    nonFoodInSubmissions: any[],
    financialsSubmissions: any[],
    humanSubmissions: any[],
    infrastructureSubmissions: any[],
    energySubmissions: any[],
    partnersSubmissions: any[],
    meta: {
        current_page: number,
        total: number,
        per_page: number,
    },
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
    handleChangeRowsPerPage?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
    getAllSurveys: () => Promise<any[]>
}

const Table = ({
                   submissions,
                   foodInSubmissions,
                   nonFoodInSubmissions,
                   financialsSubmissions,
                   humanSubmissions,
                   infrastructureSubmissions,
                   energySubmissions,
                   partnersSubmissions,
                   meta,
                   handleChangePage,
                   handleChangeRowsPerPage,
                   getAllSurveys
               }: INumeroValutatoreProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);

    const columns: string[] = [
        'ID',
        'Uoa ID',
        'FEBA Member',
        'Food Bank representative',
        'Food Banks',
        'Warehouses',
        'Charitable Org',
        'Male beneficiaries',
        'Female beneficiaries',
        'Tot End Beneficiaries',
        'Beneficiaries 0-6 months',
        'Beneficiaries 6months - 3yrs',
        'Beneficiaries 3-14yrs',
        'Beneficiaries 14-18yrs',
        'Beneficiaries over 65',
        'Beneficiaries under 18',
        'Beneficiaries over 18',
        'EU & Nat withdrals',
        'Food Aid - EU FEAD',
        'Food Aid - REACT-EU',
        'Food Aid - Nat. Prog',
        'Surplus Food from Manufactoring Sector',
        'Donated food from Manufactoring Sector',
        'Surplus food from distribution sector',
        'Donated food from distribution sector',
        'Surplus Food from HORECA and catering sector',
        'Donated food from HORECA and catering sector',
        'Collected food',
        'Food from other Food Banks',
        'Purchased food',
        'Other sources',
        'Total food in',
        'Food delivered to charitable organisations',
        'Food delivered to other Food Banks',
        'Warehouse wasted food',
        'Cost of 1 kg of food delivered',
        'Food aid - EU ESF+',
        'Surplus food from agricultural sector',
        'Donated food from agricultural sector',
        'Non food in',
        'Non food out',
        'EU FEAD',
        'EU REACT-EU',
        'EU grants (not FEAD or REACT-EU)',
        'Public sub/grants',
        'Donations (from corps &amp; founds)',
        'Donations (from individuals)',
        'Solidarity contribution',
        'FEBA grants',
        'Other funding',
        'TOTAL INCOME',
        'RENTS',
        'Salaries and related costs',
        'Transport costs',
        'Warehouse maintenance',
        'Warehouse investment',
        'Marketing costs',
        'Other costs',
        'Capital expenditured',
        'Total expenses',
        'Balance',
        'EU ESF+',
        'Regular volunteers',
        'Occasional volunteers',
        'Male volunteers',
        'Female volunteers',
        'Volunteers under 35',
        'Volunteers 36-65',
        'Volunteers over 65',
        'Employees',
        'Male employees',
        'Female employees',
        'Employees under 35',
        'Employees over 35',
        'Community Service',
        'Male Community Service',
        'Female Community Service',
        'Community Service under 35',
        'Community Service over 35',
        'Total number of co-workers',
        'Warehouses m2',
        'Offices m2',
        'Total premises (m2)',
        'Positive cold rooms (m3)',
        'negative cold rooms (m3)',
        'COLD ROOMS (m3)',
        'Positive cold vehicles',
        'Negative cold vehicles',
        'Other vehicles',
        'Total number of vehicles',
        'Hand pallet trucks',
        'Forklifts',
        'Total number of warehouse equipment',
        'Solar panels',
        'Other energy renewable sources',
        'Green energy sources',
        'Manufacturing patners',
        'Distribution partners',
        'Horeca and catering sectors partners',
        'Other partners',
        'Total number of partners',
    ];

    const findSubmission = (data: any[], submission: any) => {
        return data.find((el: any) => el.uoa_id === submission.uoa_id && el.user_id === submission.user_id);
    }

    const process = (submissions: any[]) => {
        const rows: {}[] = [];

        submissions.forEach((submission) => {
            const foodInSubmission = findSubmission(foodInSubmissions, submission);
            const nonFoodInSubmission = findSubmission(nonFoodInSubmissions, submission);
            const financialsSubmission = findSubmission(financialsSubmissions, submission);
            const humanSubmission = findSubmission(humanSubmissions, submission);
            const infrastructureSubmission = findSubmission(infrastructureSubmissions, submission);
            const energySubmission = findSubmission(energySubmissions, submission);
            const partnersSubmission = findSubmission(partnersSubmissions, submission);

            rows.push([
                submission.id,
                submission.uoa_id,
                submission.uoa_name,
                submission.user_name,
                submission.total_number_of_food_banks,
                submission.total_number_of_warehouses,
                submission.number_of_charitable_organisations,
                submission.number_of_male_end_beneficiaries,
                submission.number_of_female_end_beneficiaries,
                submission.number_of_end_beneficiaries,
                submission.number_of_end_beneficiaries_0_6_months,
                submission.number_of_end_beneficiaries_6_months_3_years,
                submission.number_of_end_beneficiaries_3_years_14_years,
                submission.number_of_end_beneficiaries_14_years_18_years,
                submission.number_of_end_beneficiaries_over_65_years,
                submission.number_of_end_beneficiaries_under_18,
                submission.number_of_end_beneficiaries_over_18,
                foodInSubmission?.eu_national_withdrals_fruits_vegetables,
                foodInSubmission?.food_aid_eu_fead,
                foodInSubmission?.food_aid_eu_react_eu,
                foodInSubmission?.food_aid_national_programme,
                foodInSubmission?.surplus_food_from_manufacturing_sector,
                foodInSubmission?.donated_food_from_manufacturing_sector,
                foodInSubmission?.surplus_food_from_distribution_sector,
                foodInSubmission?.donated_food_from_distribution_sector,
                foodInSubmission?.surplus_food_from_horeca_and_catering_sector,
                foodInSubmission?.donated_food_from_horeca_and_catering_sector,
                foodInSubmission?.collected_food,
                foodInSubmission?.food_from_other_food_banks,
                foodInSubmission?.purchased_food,
                foodInSubmission?.other_sources,
                foodInSubmission?.total_food_in_kg,
                foodInSubmission?.food_delivered_to_charitable_organisations,
                foodInSubmission?.food_delivered_to_other_food_banks,
                foodInSubmission?.warehouse_wasted_food,
                foodInSubmission?.cost_of_1_kg_of_food_delivered_food_out,
                foodInSubmission?.question26,
                foodInSubmission?.question29,
                foodInSubmission?.question32,
                nonFoodInSubmission?.non_food_in,
                nonFoodInSubmission?.non_food_out,
                financialsSubmission?.eu_fead,
                financialsSubmission?.eu_react_eu,
                financialsSubmission?.eu_grants_not_fead_or_react_eu,
                financialsSubmission?.subsidiesgrants_from_state_region_city_etc,
                financialsSubmission?.donations_from_corporations_foundations,
                financialsSubmission?.donations_from_individuals,
                financialsSubmission?.solidarity_contribution_charged_to_charitable_organisations,
                financialsSubmission?.feba_grants,
                financialsSubmission?.other_funding,
                financialsSubmission?.total_income,
                financialsSubmission?.rents_warehouse_office_vehicles,
                financialsSubmission?.salaries_and_related_costs,
                financialsSubmission?.transport_costs,
                financialsSubmission?.warehouse_maintenance,
                financialsSubmission?.warehouse_investment,
                financialsSubmission?.marketing_costs,
                financialsSubmission?.other_costs,
                financialsSubmission?.capital_expenditures,
                financialsSubmission?.total_expenses,
                financialsSubmission?.balance,
                financialsSubmission?.question19,
                humanSubmission?.regular_volunteers,
                humanSubmission?.occasional_volunteers,
                humanSubmission?.male_volunteers,
                humanSubmission?.female_volunteers,
                humanSubmission?.volunteers_under_35_years,
                humanSubmission?.volunteers_36_65_years,
                humanSubmission?.volunteers_over_65_years,
                humanSubmission?.employees,
                humanSubmission?.male_employees,
                humanSubmission?.female_employees,
                humanSubmission?.employees_under_35_years,
                humanSubmission?.employees_over_35_years,
                humanSubmission?.community_service,
                humanSubmission?.male_community_service,
                humanSubmission?.female_community_service,
                humanSubmission?.community_service_under_35_years,
                humanSubmission?.community_service_over_35_years,
                humanSubmission?.total_number_of_co_workers,
                infrastructureSubmission?.warehouses,
                infrastructureSubmission?.offices,
                infrastructureSubmission?.total_premises,
                infrastructureSubmission?.positive_cold_rooms,
                infrastructureSubmission?.negative_cold_rooms,
                infrastructureSubmission?.cold_rooms,
                infrastructureSubmission?.positive_cold_vehicles,
                infrastructureSubmission?.negative_cold_vehicles,
                infrastructureSubmission?.other_vehicles,
                infrastructureSubmission?.total_number_of_vehicles,
                infrastructureSubmission?.hand_pallet_truck,
                infrastructureSubmission?.forklifts,
                infrastructureSubmission?.total_number_of_warehouse_equipment,
                energySubmission?.solar_panels,
                energySubmission?.other_energy_renewable_sources,
                energySubmission?.green_energy_sources,
                partnersSubmission?.manufacturing_partners,
                partnersSubmission?.distribution_partners,
                partnersSubmission?.horeca_and_catering_sector_partners,
                partnersSubmission?.other_partners,
                partnersSubmission?.number_of_partners,
            ]);
        })

        return rows;
    }

    const handleDownload = async () => {
        setLoading(true);
        const data = await getAllSurveys();
        const rows = process(data);

        download([columns, ...rows], 'All organization data');
        setLoading(false);
    }

    useEffect(() => {
        setRows(process(submissions));
    }, []);

    return (
        <>
            <div className='content-title'>
                <Typography variant='h5'>
                    All organization data
                </Typography>
                {
                    !loading && (
                        <IconButton onClick={handleDownload}>
                            <Download fontSize='small'/>
                        </IconButton>
                    )
                }
                {
                    loading && (
                        <>
                            ...loading
                        </>
                    )
                }
            </div>
            <CustomTable
                columns={columns}
                rows={rows}
                meta={meta}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                useStaticWidth={false}
                numberRows={[
                    ...[0, 1],
                    ...Array.from(Array(columns.length), (_, i) => i + 4)
                ]}/>
        </>
    );
};

export default Table;
