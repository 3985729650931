import React, {createContext, ReactNode} from 'react';

interface ContainerContextProps {
    type: number | string | null,
}

type ContainerContextProviderProps = {
    type: number | string | null,
    children?: ReactNode
}

export const ContainerContext = createContext<ContainerContextProps>({
    type: null,
});

export const ContainerProvider = ({type, children}: ContainerContextProviderProps) => {
    return (
        <ContainerContext.Provider
            value={{
                type
            }}
        >
            {children}
        </ContainerContext.Provider>
    )
}
