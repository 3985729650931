import * as React from "react";
import Students from "./Students";
import BusinessProfile from "./BusinessProfile";
import {Typography} from "@mui/material";

interface IOutputProps {
    students: string,
    women: string,
    graduated: { yes: number, no: number },
    baseline: any[],
}

const Output = ({students, women, graduated, baseline}: IOutputProps) => {
    return (
        <>
            <div className="row">
                <div className="flex items-baseline space-x-2 col-span-2 ">
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        OUTPUT:
                    </Typography>
                    <Typography  sx={{ fontWeight: 600 }}>
                        Impact entrepreneurs receive practical and high-quality business training, access to markets and funding.
                    </Typography>
                </div>
                <Students students={students} women={women} graduated={graduated}/>
                <div>
                    <BusinessProfile submissions={baseline}/>
                </div>
            </div>
        </>
    )
}

export default Output