import { Card, CardContent } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { useEffect, useRef, useState } from 'react';
import { IPavesiColumnsProps } from '../../utils/models';
import mapAnswers from '../../utils/mapAnswers';

const Precarita = ({submissions, checkpoints}: IPavesiColumnsProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const calculateSubmissions = () => {
    const answersMapper: any = {
      Precario: {},
      Medio: {},
      Basso: {},
      Alto: {},
      empty: {}
    }

    mapAnswers(submissions, answersMapper, 'question14');

    const dashboardOptions: any = {
      chart: {
        height: 400,
        type: 'column',
        style: {
          fontFamily: 'Rubik, sans-serif'
        },
        backgroundColor: '#fafafa'
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: checkpoints.map((checkpoint) => checkpoint.name),
      },
      yAxis: {
        min: 0,
        stackLabels: {
          enabled: true,
        },
      },
      plotOptions: {
        column: {
          cursor: 'pointer',
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
        {
          name: 'Precario',
          color: '#ff6d01',
          data: checkpoints.map((checkpoint) => answersMapper.Precario[checkpoint.id] || 0)
        },
        {
          name: 'Medio',
          color: '#34a853',
          data: checkpoints.map((checkpoint) => answersMapper.Medio[checkpoint.id] || 0)
        },
        {
          name: 'Basso',
          color: '#fcbc00',
          data: checkpoints.map((checkpoint) => answersMapper.Basso[checkpoint.id] || 0)
        },
        {
          name: 'Alto',
          color: '#ea4236',
          data: checkpoints.map((checkpoint) => answersMapper.Alto[checkpoint.id] || 0)
        },
        {
          name: 'Nessuna Risposta',
          color: '#4285f4',
          data: checkpoints.map((checkpoint) => answersMapper.empty[checkpoint.id] || 0)
        },
      ]
    };

    setOptions(dashboardOptions);
  };

  useEffect(() => {
    calculateSubmissions();
  }, [submissions]);

  return (
    <Card className="chart-container">
      <CardContent>
        {
          options && (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          )
        }
      </CardContent>
    </Card>
  );
};

export default Precarita;
