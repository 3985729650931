import {SyntheticEvent, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Box, Tabs} from "@mui/material";
import TabPanel from "../../shared/Tabs/TabPanel";
import Loader from "../../shared/Loader";
import StyledTab from "../../shared/Tabs/StyledTab";
import CustomSelect from "../../shared/CustomSelect";
import {sha256} from "js-sha256";
import apiCall, {setUserToken} from "../../utils/api";
import NumeroValutatore from "./NumeroValutatore";
import NumeroProgetto from "./NumeroProgetto";
import TotalScore from "./TotalScore";
import EvaluationsOverview from "./EvaluationsOverview";
import NoResults from "../../shared/NoResults";

import './styles.scss';

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const STATO = [
    {
        id: 1,
        name: 'Completato',
    },
    {
        id: 0,
        name: 'Non completato',
    },
]

const CDP = () => {
    const [searchParams] = useSearchParams();
    const container =  searchParams.get('container_id');
    const surveys = searchParams.getAll('surveys');
    const typeId = searchParams.get('type_id');
    const containerId = searchParams.get('container_id');
    const hashKey = searchParams.get('key');
    const hashKeyLocal = sha256(process.env.REACT_APP_HASH_KEY_CDP || '');

    setUserToken( searchParams.get('token') || '')

    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState(0);
    const [uoas, setUoas] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [selectedUoa, setSelectedUoa] = useState<string>('All');
    const [selectedStato, setSelectedStato] = useState<number>(1);
    const [isReady, setIsReady] = useState<boolean>(false);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const getFilterData = async () => {
        const requests = [
            await apiCall({
                url: `containers/${container}/uoa`,
                params: {
                    container_id: containerId,
                    pagination: 'false',
                    searched: {
                        type_id: typeId,
                    }
                }
            })
        ]

        const responses = await Promise.all(requests);

        if (responses.length > 0 && responses[0].data.hasOwnProperty('items')) {
            setUoas(responses[0].data.items.map((item: { id: number, title: string }) => ({
                name: item.title,
                id: item.id,
            })));
        }
    }

    const getSurvey = async () => {
        try {
            setLoading(true);

            if (Array.isArray(surveys) && containerId && typeId && hashKey === hashKeyLocal) {
                const requests = [
                    await apiCall({
                        url: `containers/${container}/submissions/container`,
                        params: {
                            container_id: containerId,
                            survey_ids: surveys,
                            completed: selectedStato,
                            ...(selectedUoa !== 'All') && {uoa_parent_id: selectedUoa},
                        }
                    }),
                ];

                const responses = await Promise.all(requests);

                if (responses.length > 0 && responses[0].data.hasOwnProperty('submissions')) {
                    const submissions = responses[0].data.submissions

                    submissions.sort(function(a: any, b: any) {
                        const keyA = a.uoa_name,
                            keyB = b.uoa_name;

                        if (keyA > keyB) {
                            return 1;
                        }

                        if (keyA < keyB) {
                            return -1;
                        }

                        return 0;
                    });

                    setSubmissions(submissions);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getFilterData().then(_ => setIsReady(true));
    }, []);

    useEffect(() => {
        if (isReady) {
            getSurvey().then(_ => setLoading(false));
        }
    }, [selectedUoa, isReady, selectedStato]);

    return (
        <div className="container space-y-8">
            {
                !loading && (
                    <>
                        <div className="filter space-x-4">
                            <CustomSelect
                                items={uoas}
                                value={selectedUoa}
                                onChange={(value: any) => setSelectedUoa(value)}
                                label="Bando"
                                className="uoa-select"
                                defaultVariant="All"
                            />
                            <CustomSelect
                                items={STATO}
                                value={selectedStato}
                                onChange={(value: any) => setSelectedStato(value)}
                                label="Stato"
                                className="uoa-select"
                                defaultVariant="1"
                                disableCloseOnSelect={true}
                            />
                        </div>
                        {
                            <>
                                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                    <Tabs
                                        value={tab}
                                        onChange={handleChange}
                                        aria-label="tabs"
                                    >
                                        <StyledTab label="Numero per valutatore" {...tabProps(0)} />
                                        <StyledTab label="Numero per progetto" {...tabProps(1)} />
                                        <StyledTab label="Total score progetti" {...tabProps(2)} />
                                        <StyledTab label="Overview valutazioni" {...tabProps(3)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tab} index={0}>
                                    {
                                        submissions.length > 0 && (
                                            <NumeroValutatore submissions={submissions}/>
                                        )
                                    }
                                    {
                                        submissions.length === 0 && (
                                            <NoResults/>
                                        )
                                    }
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    {
                                        submissions.length > 0 && (
                                            <NumeroProgetto submissions={submissions}/>
                                        )
                                    }
                                    {
                                        submissions.length === 0 && (
                                            <NoResults/>
                                        )
                                    }
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    {
                                        submissions.length > 0 && (
                                            <TotalScore submissions={submissions}/>
                                        )
                                    }
                                    {
                                        submissions.length === 0 && (
                                            <NoResults/>
                                        )
                                    }
                                </TabPanel>
                                <TabPanel value={tab} index={3}>
                                    {
                                        submissions.length > 0 && (
                                            <EvaluationsOverview submissions={submissions}/>
                                        )
                                    }
                                    {
                                        submissions.length === 0 && (
                                            <NoResults/>
                                        )
                                    }
                                </TabPanel>
                            </>
                        }
                    </>
                )
            }
            {
                loading && (
                    <div className="loader-container">
                        <Loader size={60}/>
                    </div>
                )
            }
        </div>
    );
};

export default CDP;
