import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {ISubmissionsData} from '../../utils/data';
import CustomTable from '../../../../shared/Table';

const Beneficiary = ({beneficiarySubmissions}: ISubmissionsData) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<any[]>([]);

    const columns: any[] = [
        'Submission ID',
        'Uoa ID',
        'Iniziativa',
        'Uoa Parent ID',
        'Progetto',
        'Survey ID',
        'Survey Name',
        'Checkpoint ID',
        'Checkpoint Name',
        'User ID',
        'User Name',
        'Uoa Custom Field Titolo Progetto 58',
        'Uoa Custom Field Titolo Iniziati 59',
        'Uoa Breakdown Edizioni 14',
        'Intro 0',
        'A1 1',
        'Libri letti negli ultimi mesi',
        'Comunità di lettore',
        'Partecipazione evento promozione lettura',
        'Partecipazione incontro presentazione libro o dialogo con autore',
        'Presenza in biblioteca negli ultimi 12 mesi',
        'Presenza in biblioteca negli ultimi 12 mesi',
        'Utilizzo biblioteca online',
        'Età (in anni compiuti)',
        'Sesso',
        'Cittadinanza',
        'Titolo di studio',
        'Submission Opened Date Time',
        'Condizione occupazionale',
        'Submission Updated Date Time',
        'Sezione A Abit 2 A3 1 Libri Cartacei 0 Quantita 0',
        'Sezione A Abit 2 A3 1 E Book O Libri 1 Quantita 0',
        'Reddito mensile famigliare',
        'Sezione A Abit 2 A3 1 Audiolibri 2 Quantita 0',
        'Info',
        'Sezione A Abit 2 A4 2 0',
        'Info',
        'Sezione A Abit 2 A4 2 1',
        'D1',
        'Sezione A Abit 2 A4 2 2',
        'D2',
        'Sezione A Abit 2 A4 2 3',
        'Uoa Custom Field ID Progetto 62',
        'Sezione A Abit 2 A5bis 4 0',
        'Sezione A Abit 2 A5bis 4 1',
        'Sezione A Abit 2 A5bis 4 2',
        'Sezione A Abit 2 A5bis 4 3',
        'Sezione A Abit 2 A6bis 6 0',
        'Sezione A Abit 2 A6bis 6 1',
        'Sezione A Abit 2 A6bis 6 2',
        'Sezione A Abit 2 A6bis 6 3',
        'Sezione A Abit 2 A7bis 8 0',
        'Sezione A Abit 2 A7bis 8 1',
        'Sezione A Abit 2 A7bis 8 2',
        'Sezione A Abit 2 A7bis 8 3',
        'Sezione A Abit 2 A7bis 8 4',
        'Sezione A Abit 2 A8ter 11 0',
        'Sezione A Abit 2 A8ter 11 1',
        'Sezione A Abit 2 A8ter 11 2',
        'Sezione A Abit 2 A8ter 11 3',
        'Sezione B Moti 3 B1 0 0',
        'Sezione B Moti 3 B1 0 1',
        'Sezione B Moti 3 B1 0 2',
        'Sezione B Moti 3 B1 0 3',
        'Sezione B Moti 3 B1 0 4',
        'Sezione B Moti 3 B1 0 5',
        'Sezione B Moti 3 B2 1 0',
        'Sezione B Moti 3 B2 1 1',
        'Sezione B Moti 3 B2 1 2',
        'Sezione B Moti 3 B2 1 3',
        'Sezione B Moti 3 B2 1 4',
        'Sezione B Moti 3 B2 1 5',
        'Sezione B Moti 3 B2 1 6',
        'Sezione B Moti 3 B2 1 7',
        'Sezione C Info 4 C6 5 Numero Di Adult 0 Numero 0',
        'Sezione C Info 4 C6 5 Numero Di Minor 1 Numero 0',
        'Panel1 5 Privacy 3 0',
        'Sezione C Info 4 C8 8 Iniziale Del Nome',
        'User Email',
        'Sezione C Info 4 C8 8 Iniziale Del Cognome',
        'Sezione C Info 4 C8 8 Giorno Di Nascita',
        'Sezione C Info 4 C8 8 Numero Civico Indirizzo Di Residenza',
        'Validation Status',
        'Lettore?',
    ];

    const calculateSubmissions = () => {
        const rows = Object.values(beneficiarySubmissions)
            .map((item: any) => {
                return [
                    ...[
                        item.id,
                        item.uoa_id,
                        item.uoa_name,
                        item.uoa_parent_id,
                        item.uoa_parent_title,
                        item.survey_id,
                        item.survey_name,
                        item.checkpoint_id,
                        item.checkpoint_name,
                        item.user_id,
                        item.user_name,
                        item.uoa_custom_field_titolo_progetto_58,
                        item.uoa_custom_field_titolo_iniziati_59,
                        item.uoa_breakdown_edizioni_14,
                        item.intro,
                        item.a1,
                        item.a2,
                    ],
                    ...Array.from(Array(4)).map((_, index) => item[`a${(index + 5)}`] || '-'),
                    ...[
                        item.a8bis,
                        item.a9,
                    ],
                    ...Array.from(Array(4)).map((_, index) => item[`c${(index + 1)}`] || '-'),
                    ...[
                        item.submission_opened_date_time,
                        item.c5,
                        item.submission_updated_date_time,
                        item.a3_libri_cartacei_quantita,
                        item.a3_e_book_o_libri_online_quantita,
                        item.c7,
                        item.a3_audiolibri_quantita,
                        item.c8intro,
                        item.a4_0,
                        item.d1intro,
                        item.a4_1,
                        item.d1,
                        item.a4_2,
                        item.d2,
                        '',
                        item.uoa_custom_field_id_progetto_62,
                    ],
                    ...Array.from(Array(4)).map((_, index) => item[`a5bis_${(index)}`] || '-'),
                    ...Array.from(Array(4)).map((_, index) => item[`a6bis_${(index)}`] || '-'),
                    ...Array.from(Array(5)).map((_, index) => item[`a7bis_${(index)}`] || '-'),
                    ...Array.from(Array(4)).map((_, index) => item[`a8ter_${(index)}`] || '-'),
                    ...Array.from(Array(6)).map((_, index) => item[`b1_${(index)}`] || '-'),
                    ...Array.from(Array(8)).map((_, index) => item[`b2_${(index)}`] || '-'),
                    ...[
                        item.c6_numero_di_adulti_numero,
                        item.c6_numero_di_minori_numero,
                        item.privacy_0,
                        item.c8_iniziale_del_nome,
                        item.user_email,
                        item.c8_iniziale_del_cognome,
                        item.c8_giorno_di_nascita,
                        item.c8_numero_civico_indirizzo_di_residenza,
                        item.validation_status,
                        item.a1 === '2' ? 'No' : 'Si',
                    ],
                ]
            })

        setRows(rows)
        setLoading(true)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='mt-12'>
            <CardContent>
                <div className='py-16'>
                    <Typography variant='h3' sx={{fontSize: 18, fontWeight: 500}}>
                        Dettaglio delle risposte al questionario beneficiari
                    </Typography>
                    <div className='mt-12'>
                        <Typography variant='h4' sx={{fontSize: 18, fontWeight: 500}}>
                            Questionari Beneficiari
                        </Typography>
                    </div>
                </div>
                {
                    loading && (
                        <CustomTable
                            columns={columns}
                            rows={rows}
                            numberRows={[]}
                        />
                    )
                }
            </CardContent>
        </Card>
    )
}

export default Beneficiary;
