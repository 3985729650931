import * as React from "react";
import DataImage from "../../DataImage";
import {IFoodBanksProps} from "../../utils/models";
import makeSum from "../../../../utils/makeSum";

const CharitableOrganisations = ({ submissions }: IFoodBanksProps) => {
    return (
        <DataImage
            image="https://www.eurofoodbank.org/wp-content/uploads/2023/03/Charitable_organisations.png"
            value={makeSum(submissions, 'number_of_charitable_organisations')}
            text="Number of charitable organisations"
        />
    );
}

export default CharitableOrganisations;