import React, {useEffect, useState} from 'react';
import Info from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Column from '../Column';

interface IDataProps {
    plannedData: any[],
    updatingData: any[],
    uoa: string | number,
}

const ActivitiesReachingRate = ({plannedData, updatingData, uoa}: IDataProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [data, setData] = useState<{ average: number; uoa: number; }>({
        average: 0,
        uoa: 0,
    });

    const getSubmissionAnswer = (submission: any) => {
        let value = 0;

        for (const [question, answer] of Object.entries(submission)) {
            if (question.startsWith('question3_') && answer) {
                value = value + Number(answer);
            }
        }

        return value;
    }

    const calcData = () => {
        const average = {
            planned: 0,
            updating: 0,
        };
        const current = {
            planned: 0,
            updating: 0,
        }

        plannedData.forEach((plannedSubmission: any) => {
            const value = getSubmissionAnswer(plannedSubmission);

            average.planned = average.planned + value;

            if (plannedSubmission.uoa_parent_id === Number(uoa)) {
                current.planned = average.planned + value;
            }
        })

        updatingData.forEach((updatingSubmission: any) => {
            const value = getSubmissionAnswer(updatingSubmission);

            average.updating = average.updating + value;

            if (updatingSubmission.uoa_parent_id === Number(uoa)) {
                current.updating = average.updating + value;
            }
        })

        setData({
            average: average.updating / average.planned,
            uoa: current.updating / current.planned,
        })
        setLoaded(true)
    }

    useEffect(() => {
        calcData();
    }, []);

    return (
        <div>
            <div className='section-title'>
                <div>TASSO DI RAGGIUNGIMENTO DEI DESTINATARI DELLE ATTIVITA' DI SENSIBILIZZAZIONE</div>
                <Tooltip
                    title={<>
                        Il grafico mostra il tasso di raggiungimento dei destinatari attesi a seguito delle attività di
                        sensibilizzazione previste dal vostro progetto a confronto con la media dello stesso valore in
                        tutti i progetti. Il valore è ottenuto dal rapporto tra i destinatari che il progetto prevede di
                        raggiungere secondo la scheda previsionale attività di intercettazione e i soggetti
                        effettivamente raggiunti secondo la più recente scheda aggiornamento attività di
                        intercettazione.
                    </>
                    }
                >
                    <IconButton>
                        <Info fontSize='small'/>
                    </IconButton>
                </Tooltip>
            </div>
            {
                loaded && <Column
                    data={data}
                />
            }
        </div>
    );
};

export default ActivitiesReachingRate;
