import React, {useEffect, useState} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import {numberFormat} from '../../../../utils/numberFormat';
import {ISubmissionsData} from '../../utils/data';
import {previsionaleCheckpoint, consuntivoCheckpoint} from '../../index';

const ActualParticipants = ({iniziativaSubmissions}: ISubmissionsData) => {
    const [data, setData] = useState(0);
    const [loading, setLoading] = useState(false);

    const calcResults = () => {
        const answers: any[] = [];

        iniziativaSubmissions
            .filter((submission) => submission.checkpoint_id === previsionaleCheckpoint)
            .forEach((submission) => {
                const newSubmission = iniziativaSubmissions.find((el) => el.checkpoint_id === consuntivoCheckpoint && el.uoa_id === submission.uoa_id)

                if (newSubmission) {
                    answers.push(newSubmission.proj_beneficiaries_total_numero)
                }
            })

        setData(answers.reduce(function (a, b) {
            return (a + b);
        }, 0))
        setLoading(true)
    }

    useEffect(() => {
        calcResults()
    }, []);

    return (
        <>
            {
                loading && (
                    <Card>
                        <CardContent className='text-center text-primary'>
                            <Typography variant='h1' sx={{fontSize: 30, fontWeight: 800}}>
                                {numberFormat(data, 0, ',', '.')}
                            </Typography>
                            <Typography>
                                Partecipanti effettivi
                            </Typography>
                        </CardContent>
                    </Card>
                )
            }
        </>
    );
};

export default ActualParticipants;
