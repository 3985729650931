import * as React from "react";
import {Typography} from "@mui/material";
import ScaledBusiness from "./ScaledBusiness";
import Turnover from "./Turnover";
import CareerStatus from "./CareerStatus";
import SuccessFactor from "./SuccessFactor";
import EBITDA from "./EBITDA";
import ImportExport from "./ImportExport";
import Branches from "./Branches";
import SuccessfullyStart from "./SuccessfullyStart";
import ImproveCareer from "./ImproveCareer";
import BusinessSector from "./BusinessSector";

interface ILongTermProps {
    final: any[],
    baseline: any[],
}

const LongTerm = ({final, baseline}: ILongTermProps) => {
    return (
        <div className="row">
            <div className="col-span-2 bold px-4">
                <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                    Entrepreneurs scale up their business
                </Typography>
            </div>
            <div></div>
            <div>
                <ScaledBusiness final={final} baseline={baseline}/>
            </div>
            <div>
                <Turnover final={final} baseline={baseline} />
            </div>
            <div>
                <CareerStatus final={final} />
            </div>
            <div className="row-3 col-span-2">
                <div></div>
                <div className="bold px-4">
                    <Typography variant="h5" sx={{fontWeight: 600, textAlign: 'center'}}>
                        Business success factor: &gt;&gt;
                    </Typography>
                </div>
                <div>
                    <SuccessFactor final={final} />
                </div>
            </div>
            <div>
                <EBITDA baseline={baseline} final={final}/>
            </div>
            <div>
                <ImportExport baseline={baseline} final={final} />
            </div>
            <div>
                <Branches final={final}/>
            </div>
            <div></div>
            <div>
                <SuccessfullyStart baseline={baseline}  final={final}/>
            </div>
            <div>
                <ImproveCareer baseline={baseline}  final={final}/>
            </div>
            <div>
                <BusinessSector final={final}/>
            </div>
        </div>
    )
}

export default LongTerm;