import * as React from 'react';
import {Box, Card, CardContent, Typography} from '@mui/material';

interface ITotalRespondentsProps {
    total: number;
}

const TotalRespondents = ({total}: ITotalRespondentsProps) => {
    return (
        <div className='space-y-4'>
            <Card className='chart-container'>
                <CardContent>
                    <div className='enrolled-item space-x-4'>
                        <Box
                            sx={{
                                width: '4rem',
                                p: 1,
                                border: '1px solid grey',
                                textAlign: 'right',
                                fontSize: '1.25rem',
                                backgroundColor: 'rgb(243, 244, 246)',
                            }}
                        >
                            {total}
                        </Box>
                        <Typography>Total respondents (replied both to BASELINE and FINAL)</Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default TotalRespondents;
