import * as React from 'react';
import {Typography} from '@mui/material';
import getValidSubmissions from '../helpers/getValidSubmissions';
import EnrolledItem from '../base/EnrolledItem';
import WomenEmployed from './WomenEmployed';
import JobsCreated from './JobsCreated';
import WomenManager from './WomenManager';
import ContributionToResult from './ContributionToResult';

interface IImpactProps {
    final: any[];
    baseline: any[];
}

const Impact = ({final, baseline}: IImpactProps) => {
    const result: {[question: string]: number} = {
        permanentContracts: 0,
        managerialPositions: 0,
        under30: 0,
    };

    function isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const getAnswerValue = (baseline: any, questionBaseline: string, final: any, questionFinal: string) => {
        const answerBaseline = baseline[questionBaseline];
        const answerFinal = final[questionFinal];

        if (isNumeric(answerBaseline) && isNumeric(answerFinal)) {
            return Math.max(Number(answerFinal) - Number(answerBaseline), 0);
        }

        return 0;
    };

    const {filteredBaseline, filteredFinal} = getValidSubmissions(baseline, final);

    filteredBaseline.forEach((baselineSubmission: any) => {
        const finalSubmission = filteredFinal.find(
            (finalSubmission: any) => finalSubmission.uoa_id === baselineSubmission.uoa_id
        );

        if (finalSubmission) {
            result.permanentContracts = getAnswerValue(
                baselineSubmission,
                'type_contract_t0_permanent_contracts',
                finalSubmission,
                'type_contract_t1_per_contract_t1'
            );
            result.managerialPositions += getAnswerValue(
                baselineSubmission,
                'type_contract_t0_managerial_positions',
                finalSubmission,
                'type_contract_t1_mgr_contract_t1'
            );
            result.under30 += getAnswerValue(
                baselineSubmission,
                'type_jobs_t0_under_30',
                finalSubmission,
                'type_jobs_t1_under_30'
            );
        }
    });

    return (
        <>
            <Typography variant='h5'>Job creation</Typography>
            <br />
            <JobsCreated
                final={filteredFinal}
                baseline={filteredBaseline}
            />
            <WomenEmployed
                final={filteredFinal}
                baseline={filteredBaseline}
            />
            <br />
            <div className='enrolled-item space-x-4'>
                <EnrolledItem
                    value={result.under30.toFixed()}
                    text='Number of youth employed'
                />
            </div>
            <div className='enrolled-item space-x-4'>
                <EnrolledItem
                    value={result.permanentContracts.toFixed()}
                    text='Number of permanent jobs created per entrepreneur'
                />
            </div>
            <div className='enrolled-item space-x-4'>
                <EnrolledItem
                    value={result.managerialPositions.toFixed()}
                    text='Average number of high-skill jobs created per entrepreneur'
                />
            </div>
            <WomenManager
                final={filteredFinal}
                baseline={filteredBaseline}
            />
            <br />
            <ContributionToResult final={filteredFinal} />
        </>
    );
};

export default Impact;
