import {useEffect, useRef, useState} from 'react';
import {Card, CardContent} from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

interface ITassoProps {
    data: [][],
    name: string
    elements: {
        name: string,
        choices: [],
    }[]
}

const Bar = ({data, name, elements}: ITassoProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    let categories: any[] = [];
    const question = elements.find((element: { name: string }) => element.name === name)

    if (question) {
        categories = question.choices
    }

    const series = categories.map(category => (
            data
                .map(item => Object.values(item)
                    .filter(answer => answer === (typeof category === 'object' ? category.value : category)).length)
                .reduce((partialSum, a) => partialSum + a, 0)
        )
    )

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: '',
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: categories.map((category) => typeof category === 'object' ? category.text : category),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: "Count",
                data: series
            }]
        };

        setOptions(dashboardOptions)
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card>
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default Bar;
