import * as React from 'react';
import {useEffect, useRef, useState} from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import {Card, CardContent} from "@mui/material";
import makeAverage from "../../../../utils/makeAverage";

interface IBusinessProfileProps {
    final: { [key: string]: string }[],
}

const QUESTIONS = [
    'inn_perf_t1_inn_perf_prod_t1',
    'inn_perf_t1_inn_perf_proc_t1',
    'inn_perf_t1_inn_perf_mng_t1',
    'inn_perf_t1_inn_perf_mkt_t1',
    'inn_perf_t1_inn_perf_bm_t1',
]

const InnovationPerformance = ({final}: IBusinessProfileProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();
    const data: { [key: string]: number[] } = {
        inn_perf_t1_inn_perf_prod_t1: [],
        inn_perf_t1_inn_perf_proc_t1: [],
        inn_perf_t1_inn_perf_mng_t1: [],
        inn_perf_t1_inn_perf_mkt_t1: [],
        inn_perf_t1_inn_perf_bm_t1: [],
    };

    final.forEach((submission) => {
        QUESTIONS.forEach((question) => {
            data[question].push(Number(submission[question]))
        })
    })

    const calculateSubmissions = () => {
        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Level of innovation performance',
                align: 'left'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: [
                    'Products and services for customers',
                    'Production methods and processes',
                    'Management practices',
                    'Marketing',
                    'Business models'
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: "Number",
                data: Object.values(data)
                    .map((el) => makeAverage(el))
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
}

export default InnovationPerformance