import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import {Card, CardContent} from '@mui/material';
import {percentageFormatter} from '../../helpers/formatters';
import getFieldOptionTranslation from '../../helpers/getFieldOptionTranslation';

interface IEducationalBackgroundProps {
    submissions: any[];
    field: any;
}

const EducationalBackground = ({submissions, field}: IEducationalBackgroundProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const values: string[] = submissions
            .map((submission: any) => submission.uoa_custom_field_educational_bac_44_id_value)
            .filter((value) => value);

        const uniqueValues: string[] = field.options.map((option: any) => option.id);
        const translations = field.options.reduce(
            (acc: any, option: any) => ({[option.id]: getFieldOptionTranslation(option), ...acc}),
            {}
        );

        const data = uniqueValues.map((value) => {
            const count = values.filter((val) => val === value).length;

            return {name: translations[value], y: count};
        });

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Type of educational background',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default EducationalBackground;
