import * as React from "react";
import {IFoodBanksProps} from "../utils/models";
import TotalFoodIn from "./TotalFoodIn";

const NonFoodIn = ({ submissions }: IFoodBanksProps) => {
    return (
       <>
           <div className="content-title col-span-2">
               Non Food In
           </div>
           <div className="space-y-4">
               <TotalFoodIn submissions={submissions}/>
           </div>
       </>
    );
}

export default NonFoodIn;