import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ActualParticipants from './ActualParticipants';
import ParticipationRate from './ParticipationRate';
import NotReaders from './NotReaders';
import WeakReaders from './WeakReaders';
import ResponseRate from './ResponseRate';
import NonReadersChart from './NonReadersChart';
import WeakReadersChart from './WeakReadersChart';
import {ISubmissionsUoaData} from '../utils/data';

require('highcharts/modules/exporting')(Highcharts);
HighchartsMore(Highcharts);

const AllProjects = ({iniziativaSubmissions, beneficiarySubmissions, uoaParentId}: ISubmissionsUoaData) => {
    return (
        <>
            <div className='w-full'>
                <div className='section-title'>
                    <div>Statistiche benchmark (dagli altri progetti)</div>
                </div>
                <div className='container-child grid-cols-3'>
                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <>
                                    <ActualParticipants
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                    <ParticipationRate
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                </>
                            )
                        }
                    </div>

                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <>
                                    <NotReaders
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                    <WeakReaders
                                        iniziativaSubmissions={iniziativaSubmissions}
                                        beneficiarySubmissions={beneficiarySubmissions}
                                    />
                                </>
                            )
                        }
                    </div>

                    <div className='chart-container'>
                        {
                            (iniziativaSubmissions && beneficiarySubmissions) && (
                                <ResponseRate
                                    iniziativaSubmissions={iniziativaSubmissions}
                                    beneficiarySubmissions={beneficiarySubmissions}
                                />
                            )
                        }
                    </div>
                </div>

                <div className='container-child grid-cols-2 mt-12'>
                    {
                        (iniziativaSubmissions && beneficiarySubmissions && uoaParentId) && (
                            <>
                                <NonReadersChart
                                    iniziativaSubmissions={iniziativaSubmissions}
                                    beneficiarySubmissions={beneficiarySubmissions}
                                    uoaParentId={uoaParentId}
                                />
                                <WeakReadersChart
                                    iniziativaSubmissions={iniziativaSubmissions}
                                    beneficiarySubmissions={beneficiarySubmissions}
                                    uoaParentId={uoaParentId}
                                />
                            </>
                        )
                }
                </div>
            </div>
        </>
    );
};

export default AllProjects;
