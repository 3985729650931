import * as React from 'react';
import {Box, Card, CardContent, Typography} from "@mui/material";

import './styles.scss';

interface IBusinessProfileProps {
    final: any[],
    baseline: any[],
}

const ProductsInMarket = ({ final, baseline }: IBusinessProfileProps) => {
    const idealProductCount = final.filter((submission: any) => submission.idea_product_sold_t1 === 'Yes').length
    let soldProduct = 0;
    let value = 0;

    baseline.forEach((submission) => {
        if (submission.career_status_t0 === 'BIZ_IDEA_EM_T0' && submission.product_sold_t0 === 'No') {
            soldProduct++
        }
    })

    if (soldProduct > 0) {
        value = idealProductCount / soldProduct
    }

    return (
        <Card className="chart-container">
            <CardContent>
                <div className="enrolled-item space-x-4">
                    <Box
                        sx={{
                            width: 58,
                            p: 1,
                            border: '1px solid grey',
                            textAlign: 'right',
                            fontSize: '1.25rem',
                            backgroundColor: 'rgb(243, 244, 246)',
                        }}
                    >
                        {value * 100}
                    </Box>
                    <Typography>
                        % of entrepreneurs that have their products in the market
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default ProductsInMarket;
