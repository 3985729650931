import * as React from 'react';
import {Typography} from "@mui/material";

const MidtermOutcomes = () => {
    return (
        <>
            <div className="col-span-2 bold">
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                    MID-TERM OUTCOMES
                </Typography>
            </div>
            <div className="col-span-2 bold px-4">
                <Typography variant="h5"  sx={{ fontWeight: 600 }}>
                    Entrepreneurs implement and improve the business model
                </Typography>
            </div>
        </>
    );
};

export default MidtermOutcomes;
