import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';

interface IDropOutsProps {
    graduated: { yes: number, no: number },
}

const DropOuts = ({ graduated }: IDropOutsProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = [
            graduated.yes,
            graduated.no
        ];

        const dashboardOptions: any = {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Number of drop outs',
                align: 'left'
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            xAxis: {
                categories: [
                    'Successfully concluded MBA',
                    'Drop outs'
                ],
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                showInLegend: false,
                name: "Number",
                data: data
            }]
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className="chart-container">
            <CardContent>
                {
                    options && (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartComponentRef}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
};

export default DropOuts;
